'use client'

import Button from '@dg/common/components/Button'
import Layerpopup from '@dg/common/components/Layerpopup'
import Link from '@dg/common/components/Link'
import WishToast from '@dg/common/components/Mobile/Common/WishToast'
import Toast from '@dg/common/components/Toast'
import {
	classNameFunc
} from '@dg/common/lib/common'
import useImageValidate from '@dg/common/lib/hooks/useImageValidate'
import IcHeart from '@dg/common/public/icons/mobile/common/ic-heart.svg'
import imageData from '@dg/common/public/json/imageData'
import pathsData from '@dg/common/public/json/mobile/paths'
import {
	PlusCircleIcon, TrashIcon
} from '@heroicons/react/24/outline'
import Image from 'next/image'
import {
	useTranslation
} from 'react-i18next'
import type {
	BottomInfoApiProps
} from '@dg/common/lib/mobile/api'

interface FooterTabRecentViewProps {
	list?: BottomInfoApiProps[`result`];
	resetRecentItems: (boolean?: boolean) => void;
	wishActive: boolean;
	wishAddFunc: (formData: URLSearchParams) => void;
	wishRemoveFunc: (formData: URLSearchParams) => void;
}

const FooterTabRecentView = ({
	wishActive, list, resetRecentItems, wishAddFunc, wishRemoveFunc
}: FooterTabRecentViewProps) => {
	const {
		t
	} = useTranslation()

	const imageValidate = useImageValidate()

	return (
		<>
			<Layerpopup
				defaultFooter={false}
				id="layerpopup-recentitems"
				maxHeight="max-h-3/5"
				wrapZIndex="z-120"
				setTitle={
					(
						<div
							className="flex w-full justify-between"
						>
							<Link
								href={pathsData.viewedList}
							>
								{t(`최근 본 상품`)}
							</Link>

							{
								list?.recent_view_goods !== null && (
									<Button
										className="flex items-center text-sm font-normal"
										onClick={() => resetRecentItems()}
									>
										<TrashIcon
											className="mr-1 h-4"
										/>

										{t(`전체 삭제`)}
									</Button>
								)
							}
						</div>
					)
				}
				bottom
			>
				<ul
					className="grid grid-cols-3 gap-1 overflow-auto"
				>
					{
						list?.recent_view_goods && list.recent_view_goods.length > 0 ?
							list.recent_view_goods
								.filter((filterItem, filterIndex) => filterIndex < 30)
								.map((item, index) => (
									<li
										key={Object.keys(list.recent_view_goods)[index]}
										className="relative w-full pt-full"
									>
										<Link
											className="absolute left-0 top-0 block size-full"
											href={item.href}
										>
											<Image
												alt={item.gd_nm}
												className="object-cover"
												onError={imageValidate.onError}
												placeholder={imageData.emptyImage}
												sizes="33vw"
												src={item.img_src}
												fill
											/>
										</Link>

										{
											index === 29 &&
											list.recent_view_goods.length > 30 ?
												(
													<Link
														href={pathsData.viewedList}
														className={
															classNameFunc(`
																absolute left-0 top-0 z-10 flex size-full
																flex-col items-center justify-center bg-dg-black/50
																text-center text-xs text-dg-white
																dark:bg-dgdark-black/50 dark:text-dgdark-white
															`)
														}
													>
														<PlusCircleIcon
															className="mx-auto mb-1 h-7.5 stroke-1"
														/>

														{t(`더표시`)}
													</Link>
												) :
												(
													<Button
														className={
															classNameFunc(`
																btn-heart absolute bottom-1 right-1 z-10 hidden
																text-dg-500 dark:text-dgdark-500
																${
																	item.wish_list_yn === `Y` ?
																		`text-dg-red dark:text-dgdark-red` :
																		``
																}
															`)
														}
														onClick={
															() => {
																const formData = new URLSearchParams()

																formData.append(`gd_no`, item.gd_no)

																if (item.wish_list_yn === `N`) {
																	wishAddFunc(formData)
																} else {
																	wishRemoveFunc(formData)
																}
															}
														}
													>
														<IcHeart
															className="h-8.5"
														/>

														<span
															className="sr-only"
														>
															Add/remove Wishlist
														</span>
													</Button>
												)
										}
									</li>
								)) :
							(
								<li>
									<p
										className="my-15 text-center text-sm text-dg-800 dark:text-dgdark-800"
									>
										{t(`최근 본 상품이 없습니다.`)}
									</p>

									<Link
										className="bg-dg-400 py-4 text-center dark:bg-dgdark-400"
										href={pathsData.onedayChance}
									>
										{t(`원데이 찬스`)}
									</Link>
								</li>
							)
					}
				</ul>
			</Layerpopup>

			<WishToast
				active={wishActive}
			/>

			<Layerpopup
				id="layerpopup-recentitemsremovealert"
				customFooter={
					(
						<div
							className="flex w-full"
						>
							<Button
								className="btn-close flex-none"
							>
								{t(`취소`)}
							</Button>

							<Button
								className="flex-auto"
								onClick={() => resetRecentItems(true)}
							>
								{t(`확인`)}
							</Button>
						</div>
					)
				}
			>
				{t(`모두 삭제하시겠습니까?`)}
			</Layerpopup>

			<Toast
				id="toast-remove"
				className="!w-full !max-w-full rounded-none bg-dg-blue text-center dark:bg-dgdark-blue
					[&>div>button]:hidden"
			>
				{t(`즐겨찾기 삭제`)}
			</Toast>
		</>
	)
}

export default FooterTabRecentView
