'use client'

import useLayerpopup from '@dg/common/lib/hooks/useLayerpopup'
import useSetApi from '@dg/common/lib/hooks/useSetApi'
import {
	forwardOpener, procOpenerFunc, layerAlert
} from '@dg/common/lib/qoo10Common'
import LoginSnsPopupHasEmailView, {
	loginDataType
} from 'member/components/Pc/Login/LoginView/LoginSnsPopup/LoginSnsPopupHasEmail/LoginSnsPopupHasEmailView/LoginSnsPopupHasEmailView'
import {
	connectCustomByEmailApi,
	ConnectCustomByEmailApiProps
} from 'member/lib/api'
import {
	useRouter
} from 'next/navigation'
import React, {
	useEffect,
	useState
} from 'react'
import {
	useTranslation
} from 'react-i18next'

interface LoginSnsPopupHasEmailProps {
	email: string;
	loginId: string;
	name: string;
	regDt: string;
	returnUrl: string;
	snsCd: string;
	uid: string;
}

const LoginSnsPopupHasEmail = ({
	email, loginId, name, regDt, uid, snsCd, returnUrl
}: LoginSnsPopupHasEmailProps) => {
	const {
		t
	} = useTranslation()

	const router = useRouter()
	const layerpopup = useLayerpopup()

	const [
		password,
		setPassword
	] = useState<string>(``)

	const [
		loginData,
		setLoginData
	] = useState<loginDataType>({
		email,
		name,
		snsEmail: email
	})

	useEffect(() => {
		if (email) {
			setLoginData({
				email,
				name,
				snsEmail: email
			})
		}
	}, [
		email
	])

	const onChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPassword(event.target.value)
	}

	const redirect = (isRegister = false) => {
		let setReturnUrl = returnUrl

		if (setReturnUrl.startsWith(`excute:`)) {
			forwardOpener(setReturnUrl)

			return true
		}

		if (setReturnUrl.startsWith(`exopn://`)) {
			const func = `${setReturnUrl.substring(8)}CallFromPopup`

			procOpenerFunc(func)

			return true
		}

		if (isRegister) setReturnUrl = `RegisterNewMemberResult`

		return router.push(setReturnUrl)
	}

	const connectCustomByEmail = useSetApi({
		api: connectCustomByEmailApi,
		successCallback: (successData: ConnectCustomByEmailApiProps) => {
			if (successData.code === 0) {
				redirect()
			} else if (successData.code === -2) {
				layerAlert(layerpopup.open, t(`해당 ID가 없습니다.`))
			} else if (successData.code === -3) {
				layerAlert(layerpopup.open, t(`{text}가 일치하지 않습니다.`).replace(/{text}/gim, t(`비밀번호`)))
			} else {
				layerAlert(layerpopup.open, t(`시스템 오류`))
			}
		}
	})

	const onClickSubmitButton = () => {
		if (password === ``) {
			layerAlert(layerpopup.open, t(`패스워드를 입력하세요.`))

			return false
		}

		const formData = new URLSearchParams()

		formData.append(`loginId`, email)
		formData.append(`passwd`, password || ``)
		formData.append(`uid`, uid || ``)
		formData.append(`snsCd`, snsCd || ``)
		formData.append(`snsEmail`, loginData.email)

		return connectCustomByEmail.set(formData)
	}

	const onClickCloseButton = () => {
		layerpopup.close(`layerpopup_haspopup`)
	}

	const onClickFindPW = () => {
		const url = `/pc/find/pw?email=${encodeURIComponent(email)}`
		const windowFeatures = `height=800,width=1000,status=yes,toolbar=no,menubar=no,location=no`

		window.open(url, `find_pw`, windowFeatures)
	}

	const getSnsName = () => {
		switch (snsCd) {
			case `fe`: {
				return `facebook`
			}

			case `go`: {
				return `google`
			}

			case `ap`: {
				return `apple`
			}

			case `ln`: {
				return `line`
			}

			default: {
				return `unknown`
			}
		}
	}

	const props = {
		loginData,
		loginId: loginId || ``,
		onChangePassword,
		onClickCloseButton,
		onClickFindPW,
		onClickSubmitButton,
		password,
		regDt,
		snsName: getSnsName()
	}

	return (
		<LoginSnsPopupHasEmailView
			{...props}
		/>
	)
}

export default LoginSnsPopupHasEmail
