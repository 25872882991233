type PathsDataProps = Record<string, string>

const pathsData: PathsDataProps = {
	agreement: `${process.env.legacyPcUrl}/gmkt.inc/Company/UserAgreement.aspx`,
	bestseller: `${process.env.legacyPcUrl}/gmkt.inc/Bestsellers/`,
	cart: `${process.env.legacyPcUrl}/gmkt.inc/Order/Cart.aspx`,
	company: `${process.env.legacyPcUrl}/gmkt.inc/Company/AboutCompany.aspx`,
	cookiePolicy: `${process.env.legacyPcUrl}/gmkt.inc/Company/CookiePolicy.aspx`,
	creditHistory: `${process.env.legacyPcUrl}/gmkt.inc/My/CreditHistory.aspx`,
	dailyDeal: `${process.env.legacyPcUrl}/gmkt.inc/Deal/DailyDeal.aspx`,
	directShop: `${process.env.legacyPcUrl}/Gate/directshop.qoo10.jp`,
	dpl: `https://www.onlinemarketplace.jp/initiatives/initiatives_detail02/`,
	ebay: `https://www.ebayinc.com`,
	findId: `${process.env.memberUrl}/pc/find/id`,
	findPw: `${process.env.memberUrl}/pc/find/pw`,
	furusato: `${process.env.legacyPcUrl}/gmkt.inc/Category/Furusato.aspx`,
	gBankCash: `${process.env.legacyPcUrl}/gmkt.inc/GBank/GCashList.aspx`,
	gBankEtoken: `${process.env.legacyPcUrl}/gmkt.inc/GBank/ETokenInfo.aspx`,
	gBankGRefundMoney: `${process.env.legacyPcUrl}/gmkt.inc/GBank/GRefundMoney.aspx`,
	gBankGstamp: `${process.env.legacyPcUrl}/gmkt.inc/GBank/GStampIssueList.aspx`,
	gBankMemberPointHistory: `${process.env.legacyPcUrl}/gmkt.inc/GBank/MemberPointHistory.aspx`,
	groupBuy: `${process.env.legacyPcUrl}/gmkt.inc/Deal/GroupBuy.aspx`,
	help: `${process.env.legacyPcUrl}/gmkt.inc/CS/NHelpHome.aspx`,
	// eslint-disable-next-line @stylistic/max-len
	helpConnect: `${process.env.legacyPcUrl}/gmkt.inc/CS/NHelpContactUsQuestion.aspx?kind=&action=&category=&notlogin=Y`,
	helpFaq: `${process.env.legacyPcUrl}/gmkt.inc/CS/NHelpHome.aspx`,
	home: process.env.legacyPcUrl as string,
	join: `${process.env.memberUrl}/pc/join/info`,
	joinBenefit: `${process.env.legacyPcUrl}/gmkt.inc/Special/Special.aspx?sid=160943`,
	joinMoreInfo: `${process.env.memberUrl}/pc/join/moreinfo`,
	liveDetail: `${process.env.liveUrl}/pc/detail`,
	liveScheduleAlarm: `${process.env.liveUrl}/pc/schedule/alarm`,
	liveScheduleList: `${process.env.liveUrl}/pc/schedule/list`,
	liveStudio: `${process.env.liveUrl}/pc/studio`,
	login: `${process.env.memberUrl}/pc/login`,
	loginSns: `${process.env.memberUrl}/pc/login/sns`,
	moveHome: `${process.env.legacyPcUrl}/gmkt.inc/Move/Default.aspx`,
	myApply: `${process.env.legacyPcUrl}/gmkt.inc/My/myapply.aspx`,
	myCatalogueWishList: `${process.env.legacyPcUrl}/gmkt.inc/My/CatalogueWishList.aspx`,
	myCoupon: `${process.env.legacyPcUrl}/gmkt.inc/MyCoupon/MyCouponList.aspx?global_order_type=L`,
	myEcouponVocherList: `${process.env.legacyPcUrl}/gmkt.inc/My/MyEcouponVoucherList.aspx`,
	myEventList: `${process.env.legacyPcUrl}/gmkt.inc/My/EventList.aspx`,
	myInfo: `${process.env.memberUrl}/pc/myinfo/info`,
	myInfoAuth: `${process.env.memberUrl}/pc/myinfo/auth`,
	myInfoSnsAuth: `${process.env.memberUrl}/pc/myinfo/snsauth`,
	myInquiry: `${process.env.legacyPcUrl}/gmkt.inc/My/MyInquiry.aspx`,
	myLikeQSpecials: `${process.env.legacyPcUrl}/gmkt.inc/My/LikeQSpecials.aspx`,
	myMain: `${process.env.legacyPcUrl}/gmkt.inc/My/Default.aspx`,
	myOrderHistory: `${process.env.legacyPcUrl}/gmkt.inc/My/OrderHistory.aspx`,
	myQGiftPopup: `${process.env.legacyPcUrl}/gmkt.inc/My/PopupQGiftBuy.aspx`,
	myReview: `${process.env.legacyPcUrl}/gmkt.inc/My/MyReview.aspx`,
	mySellerShop: `${process.env.legacyPcUrl}/gmkt.inc/My/SellerShop.aspx`,
	myShops: `${process.env.legacyPcUrl}/gmkt.inc/My/MyShops.aspx`,
	myViewToday: `${process.env.legacyPcUrl}/gmkt.inc/My/ViewToday.aspx`,
	myViewedQSpecials: `${process.env.legacyPcUrl}/gmkt.inc/My/ViewedQSpecials.aspx`,
	myWishList: `${process.env.legacyPcUrl}/gmkt.inc/My/WishList.aspx`,
	openStore: `${process.env.legacyPcUrl}/gmkt.inc/Special/Special.aspx?sid=158483`,
	orderContractEditList: `${process.env.legacyPcUrl}/gmkt.inc/My/OrderContractEditList.aspx`,
	orderContractList: `${process.env.legacyPcUrl}/gmkt.inc/My/OrderContractList.aspx`,
	orderHistory: `${process.env.legacyPcUrl}/gmkt.inc/My/Default.aspx`,
	policy: `${process.env.legacyPcUrl}/gmkt.inc/Company/PrivacyPolicy.aspx`,
	popupMsgBox: `${process.env.legacyPcUrl}/gmkt.inc/Message/MessagePopupMyBox.aspx`,
	qchance: `${process.env.legacyPcUrl}/gmkt.inc/Event/Qchance.aspx`,
	qpost: `${process.env.legacyPcUrl}/gmkt.inc/Message/MessagePopupMyBox.aspx`,
	qsafe: `${process.env.legacyPcUrl}/gmkt.inc/GBank/Default.aspx`,
	qsafeIntro: `${process.env.legacyPcUrl}/gmkt.inc/Qsafe/QsafeIntro.aspx`,
	qsm: `https://qsm.qoo10.jp/GMKT.INC.GSM.Web/Login.aspx`,
	registerSeller: `${process.env.legacyPcUrl}/gmkt.inc/Member/RegisterSeller.aspx?isSellerRegProcess=Y`,
	registerSellerFooter: `https://university.qoo10.jp/store-setup/?utm_source=company&utm_medium=bottom&utm_content=link&utm_campaign=qoo10university`,
	resign: `${process.env.memberUrl}/pc/resign`,
	resignSnsAuth: `${process.env.memberUrl}/pc/resign/snsauth`,
	security: `${process.env.legacyPcUrl}/gmkt.inc/Company/Security.aspx`,
	sellerCreditHistory: `${process.env.legacyPcUrl}/gmkt.inc/My/SellerCreditHistory.aspx`,
	timeSale: `${process.env.legacyPcUrl}/gmkt.inc/Deal/TimeSale.aspx`,
	university: `https://article-university.qoo10.jp?utm_source=qsm&utm_medium=top&utm_content=link&utm_campaign=qoo10university`,
	viewedList: `${process.env.legacyPcUrl}/gmkt.inc/My/ViewToday.aspx`
}

export default pathsData
