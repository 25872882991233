'use client'

import {
	device
} from '@dg/common/lib/common'
import {
	store
} from '@dg/common/lib/store'
import pathsData from '@dg/common/public/json/mobile/paths'
import pathsPcData from '@dg/common/public/json/pc/paths'
import {
	QueryCache, QueryClient, QueryClientProvider
} from '@tanstack/react-query'
import {
	ReactQueryDevtools
} from "@tanstack/react-query-devtools"
import {
	Provider
} from 'jotai'
import {
	useRouter
} from 'next/navigation'
import {
	type ReactNode, useState
} from "react"

interface ProvidersProps {
	children: ReactNode;
}

export interface ProvidersErrorProps {
	errorMessage: string;
	name: string;
	response: {
		data: unknown;
		status: number;
		statusText: string;
	};
}

const Providers = ({
	children
}: ProvidersProps) => {
	const router = useRouter()

	const [
		client
	] = useState(
		new QueryClient({
			defaultOptions: {

				/*
				mutations: {
					onError: (error, variables, context) => {
						console.log(error, variables, context)
					},
					onSuccess: (data, variables, context) => {
						console.log(data, variables, context)
					},
				},
				*/
				queries: {
					retry: 0
				}
			},
			queryCache: new QueryCache({
				onError: (error, query) => {
					const customError = error as unknown as ProvidersErrorProps

					if (typeof Error !== `undefined` && process.env.NODE_ENV === `development`) {
						console.log(
							customError.name,
							customError.errorMessage,
							customError.response.data,
							customError.response.status,
							customError.response.statusText
						)
					}

					const queryOptions = query.options as {
						errorCallback: (callBackError: unknown) => void;
					}

					if (customError.response.status === 401 && queryOptions.errorCallback === undefined) {
						const pathDataChk = device().browser.desktop === undefined ? pathsData : pathsPcData

						if (device().browser.app === undefined) {
							return router.replace(`${pathDataChk.login}?ReturnUrl=${encodeURIComponent(window.location.href)}`)
						}

						return window.open(`${pathDataChk.login}?ReturnUrl=close`)
					}

					if (queryOptions.errorCallback !== undefined) {
						return queryOptions.errorCallback(error)
					}

					return false
				},
				onSuccess: (data, query) => {
					const queryOptions = query.options as {
						successCallback: (callBackData: unknown) => void;
					}

					if (queryOptions.successCallback !== undefined) {
						return queryOptions.successCallback(data)
					}

					return false
				}
			})
		})
	)

	return (
		<QueryClientProvider
			client={client}
		>
			<Provider
				store={store}
			>
				{children}
			</Provider>

			<ReactQueryDevtools
				initialIsOpen={false}
			/>
		</QueryClientProvider>
	)
}

export default Providers
