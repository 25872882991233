'use client'

import {
	classNameFunc
} from '@dg/common/lib/common'
import InputLabelView from 'member/components/Common/Find/InputLabelView'
import StackView from 'member/components/Common/Find/StackView'
import ValidateInput from 'member/components/Common/Validate/ValidateInput'
import {
	loginInfoType
} from "member/components/Pc/Login/LoginType"
import React from 'react'

interface LoginGuestContViewProps {
	loginInfo: loginInfoType;
	onPhoneChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onUserLoginIdChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const LoginGuestContView = ({
	onUserLoginIdChange,
	onPhoneChange,
	loginInfo
}: LoginGuestContViewProps) => {
	const variantClassName = classNameFunc(`flex-1 border-[#f1f1f1]`)
	const variantWrapClassName = classNameFunc(`flex h-8 flex-auto space-x-2.5`)

	return (
		<>
			<p
				className={
					classNameFunc(`
						mb-4 mt-3 rounded-lg bg-[#f3f7ff] py-2 pl-4.5 pr-3 text-xs
						text-[#517FEE] transition-colors
					`)
				}
			>
				* 購入時に入力したメールアドレスと携帯番号を入力してください。初めて購入される方はログインが必要ありません。
			</p>

			<StackView
				className="flex-wrap text-sm"
			>
				<InputLabelView
					className="w-30 pt-1.5"
					name="email"
					title="メール"
				/>

				<ValidateInput
					borderType="outline"
					className={variantClassName}
					onChange={onUserLoginIdChange}
					type="email"
					validateTextClassName="ml-30"
					validateType="email"
					value={loginInfo.email}
					wrapClassName={variantWrapClassName}
					px
					rounded
				/>
			</StackView>

			<StackView
				className="flex-wrap text-sm"
			>
				<InputLabelView
					className="w-30 pt-1.5"
					name="phone"
					title="電話番号(携帯)"
					w={30}
				/>

				<ValidateInput
					borderType="outline"
					className={variantClassName}
					onChange={onPhoneChange}
					type="phone"
					validateTextClassName="ml-30"
					validateType="phone"
					value={loginInfo.phoneNumber}
					wrapClassName={variantWrapClassName}
					px
					rounded
				/>

			</StackView>

			<p
				className="ml-30 text-2xs text-dg-600"
			>
				国番号と「‐」は除いて数字(半角)のみ入力してください。
			</p>
		</>
	)
}

export default LoginGuestContView
