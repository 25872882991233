'use client'

import Link from '@dg/common/components/Link'
import {
	classNameFunc
} from '@dg/common/lib/common'
import {
	ChevronRightIcon
} from '@heroicons/react/24/outline'
import {
	PropsWithChildren
} from 'react'

interface HeaderLoginMenuLiViewProps {
	last?: boolean;
	link?: string;
	selected?: boolean;
}

const HeaderLoginMenuLiView = ({
	children, link = ``, selected = false, last = false
}: PropsWithChildren<HeaderLoginMenuLiViewProps>) => {
	const linkClassName =
		classNameFunc(`
			relative block h-full overflow-hidden text-center text-sm leading-10 text-dg-white dark:text-dgdark-white
			${
				selected === true ?
					`
						bg-[#456ccb] font-bold after:absolute after:-bottom-2 after:left-1/2
						after:size-3 after:-translate-x-1/2 after:rotate-45 after:bg-dg-white
						dark:after:bg-dgdark-white
					` :
					``
			}
			${last === false ? `w-40` : ``}
		`)

	return (
		<li
			className={last === true ? `mr-5 flex flex-auto justify-end` : ``}
		>
			<Link
				className={linkClassName}
				href={link}
			>
				{children}

				{
					last === true && (
						<ChevronRightIcon
							className="ml-1 inline-block h-3"
						/>
					)
				}
			</Link>
		</li>
	)
}

export default HeaderLoginMenuLiView
