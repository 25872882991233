'use client'

import HeaderView from '@dg/common/components/Mobile/Header/HeaderView/HeaderView'
import {
	device, addCookie
} from '@dg/common/lib/common'
import {
	execApp, goAppStore, goAppPageFailStore, gaDimensionFunc
} from '@dg/common/lib/qoo10Common'
import pathsData from '@dg/common/public/json/mobile/paths'
import {
	useRouter
} from 'next/navigation'
import {
	useCallback, useEffect, useState
} from 'react'

interface HeaderProps {
	title?: string;
	type: `empty` | `headerOnly` | `headerOnlyAppBanner` | `main` | `sub` | `subAppBanner`;
}

const Header = ({
	title = ``, type
}: HeaderProps) => {
	const router = useRouter()

	const [
		pageData,
		setPageData
	] = useState({
		admin: false,
		banner: false
	})

	const appBannerLinkFunc = useCallback(() => {
		if (device().browser.iphone === true || device().browser.ipad === true) {
			execApp(`qoo10jp://`, ``, goAppStore)
		} else {
			goAppPageFailStore(``)
		}

		gaDimensionFunc({
			data: {
				ep_event_area: `app_banner`,
				ep_event_label: `click`,
				ep_event_page: `mweb`,
				event_name: `click_event`
			},
			event: true,
			url: window.location.href
		})
	}, [])

	const appBannerCloseFunc = useCallback(() => {
		addCookie(`appbanner=false`, 9999)

		setPageData({
			...pageData,
			banner: false
		})

		gaDimensionFunc({
			data: {
				ep_event_area: `app_banner`,
				ep_event_label: `close`,
				ep_event_page: `mweb`,
				event_name: `click_event`
			},
			event: true,
			url: window.location.href
		})
	}, [
		pageData
	])

	const historyBackChk = useCallback(() => {
		const url = new URL(window.location.href).pathname

		const findChk = document.referrer.match(/\/mobile\/login/) !== null && url.match(/\/mobile\/find/gim) !== null

		if (window.opener !== null && url === `/mobile/login`) {
			window.close()
		} else if (
			url.match(/\/mobile\/join\/validate/gim) !== null ||
			url.match(/\/mobile\/join\/complete/gim) !== null ||
			findChk === true
		) {
			router.replace(pathsData.home)
		} else if (url.match(/\/mobile\/myinfo\/bank/gim) !== null) {
			router.replace(pathsData.myInfo)
		} else {
			router.back()
		}
	}, [
		router
	])

	useEffect(() => {
		const setData = {
			admin: false,
			banner: false
		}

		const dgCookieName = `appbanner=`

		if (document.cookie.match(dgCookieName) === null && (type === `main` || type.match(/AppBanner/) !== null)) {
			setData.banner = true
		}

		const dgAdminCookieName = `AdminLogin=`

		if (document.cookie.match(dgAdminCookieName) !== null) {
			setData.admin = document.cookie.split(dgAdminCookieName)[1].split(`;`)[0] === `Y`
		}

		setPageData(setData)
	}, [
		type
	])

	const props = {
		adminChk: pageData.admin,
		appBannerCloseFunc,
		appBannerLinkFunc,
		bannerVisible: pageData.banner,
		historyBackChk,
		title,
		type
	}

	return (
		<HeaderView
			{...props}
		/>
	)
}

export default Header
