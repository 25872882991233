'use client'

import Button from '@dg/common/components/Button'
import {
	classNameFunc
} from '@dg/common/lib/common'
import useImageValidate from '@dg/common/lib/hooks/useImageValidate'
import Image from 'next/image'
import {
	PropsWithChildren
} from 'react'
import {
	useTranslation
} from 'react-i18next'

interface HeaderInfoLiViewProps {
	last?: boolean;
	link?: string;
	onClick?: () => void;
	target?: `blank`;
}

const HeaderInfoLiView = ({
	children, link, target, last = false, onClick
}: PropsWithChildren<HeaderInfoLiViewProps>) => {
	const {
		t
	} = useTranslation()

	const imageValidate = useImageValidate()

	return (
		<li
			className={
				classNameFunc(`
					relative flex-none text-xs
					${
						last === false ?
							`
								px-1.5 after:absolute after:right-0 after:top-1/2 after:block
								after:h-2 after:w-px after:-translate-y-1/2 after:bg-[#b8b6b2]
							` :
							`pl-1.5`
					}
				`)
			}
		>
			<Button
				className="flex items-center hover:underline"
				onClick={
					() => {
						if (link !== undefined) {
							if (link.match(/window/) !== null) {
								return window.open(link.split(`_`)[1], link.split(`_`)[2], link.split(`_`)[3])
							}

							if (link.match(/window/) === null) {
								if (target === `blank`) {
									window.open(link)
								} else {
									window.location.href = link
								}

								return false
							}
						}

						if (onClick !== undefined) {
							onClick()
						}

						return false
					}
				}
			>
				{
					children === `Qpost` && (
						<div
							className="relative mr-1 flex h-3 w-3.5"
						>
							<Image
								alt="Qpost Icon"
								onError={imageValidate.onError}
								sizes="14px"
								src="/images/pc/common/nav-qpost.png"
								fill
							/>
						</div>
					)
				}

				{children}

				{
					children === t(`Qoo10대학`) && (
						<Image
							alt="New Icon"
							className="ml-1"
							height={11}
							onError={imageValidate.onError}
							src="/images/pc/common/nav-new.png"
							width={27}
						/>
					)
				}
			</Button>
		</li>
	)
}

export default HeaderInfoLiView
