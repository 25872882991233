'use client'

import ListView from '@dg/common/components/Pc/Common/List/ListView/ListView'

interface ListProps {
	bottom?: {
		price?: {
			finalPriceName: string;
			sellPriceName?: string;
		};
		star?: {
			starName: string;
		};
		title?: {
			className?: string;
			prependIconName?: string;
			titleName: string;
		};
	}[];
	className?: string;
	liClassName: string;
	linkName: string;
	list: unknown[];
	top?: {
		height?: string;
		image?: {
			altName: string;
			imageName: string;
			imageType?: string; // gd-image
		};
		width?: string;
	};
	type?: `horizon` | `vertical`;
}

const List = ({
	className = ``, liClassName, linkName, list, type = `vertical`, top, bottom
}: ListProps) => {
	const props = {
		bottom,
		className,
		liClassName,
		linkName,
		list,
		top,
		type
	}

	return (
		<ListView
			{...props}
		/>
	)
}

export default List
