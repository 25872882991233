'use client'

import Select, {
	SelectProps
} from '@dg/common/components/Select/Select'
import {
	sortData
} from '@dg/common/lib/common'
import formData from '@dg/common/public/json/form'
import {
	FormEvent
} from 'react'
import {
	useTranslation
} from 'react-i18next'

interface SelectNationViewProps extends SelectProps {
	fullNation: boolean;
	fullNationFunc: (event: FormEvent) => void;
	selectDataValue: string;
	selectValue: string;
	selectValueOnly: boolean;
	setValue: `code` | `name`;
}

const SelectNationView = (props: SelectNationViewProps) => {
	const {
		id = ``, selectValue, selectDataValue, setValue, fullNation, selectValueOnly, fullNationFunc,
		...rest
	} = props

	const {
		t
	} = useTranslation()

	const sortPhone = fullNation === true ?
		sortData([
			...formData.phone
		], `fullname`) :
		[
			...formData.phone
		]

	const numberValue = setValue === `code` ? selectValue : selectDataValue
	const dataValue = setValue === `code` ? selectDataValue : selectValue

	let selectedValue = setValue === `code` ? numberValue : dataValue

	if (setValue === `code` && numberValue === `1`) {
		selectedValue = `${numberValue}-${dataValue}`
	} else if (setValue === `name` && numberValue === `1`) {
		selectedValue = `${dataValue}-${numberValue}`
	}

	return (
		<Select
			{...rest}
			id={id}
			onChange={fullNationFunc}
			value={selectedValue}
		>
			{
				Array.from(sortPhone)
					.filter((filterItem, filterIndex) => {
						// eslint-disable-next-line @stylistic/max-len
						const filterValue = `${setValue === `code` ? filterItem.value : filterItem.name}${filterItem.value === 1 ? `-${setValue === `code` ? filterItem.name : filterItem.value}` : ``}`

						// eslint-disable-next-line @stylistic/max-len
						return (fullNation === false ? filterIndex < 21 : true) && (selectValueOnly === true ? selectedValue === filterValue : true)
					})
					.map((item, index) => (
						<option
							key={`${id}-${fullNation.toString()}-${Object.keys(Array.from(sortPhone))[index]}`}
							data-value={setValue === `code` ? item.name : item.value}
							value={
								`
									${setValue === `code` ? item.value : item.name}
									${item.value === 1 ? `-${setValue === `code` ? item.name : item.value}` : ``}
								`.trim().replace(/\n\s+/gim, ``)
							}
						>
							{item.jpfullname}
						</option>
					))
			}

			{
				fullNation === false && selectValueOnly === false && (
					<option
						value="more"
					>
						{t(`다른 나라`)}
					</option>
				)
			}
		</Select>
	)
}

export default SelectNationView
