'use client'

import Button from '@dg/common/components/Button'
import Link from '@dg/common/components/Link'
import {
	classNameFunc
} from '@dg/common/lib/common'
import pathsData from '@dg/common/public/json/pc/paths'

interface LoginInfoViewProps {
	isNoLoginQuestion: boolean;
	memberType: string;
}

const LoginInfoView = ({
	isNoLoginQuestion, memberType
}: LoginInfoViewProps) => {
	const linkClassName = classNameFunc(`transition-colors hover:underline`)

	return (
		<>
			{
				memberType === `member` && (
					<ul
						className="flex pb-2"
					>
						<li
							className={
								classNameFunc(`
									relative pr-2 after:absolute after:right-0 after:top-1/2
									after:h-3 after:w-px after:-translate-y-1/2 after:bg-[#a7a7a7]
								`)
							}
						>
							<Link
								className={linkClassName}
								href={pathsData.findId}
							>
								メールまたはIDを忘れた方
							</Link>
						</li>

						<li
							className="pl-2"
						>
							<Link
								className={linkClassName}
								href={pathsData.findPw}
							>
								パスワードを忘れた方
							</Link>
						</li>
					</ul>
				)
			}

			{
				isNoLoginQuestion && (
					<div
						className="pb-2"
					>
						<span
							className="relative text-[#a7a7a7]"
						>
							ログインなしでお問い合わせできます。

						</span>

						<Link
							className="underline"
							href={pathsData.helpConnect}
						>
							こちら &gt;
						</Link>
					</div>
				)
			}

			<div>
				<Button
					className="hover:underline"
					// eslint-disable-next-line @stylistic/max-len
					onClick={() => window.open(`${process.env.legacyPcUrl}/gmkt.inc/Main/PopupNotice.aspx?nid=382&__ar=Y`, `blank`, `top=100, left=300, width=700, height=600, toolbar=no, menubar=no, location=no, status=no, scrollbars=no, resizable=no`)}
				>
					ログインができない場合
				</Button>
			</div>

			{
				memberType !== `member` &&
				(
					<p
						className="w-full pt-4 text-2xs transition-colors"
					>
						*電話番号(携帯)の欄には注文時に入力された番号(例:090あるいは90)を入力してください。
						<br />
						*ゲストのログインは一番最後に購入された際の情報でログインができます。
					</p>
				)
			}
		</>
	)
}

export default LoginInfoView
