'use client'

import HeaderRightAlarmView from '@dg/common/components/Mobile/Header/HeaderView/HeaderRightAlarm/HeaderRightAlarmView/HeaderRightAlarmView'
import {
	headerApi, HeaderApiProps
} from '@dg/common/lib/api'
import useGetApi from '@dg/common/lib/hooks/useGetApi'
import {
	UseQueryResult
} from '@tanstack/react-query'

interface HeaderRightAlarmProps {
	type?: `alarm` | `cart`;
}

const HeaderRightAlarm = ({
	type = `alarm`
}: HeaderRightAlarmProps) => {
	const headerDataApi = useGetApi({
		api: headerApi,
		name: `headerDataApi`
	}) as UseQueryResult<HeaderApiProps>

	const props = {
		data: headerDataApi.data?.result,
		type
	}

	return (
		<HeaderRightAlarmView
			{...props}
		/>
	)
}

export default HeaderRightAlarm
