'use client'

import HeaderRightSearchLayerRecentKeywordView from '@dg/common/components/Mobile/Header/HeaderView/HeaderRightSearchLayer/HeaderRightSearchLayerView/HeaderRightSearchLayerRecentKeyword/HeaderRightSearchLayerRecentKeywordView/HeaderRightSearchLayerRecentKeywordView'
import {
	addCookie
} from '@dg/common/lib/common'
import useLayerpopup from '@dg/common/lib/hooks/useLayerpopup'
import {
	useCallback, useEffect, useRef, useState
} from 'react'

const HeaderRightSearchLayerRecentKeyword = () => {
	const layerpopup = useLayerpopup()

	const headerSearchLayerRecentKeywordRef = useRef<HTMLElement>(null)

	const [
		pageData,
		setPageData
	] = useState({
		edit: false,
		open: false,
		openBtnVisible: false,
		recentKeywordArray: [] as string[]
	})

	const openFunc = useCallback(() => {
		setPageData({
			...pageData,
			open: true
		})
	}, [
		pageData
	])

	const editFunc = useCallback(() => {
		setPageData({
			...pageData,
			edit: pageData.edit !== true
		})
	}, [
		pageData
	])

	const removeItemFunc = useCallback((item: string) => {
		const setData = {
			...pageData
		}

		let cookieData = ``

		setData.recentKeywordArray =
			pageData.recentKeywordArray
				.filter((filterItem) => filterItem !== item)
				.map((mapItem, mapIndex) => {
					cookieData += `${mapIndex > 0 ? `_` : ``}${encodeURIComponent(mapItem)}`

					return mapItem
				})

		addCookie(`recentSearchKeyword=${cookieData}`, 30)

		setPageData({
			...setData
		})
	}, [
		pageData
	])

	const removeAllFunc = useCallback(() => {
		addCookie(`recentSearchKeyword=`, 0)

		setPageData({
			...pageData,
			recentKeywordArray: []
		})
	}, [
		pageData
	])

	useEffect(() => {
		const dgCookieName = `recentSearchKeyword=`

		let recentKeywordArray = [] as string[]

		if (document.cookie.match(dgCookieName) !== null) {
			recentKeywordArray = decodeURIComponent(document.cookie.split(dgCookieName)[1].split(`;`)[0]).split(`_`)
		}

		if (pageData.recentKeywordArray.length === 0 && recentKeywordArray.length > 0) {
			setPageData({
				...pageData,
				recentKeywordArray
			})
		}

		if (layerpopup.get()[`layerpopup-searchlayer`] !== undefined && layerpopup.get()[`layerpopup-searchlayer`].open === true && headerSearchLayerRecentKeywordRef.current !== null) {
			if (headerSearchLayerRecentKeywordRef.current?.offsetHeight > 200 && pageData.openBtnVisible === false) {
				setPageData({
					...pageData,
					openBtnVisible: true
				})
			}
		}
	}, [
		layerpopup,
		pageData
	])

	const props = {
		edit: pageData.edit,
		editFunc,
		headerSearchLayerRecentKeywordRef,
		open: pageData.open,
		openBtnVisible: pageData.openBtnVisible,
		openFunc,
		recentKeywordArray: pageData.recentKeywordArray,
		removeAllFunc,
		removeItemFunc
	}

	return (
		<HeaderRightSearchLayerRecentKeywordView
			{...props}
		/>
	)
}

export default HeaderRightSearchLayerRecentKeyword
