type titleDataProps = Record<string, Record<string, Record<string, string>>>

const titleData: titleDataProps = {
	live: {
		studio: {
			index: `Live Shopping`
		}
	},
	member: {
		find: {
			id: `ID/メールを探す`,
			pw: `パスワードの再設定`
		},
		join: {
			complete: `会員登録完了`,
			info: `会員登録(無料)`,
			main: `会員登録`
		},
		login: {
			checkprimaryid: `ネット通販`,
			confirmemail: `メールアドレスを認証してください。`,
			main: `会員登録/ログイン`,
			memberconfirm: `認証する`,
			phoneauth: `ネット通販`,
			sns: `Qoo10 - ネット通販｜eBay Japan`
		},
		myinfo: {
			address: `住所リスト`,
			bank: `口座管理`,
			edit: `会員情報修正`,
			findaddress: `検索`,
			findmap: `新しい住所を追加`,
			loginhistory: `ログイン履歴`,
			main: `会員情報`,
			newaddress: `新しい住所を追加`,
			newaddressmodify: `住所を編集`,
			newbank: `口座追加`,
			newbankmodify: `口座編集`
		},
		resign: {
			main: `会員退会`
		}
	}
}

export default titleData
