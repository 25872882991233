'use client'

import AsyncBoundaryErrorView from '@dg/common/components/AsyncBoundary/AsyncBoundaryView/AsyncBoundaryErrorView'
import AsyncBoundaryErrorWrapView from '@dg/common/components/AsyncBoundary/AsyncBoundaryView/AsyncBoundaryErrorWrapView'
import Skeleton from '@dg/common/components/Skeleton'
import Spinner from '@dg/common/components/Spinner'
import {
	PropsWithChildren, ReactNode, Suspense
} from 'react'

interface AsyncBoundaryProps {
	fallbackAppendElement?: ReactNode;
	fallbackClassName?: string;
	fallbackPrependElement?: ReactNode;
	fallbackType?: `skeleton` | `spinner`;
	hidden?: boolean;
}

const AsyncBoundary = ({
	children,
	fallbackClassName,
	fallbackType = `spinner`,
	fallbackAppendElement,
	fallbackPrependElement,
	hidden = true
}: PropsWithChildren<AsyncBoundaryProps>) => (
	<Suspense
		fallback={
			hidden === false && (
				<>
					{fallbackPrependElement}

					{
						fallbackType === `spinner` ?
							(
								<Spinner
									className={fallbackClassName}
									hidden={hidden}
								/>
							) :
							(
								<Skeleton
									className={fallbackClassName}
								/>
							)
					}

					{fallbackAppendElement}
				</>
			)
		}
	>
		<AsyncBoundaryErrorWrapView
			fallback={<AsyncBoundaryErrorView />}
		>
			{children}
		</AsyncBoundaryErrorWrapView>
	</Suspense>
)

export default AsyncBoundary
