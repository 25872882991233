'use client'

import {
	loginInfoType
} from "member/components/Pc/Login/LoginType"
import React, {
	useEffect, useState, type RefObject
} from "react"
import LoginGuestContView from "./LoginGuestContView/LoginGuestContView"

interface LoginGuestContProps {
	inputRef: RefObject<loginInfoType>;
}

const LoginGuestCont = ({
	inputRef
}: LoginGuestContProps) => {
	const [
		loginInfo,
		setLoginInfo
	] = useState<loginInfoType>({
		checkbox: false,
		country: `81`,
		email: ``,
		loginId: ``,
		password: ``,
		phoneNumber: ``,
		refreshCount: 0
	})

	const onPhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setLoginInfo({
			...loginInfo,
			phoneNumber: event.target.value
		})
	}

	const onUserLoginIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setLoginInfo({
			...loginInfo,
			email: event.target.value
		})
	}

	useEffect(() => {
		// eslint-disable-next-line no-param-reassign
		inputRef.current = loginInfo
	}, [
		inputRef,
		loginInfo
	])

	return (
		<LoginGuestContView
			loginInfo={loginInfo}
			onPhoneChange={onPhoneChange}
			onUserLoginIdChange={onUserLoginIdChange}
		/>
	)
}

export default LoginGuestCont
