'use client'

import SnsArea from "member/components/Common/SnsArea"
import LoginSnsPopupHasEmail from "../../LoginSnsPopup/LoginSnsPopupHasEmail"
import LoginSnsPopupNoEmail from "../../LoginSnsPopup/LoginSnsPopupNoEmail"

export interface LoginSnsAreaPopupDataProps {
	email: string;
	loginId: string;
	name: string;
	regDt: string;
	ret: string;
	returnUrl: string;
	snsCd: string;
	snsEmail: string;
	uid: string;
}

export interface LoginSnsAreaViewUrlsProps {
	failureUri: string;
	redirectUri: string;
	successUri: string;
}

interface LoginSnsAreaViewDataProps {
	hidden: boolean;
	popupData: LoginSnsAreaPopupDataProps;
	urls: LoginSnsAreaViewUrlsProps;
}

const LoginSnsAreaView = ({
	hidden, popupData, urls
}: LoginSnsAreaViewDataProps) => (
	<>
		<SnsArea
			className={hidden === true ? `hidden` : ``}
			failureUri={urls.failureUri}
			redirectUri={urls.redirectUri}
			successUri={urls.successUri}
			type="LOGIN"
		/>

		<LoginSnsPopupHasEmail
			email={popupData.email}
			loginId={popupData.loginId}
			name={popupData.name}
			regDt={popupData.regDt}
			returnUrl={popupData.returnUrl}
			snsCd={popupData.snsCd}
			uid={popupData.uid}
		/>

		<LoginSnsPopupNoEmail
			ret={popupData.ret}
			returnUrl={popupData.returnUrl}
			snsCd={popupData.snsCd}
			snsEmail={popupData.snsEmail}
			snsName={popupData.name}
			uid={popupData.uid}
		/>
	</>
)

export default LoginSnsAreaView
