'use client'

import HeaderRightSearchLayerKeywordView from '@dg/common/components/Mobile/Header/HeaderView/HeaderRightSearchLayer/HeaderRightSearchLayerView/HeaderRightSearchLayerKeyword/HeaderRightSearchLayerKeywordView/HeaderRightSearchLayerKeywordView'
import useLayerpopup from '@dg/common/lib/hooks/useLayerpopup'
import {
	useCallback, useEffect, useMemo, useState
} from 'react'
import type {
	SearchMobilePopupApiProps
} from '@dg/common/lib/mobile/api'

interface HeaderRightSearchLayerKeywordProps {
	list?: SearchMobilePopupApiProps[`result`][`hot_keywords`];
}

const HeaderRightSearchLayerKeyword = ({
	list
}: HeaderRightSearchLayerKeywordProps) => {
	const layerpopup = useLayerpopup()

	const data = useMemo(() => ({
		delayFunc: null as unknown as ReturnType<typeof setTimeout>
	}), [])

	const [
		pageData,
		setPageData
	] = useState({
		open: false,
		selIndex: 0
	})

	const cntFunc = useCallback(() => {
		if (list !== undefined) {
			const nextIndex = pageData.selIndex + 1 >= list.length ? 0 : pageData.selIndex + 1

			if (pageData.open === false) {
				data.delayFunc = setTimeout(() => {
					setPageData({
						...pageData,
						selIndex: nextIndex
					})
				}, 2000)
			}
		}
	}, [
		data,
		list,
		pageData
	])

	const toggleBtnFunc = useCallback(() => {
		const openValue = pageData.open !== true

		clearTimeout(data.delayFunc)

		if (openValue === true) {
			cntFunc()
		}

		setPageData({
			...pageData,
			open: pageData.open !== true
		})
	}, [
		cntFunc,
		data.delayFunc,
		pageData
	])

	useEffect(() => {
		if (layerpopup.get()[`layerpopup-searchlayer`] !== undefined && layerpopup.get()[`layerpopup-searchlayer`].open === true) {
			clearTimeout(data.delayFunc)

			cntFunc()
		}
	}, [
		cntFunc,
		data.delayFunc,
		layerpopup
	])

	const props = {
		list,
		open: pageData.open,
		selIndex: pageData.selIndex,
		toggleBtnFunc
	}

	return (
		<HeaderRightSearchLayerKeywordView
			{...props}
		/>
	)
}

export default HeaderRightSearchLayerKeyword
