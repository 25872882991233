'use client'

import {
	SelectProps
} from '@dg/common/components/Select/Select'
import formData from '@dg/common/public/json/form'
import SelectNationView from 'member/components/Common/Select/SelectNation/SelectNationView/SelectNationView'
import {
	ChangeEvent, FormEvent, useCallback, useEffect, useState
} from 'react'

interface SelectNationProps extends SelectProps {
	fullNation?: boolean;
	selectValueOnly?: boolean; // 선택된 option만 노출되게 설정
	setValue?: `code` | `name`;
}

const SelectNation = ({
	defaultValue, fullNation, setValue = `code`, selectValueOnly = false, onChange,
	...rest
}: SelectNationProps) => {
	const filteredItem =
		formData.phone.filter((filterItem) => {
			// CANADA, USA 구분이 필요한 경우는 defaultValue에 1-CA, 1-US 로 설정
			const defaultChk = (defaultValue as string || ``).split(`-`)

			const caUsaChk = defaultChk[1] !== undefined ? filterItem.name === defaultChk[1] : true
			const compareValue = setValue === `code` ? filterItem.value.toString() : filterItem.name

			return compareValue === defaultChk[0] && caUsaChk === true
		})

	const selectValueChk = setValue === `code` ? filteredItem[0]?.value.toString() : filteredItem[0]?.name
	const selectDataValueChk = setValue === `code` ? filteredItem[0]?.name : filteredItem[0]?.value.toString()

	const [
		pageData,
		setPageData
	] = useState({
		fullNation: false,
		selectDataValue: ``,
		selectValue: ``
	})

	const fullNationFunc = useCallback((event: FormEvent) => {
		const obj = event.target as HTMLSelectElement

		const setData = {
			...pageData
		}

		setData.selectDataValue =
			(obj.querySelector(`option:checked`) as HTMLOptionElement).getAttribute(`data-value`) ?? ``
		setData.selectValue = (obj.value ?? ``).split(/-/gim)[0]

		if (pageData.fullNation === false && setData.selectValue === `more`) {
			setData.fullNation = true
			setData.selectValue = selectValueChk
			setData.selectDataValue = selectDataValueChk
		}

		setPageData(setData)

		if (onChange !== undefined) {
			onChange(event as ChangeEvent<HTMLSelectElement>)
		}
	}, [
		onChange,
		pageData,
		selectDataValueChk,
		selectValueChk
	])

	useEffect(() => {
		setPageData({
			fullNation: fullNation !== undefined && fullNation,
			selectDataValue: selectDataValueChk,
			selectValue: selectValueChk
		})
	}, [
		fullNation,
		selectDataValueChk,
		selectValueChk
	])

	const props = {
		...rest,
		fullNation: pageData.fullNation,
		fullNationFunc,
		selectDataValue: pageData.selectDataValue,
		selectValue: pageData.selectValue,
		selectValueOnly,
		setValue
	}

	return (
		<SelectNationView
			{...props}
		/>
	)
}

export default SelectNation
