import {
	apiFunc
} from "@dg/common/lib/common"
import {
	getLegacyMobileServerUrl
} from "@dg/common/lib/qoo10Common"
import pathsData from "@dg/common/public/json/mobile/paths"

const getHostCheckedUrl = (url: string) => {
	if (url.startsWith(`http`)) {
		return url
	}

	if (url.startsWith(`/`)) {
		return `${window.location.origin}${url}`
	}

	return getLegacyMobileServerUrl()
}

const getReturnUrlInMobileLoginSubPage = (url?: string, referer?: string) => url ?? referer ??
	getLegacyMobileServerUrl(`/My/MyMain.aspx`)

const apiFuncOnServer = async (
	url: string,
	method = `get` as `get` | `post` | `put` | `delete`,
	params = {}
) => apiFunc(
	`${process.env.apiUrl}${url}`,
	method,
	params
)

const goNextPageOnServer = (url: string) => Promise.resolve({
	redirect: {
		destination: url,
		permanent: false
	}
})

const goMobileLoginPageOnServer = (url?: string) => goNextPageOnServer(`${pathsData.login}?nextUrl=${url ?? ``}`)

const parseTelNo = (telNo: string) => {
	let setTelNo = telNo

	try {
		const returnTelNo: string[] = [
			``,
			``,
			``,
			``
		]

		const arrayTelNo = setTelNo.split(`-`)

		if (arrayTelNo.length === 1) {
			if (setTelNo.startsWith(`+`)) {
				returnTelNo[0] = setTelNo.substring(0, 3).replace(`+`, ``).trim()
				setTelNo = setTelNo.substring(3).trim()
			}

			if (setTelNo.length > 4 && setTelNo.length <= 8) {
				returnTelNo[2] = setTelNo.substring(0, setTelNo.length - 4).trim()
				returnTelNo[3] = setTelNo.substring(setTelNo.length - 4, 4).trim()
			} else if (setTelNo.length > 8) {
				returnTelNo[1] = setTelNo.substring(0, setTelNo.length - 4 - 4).trim()
				returnTelNo[2] = setTelNo.substring(setTelNo.length - 4 - 4, setTelNo.length - 4).trim()
				returnTelNo[3] = setTelNo.substring(setTelNo.length - 4).trim()
			}

			return returnTelNo
		}

		if (arrayTelNo.length === 2) {
			if (arrayTelNo[0].startsWith(`+`)) {
				returnTelNo[0] = arrayTelNo[0].replace(`+`, ``).trim()

				if (arrayTelNo[1].length > 8) {
					returnTelNo[1] = arrayTelNo[1].substring(0, arrayTelNo[1].length - 4 - 4).trim()
					returnTelNo[2] = arrayTelNo[1]
						.substring(arrayTelNo[1].length - 4 - 4, arrayTelNo[1].length - 4).trim()
					returnTelNo[3] = arrayTelNo[1].substring(arrayTelNo[1].length - 4).trim()
				} else if (arrayTelNo[1].length > 4) {
					returnTelNo[2] = arrayTelNo[1].substring(0, arrayTelNo[1].length - 4).trim()
					returnTelNo[3] = arrayTelNo[1].substring(arrayTelNo[1].length - 4).trim()
				} else {
					returnTelNo[2] = arrayTelNo[1].trim()
				}
			} else {
				returnTelNo[2] = arrayTelNo[0].trim()
				returnTelNo[3] = arrayTelNo[1].trim()
			}

			return returnTelNo
		}

		if (arrayTelNo.length === 3) {
			if (arrayTelNo[0].startsWith(`+`)) {
				returnTelNo[0] = arrayTelNo[0].replace(`+`, ``).trim()

				if (arrayTelNo[2].length > 4) {
					returnTelNo[1] = arrayTelNo[1].trim()
					returnTelNo[2] = arrayTelNo[2].substring(0, arrayTelNo[2].length - 4).trim()
					returnTelNo[3] = arrayTelNo[2].substring(arrayTelNo[2].length - 4).trim()
				} else {
					returnTelNo[2] = arrayTelNo[1].trim()
					returnTelNo[3] = arrayTelNo[2].trim()
				}
			} else {
				returnTelNo[1] = arrayTelNo[0].trim()
				returnTelNo[2] = arrayTelNo[1].trim()
				returnTelNo[3] = arrayTelNo[2].trim()
			}

			return returnTelNo
		}

		if (arrayTelNo.length === 4) {
			returnTelNo[0] = arrayTelNo[0].replace(`+`, ``).trim()
			returnTelNo[1] = arrayTelNo[1].trim()
			returnTelNo[2] = arrayTelNo[2].trim()
			returnTelNo[3] = arrayTelNo[3].trim()

			return returnTelNo
		}

		if (arrayTelNo.length > 4) {
			returnTelNo[0] = arrayTelNo[0].replace(`+`, ``).trim()
			returnTelNo[1] = arrayTelNo[1].trim()
			returnTelNo[2] = arrayTelNo[2].trim()
			returnTelNo[3] = arrayTelNo[3].trim()

			for (let i = 4; i < arrayTelNo.length; i += 1) {
				returnTelNo[3] += arrayTelNo[i].trim()
			}

			return returnTelNo
		}
	} catch (error) {
		const errorMessage = error instanceof Error ? error.message : `Unknown Error`

		console.log(errorMessage)
	}

	return `---`.split(`-`)
}

const checkValidLoginHpNo = (phoneNumber: string) => {
	const chk = /[^0-9]+/

	return !chk.test(phoneNumber)
}

const checkValidLoginHpNoNation = (phoneCountry: string, phoneNum: string) => {
	if (phoneCountry === `65` || phoneCountry === `852`) {
		return phoneNum.length === 8
	}

	if (phoneCountry === `62`) {
		return phoneNum.length === 11 || phoneNum.length === 12
	}

	if (phoneCountry === `81` || phoneCountry === `82`) {
		return phoneNum.length === 11
	}

	if (phoneCountry === `60` || phoneCountry === `86`) {
		return phoneNum.length === 10 || phoneNum.length === 11
	}

	return phoneNum.length >= 8 && phoneNum.length <= 14
}

const chkLoginHpNoNum = (str: string) => str.trim().replace(/[^0-9]/g, ``)

const chkLoginHpNoChar = (str: string) => {
	const regexp = /[0-9-+]/

	for (let i = 0; i < str.length; i += 1) {
		if (str.charAt(i) !== ` ` && regexp.test(str.charAt(i)) === false) {
			return false
		}
	}

	return true
}

const getUrlWithScriptCheck = (url: string): string => (url && url.toLowerCase().indexOf(`script`) < 0 ? url : ``)

const isReturnUrlIsRelatedLoginPage = (url: string): boolean => {
	if (
		url.indexOf(`/find/id`) >= 0 ||
		url.indexOf(`/find/pw`) >= 0 ||
		url.toLowerCase().indexOf(`/join`) >= 0 ||
		url.toLowerCase().indexOf(`/login`) >= 0
	) {
		if (url.toLowerCase().indexOf(`/pc/join/moreinfo`) >= 0) return false

		return true
	}

	return false
}

const getString = (str: string[] | string | undefined) => {
	if (str === undefined) return ``

	if (Array.isArray(str)) return str[0]

	return str
}

export {
	getHostCheckedUrl,
	getReturnUrlInMobileLoginSubPage,
	apiFuncOnServer,
	goMobileLoginPageOnServer,
	goNextPageOnServer,
	parseTelNo,
	checkValidLoginHpNo,
	checkValidLoginHpNoNation,
	chkLoginHpNoNum,
	chkLoginHpNoChar,
	getUrlWithScriptCheck,
	isReturnUrlIsRelatedLoginPage,
	getString
}
