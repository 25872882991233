'use client'

import FooterTabLiView from '@dg/common/components/Pc/Footer/FooterView/FooterTabLiView'
import pathsData from '@dg/common/public/json/pc/paths'
import {
	useTranslation
} from 'react-i18next'

const FooterTabView = () => {
	const {
		t
	} = useTranslation()

	return (
		<nav
			className="h-11 bg-dg-300 dark:bg-dgdark-300"
		>
			<ul
				className="relative mx-auto flex h-full w-pc flex-nowrap items-center text-xs"
			>
				<FooterTabLiView
					link={pathsData.company}
				>
					{t(`회사개요`)}
				</FooterTabLiView>

				<FooterTabLiView
					link={pathsData.agreement}
				>
					{t(`이용 약관`)}
				</FooterTabLiView>

				<FooterTabLiView
					link={pathsData.policy}
				>
					{t(`개인 정보 보호 정책`)}
				</FooterTabLiView>

				<FooterTabLiView
					link={pathsData.cookiePolicy}
				>
					{t(`외부 전송 정책`)}
				</FooterTabLiView>

				<FooterTabLiView
					link={`${pathsData.company}?partnership=1`}
				>
					{t(`제휴`)}
				</FooterTabLiView>

				<FooterTabLiView
					link={pathsData.registerSellerFooter}
				>
					{t(`출점자 등록`)}
				</FooterTabLiView>

				<FooterTabLiView
					link={pathsData.university}
				>
					{t(`Qoo10대학`)}
				</FooterTabLiView>

				<FooterTabLiView
					link={pathsData.help}
				>
					{t(`고객센터`)}
				</FooterTabLiView>

				<FooterTabLiView
					link={pathsData.qsafeIntro}
				>
					{t(`Q어카운트 프로그램`)}
				</FooterTabLiView>

				<FooterTabLiView
					link={pathsData.dpl}
					target="_blank"
					last
				>
					{t(`거래 DPL법에 관한 대처`)}
				</FooterTabLiView>
			</ul>
		</nav>
	)
}

export default FooterTabView
