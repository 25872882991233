'use client'

import {
	addCookie
} from '@dg/common/lib/common'
import useLayerpopup from '@dg/common/lib/hooks/useLayerpopup'
import useSetApi from '@dg/common/lib/hooks/useSetApi'
import {
	forwardOpener, getLegacyServerUrl, procOpenerFunc, layerAlert
} from '@dg/common/lib/qoo10Common'
import SnsPopupNoEmailView from 'member/components/Pc/Login/LoginView/LoginSnsPopup/LoginSnsPopupNoEmail/LoginSnsPopupNoEmailView/LoginSnsPopupNoEmailView'
import {
	setNameOrEmailPageApi,
	SetNameOrEmailPageApiProps,
	validationEmailApi,
	ValidationEmailApiProps
} from 'member/lib/api'
import {
	useRouter
} from 'next/navigation'
import React, {
	useEffect, useState
} from 'react'
import {
	useTranslation
} from 'react-i18next'

interface SnsPopupNoEmailParameterDataType {
	ret: string;
	returnUrl: string;
	snsCd: string;
	snsEmail: string;
	snsName: string;
	uid: string;
}

interface loginDataType {
	email: string;
	name: string;
}

const SnsPopupNoEmail = ({
	snsCd = ``, snsEmail = ``, snsName = ``, uid = ``, returnUrl = getLegacyServerUrl()
}: SnsPopupNoEmailParameterDataType) => {
	const {
		t
	} = useTranslation()

	const router = useRouter()
	const layerpopup = useLayerpopup()

	const [
		loginData,
		setLoginData
	] = useState<loginDataType>({
		email: snsEmail || ``,
		name: snsName || ``
	})

	useEffect(() => {
		if (snsEmail) {
			setLoginData({
				email: snsEmail,
				name: snsName
			})
		}
	}, [
		snsEmail
	])

	const onChangeLoginData = (event: React.ChangeEvent<HTMLInputElement>) => {
		setLoginData({
			...loginData,
			[event.target.name]: event.target.value
		})
	}

	const [
		submitDisabledBtn,
		setSubmitDisabledBtn
	] = useState(true)

	const validationEmail = useSetApi({
		api: validationEmailApi,
		successCallback: (successData: ValidationEmailApiProps) => {
			// TODO Submit BTN 활성화 로직 추가 필요
			if (successData.code === 0) {
				setSubmitDisabledBtn(false)
			}

			layerpopup.open({
				id: successData.code === 0 ? `layerpopup-snsemail-valid` : `layerpopup-snsemail-invalid`
			})
		}
	})

	const checkEmailAllowDomain = (email: string) => {
		const allowdomain = `/world/`
		const domain = email.substring(email.lastIndexOf(`.`) + 1).toLowerCase()

		if (domain.length > 0 && allowdomain.indexOf(`/${domain}/`) > -1) return true

		return false
	}

	const isValidEmailAddress = (email: string) => {
		if (email.lastIndexOf(` `) > 0) {
			return false
		}

		let regText = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,4}$/i

		if (checkEmailAllowDomain(email)) {
			regText = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.*$/i
		}

		return regText.test(email)
	}

	const onClickCheckEmail = () => {
		let isOpenLayer = true

		if (loginData.email !== ``) {
			if (isValidEmailAddress(loginData.email)) {
				if (loginData.email.indexOf(`.@`) < 0) {
					const formData = new URLSearchParams()

					formData.append(`email`, loginData.email)

					validationEmail.set(formData)

					isOpenLayer = false
				}
			}
		}

		if (isOpenLayer) {
			layerpopup.open({
				id: `layerpopup-snsemail-invalid`
			})
		}
	}

	const onClickClose = () => {
		layerpopup.close(`layerpopup_noemail`)
	}

	const redirect = (isRegister = false) => {
		if (returnUrl.startsWith(`excute:`)) {
			forwardOpener(returnUrl)

			return true
		}

		if (returnUrl.startsWith(`exopn://`)) {
			const func = `${returnUrl.substring(8)}CallFromPopup`

			procOpenerFunc(func)

			return true
		}

		if (isRegister) {
			addCookie(`next_page_popup=snsregistereventpopup_:::_0_:::_0`, 1)
		}

		return router.push(returnUrl)
	}

	const setNameOrEmail = useSetApi({
		api: setNameOrEmailPageApi,
		successCallback: (successData: SetNameOrEmailPageApiProps) => {
			if (successData.code === 0) {
				redirect()
			} else if (successData.code === 1) {
				redirect(true)
			}

			return false
		}
	})

	const onClickSubmit = () => {
		if (loginData.name === ``) {
			layerAlert(layerpopup.open, t(`성명을 정확하게 입력하십시오.`))

			return false
		}

		if (loginData.email === ``) {
			layerAlert(layerpopup.open, t(`먼저 사용 가능한 메일인지 확인하십시오.`))

			return false
		}

		const formData = new URLSearchParams()

		formData.append(`uid`, uid || ``)
		formData.append(`snsCd`, snsCd || ``)
		formData.append(`snsName`, loginData.name)
		formData.append(`snsEmail`, loginData.email)

		return setNameOrEmail.set(formData)
	}

	const props = {
		email: loginData.email,
		name: loginData.name,
		onChangeLoginData,
		onClickCheckEmail,
		onClickClose,
		onClickSubmit,
		submitDisabledBtn
	}

	return (
		<SnsPopupNoEmailView
			{...props}
		/>
	)
}

export default SnsPopupNoEmail
