'use client'

import LoginGuestAfterGoodsContView from "./LoginGuestAfterGoodsContView/LoginGuestAfterGoodsContView"

interface LoginGuestAfterGoodsContProps {
	orderUrl: string;
}

const LoginGuestAfterGoodsCont = ({
	orderUrl
}: LoginGuestAfterGoodsContProps) => (
	<LoginGuestAfterGoodsContView
		orderUrl={orderUrl}
	/>
)

export default LoginGuestAfterGoodsCont
