'use client'

import Button from '@dg/common/components/Button'
import useGetApi from '@dg/common/lib/hooks/useGetApi'
import useImageValidate from '@dg/common/lib/hooks/useImageValidate'
import {
	gaDimensionFunc, getLegacyServerUrl
} from '@dg/common/lib/qoo10Common'
import imageData from '@dg/common/public/json/imageData'
import {
	UseQueryResult
} from '@tanstack/react-query'
import LoginInfoView from 'member/components/Pc/Login/LoginView/LoginInfoView'
import {
	loginBannersApi, LoginBannersApiProps
} from 'member/lib/pc/api'
import Image from 'next/image'

interface LoginGuideViewProps {
	isGuestAfterGoodsLogin?: boolean;
	isNoLoginQuestion: boolean;
	memberType: string;
	seller?: string;
}

const LoginGuideView = ({
	isGuestAfterGoodsLogin,
	isNoLoginQuestion,
	memberType,
	seller
}: LoginGuideViewProps) => {
	const imageValidate = useImageValidate()

	const loginPageBannerDataApi = useGetApi({
		api: loginBannersApi,
		name: `loginPageBannerDataApi`
	}) as UseQueryResult<LoginBannersApiProps>

	return (
		<div
			className="relative flex w-[375px] flex-col px-6 text-xs"
		>
			{
				seller !== `true` &&
				loginPageBannerDataApi.data?.result !== null &&
				loginPageBannerDataApi.data?.result.link !== undefined &&
				loginPageBannerDataApi.data?.result.link !== null && (
					<Button
						onClick={
							() => {
								gaDimensionFunc({
									data: {
										ep_event_area: `click`,
										ep_event_label: ``,
										ep_event_page: `pc_login_banner`,
										event_name: `click_event`
									},
									event: true,
									url: window.location.href
								})

								// gaDimensionFunc 이벤트 호출로 인해 setTimeout 설정
								setTimeout(() => {
									// eslint-disable-next-line @stylistic/max-len
									window.location.href = getLegacyServerUrl(loginPageBannerDataApi.data?.result.link.replace(/.*\/gmkt\.inc/, ``))
								}, 500)
							}
						}
					>
						<Image
							alt="배너이미지"
							height={150}
							onError={imageValidate.onError}
							placeholder={imageData.emptyImage}
							src={loginPageBannerDataApi.data?.result.src}
							width={327}
							priority
						/>
					</Button>
				)
			}

			{
				!isGuestAfterGoodsLogin && (
					<div
						className={seller !== `true` ? `mt-16` : `mt-2`}
					>
						<LoginInfoView
							isNoLoginQuestion={isNoLoginQuestion}
							memberType={memberType}
						/>
					</div>
				)
			}
		</div>
	)
}

export default LoginGuideView
