'use client'

import Link from "@dg/common/components/Link"
import {
	classNameFunc
} from "@dg/common/lib/common"
import useImageValidate from '@dg/common/lib/hooks/useImageValidate'
import pathsData from "@dg/common/public/json/pc/paths"
import Image from 'next/image'

interface LoginGuestAfterGoodsContViewProps {
	orderUrl: string;
}

const LoginGuestAfterGoodsContView = ({
	orderUrl
}: LoginGuestAfterGoodsContViewProps) => {
	const imageValidate = useImageValidate()

	return (
		<>
			<div
				className={
					classNameFunc(`
						mx-[-30px] mb-2.5 mt-3 rounded-3xl bg-[#f1f1f1] px-9 py-2.5
						text-2xs transition-colors
					`)
				}
			>
				<strong>
					ゲストとして注文される方へ
				</strong>

				<p>
					次の注文画面で
					<em>
						メールアドレス
					</em>
					と

					<em>
						携帯電話番号
					</em>
					を正確にご入力ください。注文後に商品の配送状況などを確認する際、必要となります。
				</p>
			</div>

			<Link
				btnType="pcBlue"
				href={orderUrl}
				className={
					classNameFunc(`
						mx-auto my-0 block w-[180px] pb-3 pt-3.5
						text-center text-sm font-bold leading-4
					`)
				}
			>
				続ける
			</Link>

			<div
				className="my-2.5 text-center text-2xs font-bold leading-3"
			>
				<p>
					* ゲストは一定の金額内でのみ購入できます。
					<br />
					またQoo10の様々な特典は利用できません。
				</p>
			</div>

			<div
				className="bg-[#f1f6fb] px-5 pb-6 pt-5"
			>
				<Image
					alt="Join Coupon Info"
					className="object-cover"
					height={140}
					onError={imageValidate.onError}
					src="/images/pc/login/login-joinInfo.png"
					width={350}
				/>

				<Link
					btnType="pcBlue"
					href={pathsData.join}
					className={
						classNameFunc(`
							mx-auto my-0 block h-12.5 w-[275px]
							text-center text-sm font-bold leading-[50px]
						`)
					}
				>
					会員登録(無料)
				</Link>
			</div>
		</>
	)
}

export default LoginGuestAfterGoodsContView
