'use client'

import HeaderSearchView from '@dg/common/components/Pc/Header/HeaderView/HeaderSearch/HeaderSearchView/HeaderSearchView'
import {
	searchRelatedApi, SearchRelatedApiProps
} from '@dg/common/lib/api'
import {
	addCookie
} from '@dg/common/lib/common'
import useSetApi from '@dg/common/lib/hooks/useSetApi'
import {
	SearchPopularApiProps, searchRelatedGoodsApi, SearchRelatedGoodsApiProps
} from '@dg/common/lib/pc/api'
import {
	useQueryClient
} from '@tanstack/react-query'
import React, {
	FocusEvent, FormEvent, useCallback, useEffect, useMemo, useState
} from 'react'

interface HeaderFormDataProps extends HTMLFormControlsCollection {
	[`header-search`]: HTMLInputElement;
}

const HeaderSearch = () => {
	const queryClient = useQueryClient()

	const data = useMemo(() => ({
		searchInputDelayFunc: null as unknown as ReturnType<typeof setTimeout>
	}), [])

	const [
		keyWordList,
		setKeyqordList
	] = useState([] as SearchPopularApiProps[`result`])

	const [
		pageData,
		setPageData
	] = useState({
		searchLayerChk: false,
		searchRelatedItemList: [] as SearchRelatedGoodsApiProps[`result`],
		searchRelatedKeyword: ``,
		searchRelatedKeywordList: [] as SearchPopularApiProps[`result`],
		searchRelatedLayerChk: false
	})

	const headerSearchRelatedApi = useSetApi({
		api: (variables: string) => {
			const length = 3

			return searchRelatedGoodsApi(variables, length)
		},
		progress: false,
		successCallback: (successData: SearchRelatedGoodsApiProps, variables: string) => {
			if (successData.code === 0) {
				setPageData({
					...pageData,
					searchRelatedItemList: successData.result,
					searchRelatedKeyword: variables,
					searchRelatedLayerChk: true
				})
			}
		}
	})

	const headerPcSearchRelatedApi = useSetApi({
		api: (keyword: string) => searchRelatedApi(keyword),
		progress: false,
		successCallback: (successData: SearchRelatedApiProps, variables: string) => {
			if (successData.code === 0) {
				const relatedListArray = [] as SearchPopularApiProps[`result`]

				for (const item of successData.result.result_list ?? []) {
					const regExpValue = variables.replace(/\\/gim, `\\\\`)

					const regValue = new RegExp(`(${regExpValue})`, `gim`)
					const replacer = (match: string, p1: string) => `<strong>${p1}</strong>`

					relatedListArray?.push({
						keyword: item.replace(regValue, replacer),
						originalKeyword: item
					})
				}

				setPageData({
					...pageData,
					searchRelatedKeywordList: relatedListArray
				})
			}
		}
	})

	const searchRelatedItemApiFunc = useCallback((event: FocusEvent | React.MouseEvent, keyword = ``) => {
		if (event.type === `mouseover`) {
			headerSearchRelatedApi.set(keyword)
		} else {
			const obj = event.relatedTarget as HTMLElement

			if (obj !== null) {
				const btnObj = obj.closest(`button`)
				const itemLayerObj = obj.closest(`.item-layer`)

				if (btnObj === null && itemLayerObj === null && pageData.searchRelatedLayerChk === true) {
					setPageData({
						...pageData,
						searchRelatedItemList: [],
						searchRelatedKeyword: ``,
						searchRelatedLayerChk: false
					})
				}
			}
		}
	}, [
		headerSearchRelatedApi,
		pageData
	])

	const searchLayerToggleFunc = useCallback((event: MouseEvent, value = false) => {
		const obj = event.target as HTMLElement

		const searchLayerChk1 = obj.nodeName === `INPUT` && obj.getAttribute(`type`) === `search` && value === true

		const searchLayerChk2 =
			obj.closest(`form`) === null &&
			(obj.nodeName === `INPUT` && obj.getAttribute(`type`) === `search`) === false &&
			value === false

		const searchLayerChk3 = obj.closest(`.btn-searchlayerclose`) !== null
		const searchLayerChk4 = searchLayerChk1 || searchLayerChk2 || searchLayerChk3

		if (pageData.searchLayerChk !== value && searchLayerChk4) {
			setPageData({
				...pageData,
				searchLayerChk: value
			})
		}
	}, [
		pageData
	])

	const searchLayerResetCookieFunc = useCallback(async () => {
		addCookie(`recentKeyword=`, 0)

		await queryClient.refetchQueries({
			queryKey: [
				`headerPcSearchPopluarApi`
			]
		})

		setKeyqordList([])
	}, [
		queryClient
	])

	const searchFunc = useCallback((event: FormEvent) => {
		if (event.cancelable === true) {
			event.preventDefault()
			event.stopPropagation()
		}

		const obj = event.target as HTMLFormElement

		if (event.type === `change`) {
			const value = obj.value as string

			clearTimeout(data.searchInputDelayFunc)

			if (value !== ``) {
				data.searchInputDelayFunc = setTimeout(() => {
					headerPcSearchRelatedApi.set(value)
				}, 300)
			} else {
				setPageData({
					...pageData,
					searchRelatedKeywordList: []
				})
			}
		} else {
			const objElements = obj.elements as HeaderFormDataProps

			const inputValue = objElements[`header-search`].value

			const searchCookieName = `recentKeyword=`
			let cookieValue = `${searchCookieName}${inputValue}`

			if (document.cookie.match(searchCookieName) !== null) {
				const arrayList = document.cookie.match(searchCookieName)?.input?.split(searchCookieName)[1].split(`_`)

				arrayList?.unshift(inputValue)

				const setArray = [
					...new Set(arrayList)
				]

				cookieValue = `${searchCookieName}`

				for (const [
					index,
					item
				] of setArray.entries()) {
					cookieValue += `${index > 0 ? `_` : ``}${item}`
				}
			}

			addCookie(cookieValue, 30)

			const linkUrl =
				`${process.env.legacyPcUrl}/s/${inputValue.toUpperCase()}?keyword=${inputValue}`

			window.location.href = linkUrl
		}
	}, [
		data,
		headerPcSearchRelatedApi,
		pageData
	])

	useEffect(() => {
		const listArray = [] as SearchPopularApiProps[`result`]

		const cookieName = `recentKeyword=`

		if (document.cookie.match(cookieName) !== null) {
			const favoriteKeyword = document.cookie.split(cookieName)[1].split(`;`)[0].split(`_`)

			for (const item of favoriteKeyword) {
				listArray?.push({
					keyword: item
				})
			}
		}

		setKeyqordList(listArray)

		const wrap = document.querySelector(`#wrap`) as HTMLDivElement

		wrap.addEventListener(`click`, searchLayerToggleFunc)

		const cleanup = () => {
			if (wrap !== null) {
				wrap.removeEventListener(`click`, searchLayerToggleFunc)
			}
		}

		return cleanup
	}, [
		searchLayerToggleFunc
	])

	const props = {
		searchCookieKeywordList: keyWordList,
		searchFunc,
		searchLayerChk: pageData.searchLayerChk,
		searchLayerResetCookieFunc,
		searchLayerToggleFunc,
		searchRelatedItemApiFunc,
		searchRelatedItemList: pageData.searchRelatedItemList,
		searchRelatedKeyword: pageData.searchRelatedKeyword,
		searchRelatedKeywordList: pageData.searchRelatedKeywordList,
		searchRelatedLayerChk: pageData.searchRelatedLayerChk
	}

	return (
		<HeaderSearchView
			{...props}
		/>
	)
}

export default HeaderSearch
