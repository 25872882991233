'use client'

import Button from "@dg/common/components/Button"
import Layerpopup from "@dg/common/components/Layerpopup"
import {
	classNameFunc, stringTrimFunc
} from "@dg/common/lib/common"
import InputLabelView from "member/components/Common/Find/InputLabelView"
import StackView from "member/components/Common/Find/StackView"
import ValidateInput from "member/components/Common/Validate/ValidateInput"
import React from "react"

export interface loginDataType {
	email: string;
	name: string;
	snsEmail: string;
}

interface LoginSnsPopupHasEmailViewProps {
	loginData: loginDataType;
	loginId: string;
	onChangePassword: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onClickCloseButton: () => void;
	onClickFindPW: () => void;
	onClickSubmitButton: () => void;
	password: string;
	regDt: string;
	snsName: string;
}

const LoginSnsPopupHasEmailView = ({
	loginId,
	loginData,
	onChangePassword,
	onClickSubmitButton,
	onClickFindPW,
	onClickCloseButton,
	password,
	regDt,
	snsName
}: LoginSnsPopupHasEmailViewProps) => {
	const btnClassName = classNameFunc(`mx-3 !min-h-8 w-18 text-2xs`)

	return (
		<Layerpopup
			className="!w-[500px] pb-8"
			id="layerpopup_haspopup"
			setTitle="アカウントにアクセス"
			setWidth={false}
			customFooter={
				(
					<div
						className="mx-auto flex items-center"
					>
						<Button
							btnType="pcBlue"
							className={btnClassName}
							onClick={onClickSubmitButton}
							rounded
						>
							接続する
						</Button>

						<Button
							btnType="pcBlue"
							className={btnClassName}
							onClick={onClickCloseButton}
							rounded
						>
							閉じる
						</Button>
					</div>
				)
			}
		>
			<div>
				<StackView
					direction="col"
				>
					<p
						className="mt-3 text-left text-2xs"
					>
						{`${loginData.name}様`}

						<br />

						{
							stringTrimFunc(`
								${loginData.name}様の${snsName}に登録されているメール[${loginData.email}]は既にQoo10会員として登録されています。
								該当のQoo10アカウントよりログインし本人確認をし${snsName}のアカウントと連動させてください。
								アカウントを連動しない場合はメールアドレスの重複により${snsName}からログインし利用することができません。
							`)
						}
					</p>

					<StackView
						className="mt-2 bg-dg-400 pb-2 pt-1"
						direction="col"
					>
						<StackView
							className="mt-3 items-center text-2xs"
						>
							<InputLabelView
								className="w-30 text-dg-black"
								fontWeight="bold"
								name="text"
								title="登録サイト"
							/>

							<p
								className="text-[#0066bc]"
							>
								www.qoo10.jp
							</p>
						</StackView>

						<StackView
							className="mt-3 items-center text-2xs"
						>
							<InputLabelView
								className="w-30 text-dg-black"
								fontWeight="bold"
								name="text"
								title="メール"
							/>

							<p>
								{`${loginData.snsEmail} (${regDt})`}
							</p>
						</StackView>

						{
							loginId && loginId.length > 0 ?
								(
									<StackView
										className="mt-3 items-center text-2xs"
									>
										<InputLabelView
											className="w-30 text-dg-black"
											fontWeight="bold"
											name="text"
											title="ID"
										/>

										<p>
											{loginId}
										</p>
									</StackView>
								) :
								(
									<>
									</>
								)
						}

						<StackView
							className="relative mt-3 items-start text-2xs"
						>
							<InputLabelView
								className="leading-5.5 h-5.5 w-30 text-dg-black"
								fontWeight="bold"
								name="password"
								title="パスワード"
							/>

							<div
								className="flex flex-wrap"
							>
								<ValidateInput
									borderType="outline"
									className="h-5 border-[#7f9db9] px-2"
									name="password"
									onChange={onChangePassword}
									type="password"
									validateTextClassName="whitespace-nowrap"
									validateType="password"
									value={password}
								/>

							</div>

							<Button
								onClick={onClickFindPW}
								className={
									classNameFunc(`
										absolute right-7 top-1 whitespace-nowrap font-bold
										text-[#007fe1] underline first-letter:top-1
									`)
								}
								rounded
							>
								パスワードを忘れた方は
							</Button>
						</StackView>
					</StackView>
				</StackView>
			</div>
		</Layerpopup>
	)
}

export default LoginSnsPopupHasEmailView
