'use client'

import Button from "@dg/common/components/Button"
import Input from "@dg/common/components/Input"
import Captcha from "@dg/common/components/Pc/Common/Captcha"
import Select from '@dg/common/components/Select'
import Tooltip from '@dg/common/components/Tooltip'
import {
	classNameFunc
} from "@dg/common/lib/common"
import useTooltip from "@dg/common/lib/hooks/useTooltip"
import {
	QuestionMarkCircleIcon
} from '@heroicons/react/24/outline'
import InputLabelView from 'member/components/Common/Find/InputLabelView'
import InputView from "member/components/Common/Find/InputView"
import StackView from 'member/components/Common/Find/StackView'
import ValidateInput from "member/components/Common/Validate/ValidateInput"
import {
	loginInfoType
} from "member/components/Pc/Login/LoginType"
import React from "react"

interface LoginMemberContViewProps {
	loginInfo: loginInfoType;
	loginType: string;
	onChangeCheckValue: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onChangePWDValue: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onChangePhoneNation: (event: React.ChangeEvent<HTMLSelectElement>) => void;
	onChangePhoneNumber: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onUserLoginIdChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onUserLoginTypeChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
	seller?: string;
}

const LoginMemberContView = ({
	seller,
	loginInfo,
	loginType,
	onChangeCheckValue,
	onChangePWDValue,
	onChangePhoneNation,
	onChangePhoneNumber,
	onUserLoginIdChange,
	onUserLoginTypeChange
}: LoginMemberContViewProps) => {
	const tooltip = useTooltip()

	return (
		<>
			<StackView
				className="mt-2 flex w-full text-sm"
			>
				<Select
					borderType="outline"
					onChange={onUserLoginTypeChange}
					value={loginType}
					className={
						classNameFunc(`
							mr-2.5 h-8 w-30 flex-none self-baseline border-dg-400 !bg-[center_right_0.75rem] !px-3
							!text-xs
						`)
					}
					rounded
				>
					<option
						key="input_email_id"
						value="id"
					>
						メール or ID
					</option>

					<option
						key="input_telephone"
						value="phone"
					>
						携帯電話番号
					</option>
				</Select>

				{
					loginType === `id` ?
						(
							<div
								className="flex-1 flex-col rounded-md"
							>
								<InputView
									className="w-full border-0"
									id="id"
									name="id"
									onChange={onUserLoginIdChange}
									type="text"
									value={loginInfo.email}
									variant="outlined-8"
									px
									rounded
								/>
							</div>
						) :
						(
							<div
								className="flex w-full flex-col overflow-hidden"
							>
								<ValidateInput
									borderType="outline"
									className="mt-2.5 !h-8 w-full flex-1 border-[#f1f1f1]"
									nationClassName={classNameFunc(`!h-8 w-full rounded border-[#f1f1f1] !px-1 !pl-2`)}
									nationOnChange={onChangePhoneNation}
									onChange={onChangePhoneNumber}
									type="tel"
									validateType="phone"
									value={loginInfo.phoneNumber}
									px
									rounded
								/>
							</div>
						)
				}
			</StackView>

			<StackView
				className="mt-4 flex items-start text-sm"
			>
				<InputLabelView
					className="mr-2.5 h-8 w-30 pt-1.5"
					name="password"
					title="パスワード"
				/>

				<InputView
					className="flex-1 border-[#f1f1f1]"
					onChange={onChangePWDValue}
					type="password"
					validateType="password"
					value={loginInfo.password}
					variant="outlined-8"
					rounded
				/>
			</StackView>

			<Captcha
				key={`login-captcha-${loginInfo.refreshCount.toString()}`}
				inputHeight="h-8"
				loginId={loginInfo.loginId}
				refreshCount={loginInfo.refreshCount}
				titleWidth="w-30"
			/>

			{
				seller !== `true` && (
					<div
						className="ml-32"
					>
						<Input
							checked={loginInfo.checkbox}
							className="size-4 px-2"
							name="checkbox"
							onChange={onChangeCheckValue}
							spanClassName="text-xs"
							text="ログイン状態を維持する"
							type="checkbox"
						/>

						<Button
							className="ml-1 align-baseline"
							onClick={
								(event) => {
									tooltip.open({
										id: `tooltip-loginstatus`,
										target: event.currentTarget
									})
								}
							}
						>
							<QuestionMarkCircleIcon
								className="size-4 text-[#517fef]"
							/>
						</Button>

						<Tooltip
							id="tooltip-loginstatus"
							width="w-[500px]"
							rounded
						>
							「ログイン状態を維持する」をチェックしログインすると、
							ブラウザを閉じたりパソコンを終了してもログイン状態が維持されます。
							毎回メール/IDとパスワードを入力する手間が省け、より便利にQoo10をご利用いただけます。
							ログイン状態の維持はQoo10を最後にご利用されてから90日間維持されます。
							解除する場合は、トップページの「ログアウト」を押してください。
							また個人情報保護のため個人のパソコンでのみご利用をお勧めします。
						</Tooltip>
					</div>
				)
			}
		</>
	)
}

export default LoginMemberContView
