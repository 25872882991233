'use client'

import Button from '@dg/common/components/Button'
import Link from '@dg/common/components/Link'
import List from '@dg/common/components/Pc/Common/List'
import HeaderSearchLayerUlView from '@dg/common/components/Pc/Header/HeaderView/HeaderSearch/HeaderSearchView/HeaderSearchLayerUlView'
import {
	classNameFunc
} from '@dg/common/lib/common'
import useGetApi from '@dg/common/lib/hooks/useGetApi'
import {
	searchPopularApi, SearchPopularApiProps, SearchRelatedGoodsApiProps
} from '@dg/common/lib/pc/api'
import {
	UseQueryResult
} from '@tanstack/react-query'
import React, {
	FocusEvent
} from 'react'
import {
	useTranslation
} from 'react-i18next'

interface HeaderSearchLayerViewProps {
	searchCookieKeywordList: SearchPopularApiProps[`result`];
	searchLayerChk: boolean;
	searchLayerResetCookieFunc: () => void;
	searchLayerToggleFunc: (event: MouseEvent, value: boolean) => void;
	searchRelatedItemApiFunc: (event: FocusEvent | React.MouseEvent) => void;
	searchRelatedItemList: SearchRelatedGoodsApiProps[`result`];
	searchRelatedKeyword: string;
	searchRelatedKeywordList: SearchPopularApiProps[`result`];
	searchRelatedLayerChk: boolean;
}

const HeaderSearchLayerView = ({
	searchLayerChk,
	searchRelatedLayerChk,
	searchCookieKeywordList,
	searchRelatedKeywordList,
	searchRelatedKeyword,
	searchRelatedItemList,
	searchRelatedItemApiFunc,
	searchLayerToggleFunc,
	searchLayerResetCookieFunc
}: HeaderSearchLayerViewProps) => {
	const {
		t
	} = useTranslation()

	const headerPcSearchPopluarApi = useGetApi({
		api: () => searchPopularApi(10 - (searchCookieKeywordList.length || 0)),
		name: `headerPcSearchPopluarApi`
	}) as UseQueryResult<SearchPopularApiProps>

	return (
		<div
			onBlur={searchRelatedItemApiFunc}
			onMouseOut={searchRelatedItemApiFunc}
			className={
				classNameFunc(`
					search-layer absolute left-0 top-full w-full bg-dg-white text-xs
					shadow-md dark:bg-dgdark-white
					${searchLayerChk === false ? `hidden` : ``}
				`)
			}
		>
			{
				headerPcSearchPopluarApi.data !== undefined && (
					searchRelatedKeywordList !== undefined && searchRelatedKeywordList.length > 0 ?
						(
							<HeaderSearchLayerUlView
								list={searchRelatedKeywordList}
								searchRelatedItemApiFunc={searchRelatedItemApiFunc}
							/>
						) :
						(
							<>
								{
									searchCookieKeywordList !== undefined && searchCookieKeywordList.length > 0 && (
										<HeaderSearchLayerUlView
											list={searchCookieKeywordList}
											searchRelatedItemApiFunc={searchRelatedItemApiFunc}
											title={t(`검색 기록`)}
										/>
									)
								}

								{
									headerPcSearchPopluarApi.data.result.length > 0 && (
										<HeaderSearchLayerUlView
											list={headerPcSearchPopluarApi.data.result}
											searchRelatedItemApiFunc={searchRelatedItemApiFunc}
											title={t(`인기 키워드`)}
										/>
									)
								}
							</>
						)
				)
			}

			{
				searchRelatedItemList.length > 0 && (
					<div
						className={
							classNameFunc(`
								item-layer absolute left-3/5 top-1 ml-3 mt-1 w-9/10 overflow-y-auto
								bg-dg-white text-xs shadow-md dark:bg-dgdark-white
								${searchRelatedLayerChk === false ? `hidden` : ``}
							`)
						}
					>
						<List
							liClassName="px-3 py-2.5 [&+li]:border-t [&+li]:border-solid [&+li]:border-[#cfcfcf]"
							linkName="gd_no"
							list={searchRelatedItemList}
							type="horizon"
							bottom={
								[
									{
										title: {
											className: `line-clamp-3`,
											prependIconName: `basis_type`,
											titleName: `gd_nm`
										}
									},
									{
										price: {
											finalPriceName: `final_price`,
											sellPriceName: `sell_price`
										}
									},
									{
										star: {
											starName: `satisfaction_percent`
										}
									}
								]
							}
							top={
								{
									height: `h-16`,
									image: {
										altName: `gd_nm`,
										imageName: `img_contents_no`,
										imageType: `gd-image`
									},
									width: `w-16`
								}
							}
						/>

						<div
							className={
								classNameFunc(`
									flex h-7 items-center justify-between border-t border-solid
									border-dg-500 px-3 dark:border-dgdark-500
								`)
							}
						>
							<Link
								href={`${process.env.legacyPcUrl}/s/${searchRelatedKeyword.toUpperCase()}?keyword=${searchRelatedKeyword}&keyword_etc_act=keyword_click`}
							>
								{t(`검색결과 더보기`)}

								<strong
									className="ml-1 text-[#3782ea]"
								>
									{searchRelatedKeyword}
								</strong>
							</Link>
						</div>
					</div>
				)
			}

			<div
				className={
					classNameFunc(`
						flex h-7 items-center justify-between border-t border-solid
						border-dg-500 dark:border-dgdark-500
					`)
				}
			>
				<Button
					className="btn-searchlayerclose flex-none px-3 font-bold"
					onClick={
						(event) => {
							searchLayerToggleFunc(event.nativeEvent, false)
						}
					}
				>
					{t(`닫다`)}
				</Button>

				{
					searchRelatedKeywordList?.length === 0 && (
						<Button
							className="flex-none px-3 text-right font-bold"
							onClick={searchLayerResetCookieFunc}
						>
							{t(`검색 기록 삭제`)}
						</Button>
					)
				}
			</div>
		</div>
	)
}

export default HeaderSearchLayerView
