'use client'

import {
	classNameFunc
} from '@dg/common/lib/common'
import {
	PropsWithChildren
} from 'react'

interface SpinnerProps {
	className?: string;
	fixed?: boolean;
	hidden?: boolean;
	id?: string;
}

const Spinner = ({
	children, id, className = ``, fixed = false, hidden = true
}: PropsWithChildren<SpinnerProps>) => {
	const customClassName =
		classNameFunc(`
			left-0 top-0 size-full min-h-7 before:absolute before:inset-1/2 before:block before:size-12.5
			before:origin-top-left before:animate-loading before:bg-loading before:bg-contain before:bg-center
			before:bg-no-repeat
			${
				fixed === false ?
					`relative z-0` :
					`fixed z-200 bg-dg-black/60 dark:bg-dgdark-black/60`
			}
			${hidden === true ? `hidden` : ``}
			${className}
		`)

	return (
		<div
			className={customClassName}
			id={id}
		>
			{
				children !== undefined ?
					children :
					(
						<span
							className="sr-only"
						>
							Loading
						</span>
					)
			}
		</div>
	)
}

export default Spinner
