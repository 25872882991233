'use client'

import Button from '@dg/common/components/Button'
import Input from '@dg/common/components/Input'
import Link from '@dg/common/components/Link'
import {
	classNameFunc
} from '@dg/common/lib/common'
import IcInputRemove from '@dg/common/public/icons/mobile/common/ic-input-remove.svg'
import IcSearchLink from '@dg/common/public/icons/mobile/common/ic-search-link.svg'
import pathsData from '@dg/common/public/json/mobile/paths'
import {
	ChevronLeftIcon, XMarkIcon
} from '@heroicons/react/24/outline'
import {
	FormEvent
} from 'react'
import {
	useTranslation
} from 'react-i18next'

interface HeaderRightSearchLayerSearchViewProps {
	inputChgFunc: (event: FormEvent) => void;
	keywordChkFunc: (value: string) => string;
	open: boolean;
	recentKeywordArray: string[];
	relatedKeywordArray: string[];
	removeItemFunc: (item: string) => void;
	submitFunc: (event: FormEvent) => void;
}

const HeaderRightSearchLayerSearchView = ({
	recentKeywordArray,
	relatedKeywordArray,
	open,
	inputChgFunc,
	keywordChkFunc,
	removeItemFunc,
	submitFunc
}: HeaderRightSearchLayerSearchViewProps) => {
	const {
		t
	} = useTranslation()

	const liClassName =
		classNameFunc(`
			relative flex h-12.5 px-4 [&+li]:border-t [&+li]:border-solid
			[&+li]:border-dg-400 dark:[&+li]:border-dgdark-400
		`)

	const searchLinkClassName =
		classNameFunc(`
			absolute right-2.5 top-1/2 size-6 -translate-y-1/2 text-dg-600
			dark:text-dgdark-600
		`)

	return (
		<>
			<form
				className="sticky left-0 top-0 z-1 flex bg-dg-white px-4 py-2.5 dark:bg-dgdark-white"
				onSubmit={submitFunc}
			>
				<Button
					className="btn-close -ml-4 flex h-10 w-15 items-center justify-center"
				>
					<ChevronLeftIcon
						className="size-7"
					/>

					<span
						className="sr-only"
					>
						Close Button
					</span>
				</Button>

				<span
					className="relative flex-auto overflow-hidden"
				>
					<Input
						height="h-10"
						id="searchlayer-keyword"
						onInput={inputChgFunc}
						placeholder={t(`무엇을 찾으시나요?`)}
						type="search"
						width="w-full"
						className={
							classNameFunc(`
								focus:border-dg-red dark:focus:border-dgdark-red
								${open === true ? `[&+button]:focus:!right-3` : ``}
							`)
						}
						px
						rounded
					/>

					<Button
						transition={false}
						type="reset"
						className={
							classNameFunc(`
								absolute -right-full top-1/2 -translate-y-1/2 text-dg-550 transition-all
								dark:text-dgdark-550
							`)
						}
					>
						<IcInputRemove
							className="size-5"
						/>
					</Button>
				</span>

				<Button
					type="submit"
					hidden
				>
					Search
				</Button>
			</form>

			{
				open === true && (
					<ul
						className={
							classNameFunc(`
								fixed left-0 top-15 z-1 !mt-0 h-[calc(100%-60px)] w-full overflow-y-auto
								bg-dg-white dark:bg-dgdark-white
							`)
						}
					>
						{
							recentKeywordArray
								.filter((filterItem) => {
									const text = filterItem.split(`:`)[0]
									const matchKeyword = keywordChkFunc(text).match(/text-dg-red/gim)

									return text !== `` && matchKeyword !== null
								})
								.map((item, index) => {
									const text = item.split(`:`)[0]
									const textUpperCase = text.replace(/\+/gim, `-`).toUpperCase()
									const textLowerCase = text.replace(/\+/gim, `%20`).toLowerCase()

									return (
										<li
											key={Object.keys(recentKeywordArray)[index]}
											className={liClassName}
										>
											<Button
												className={
													classNameFunc(`
														-ml-4 flex w-9 items-center justify-center text-dg-600
														dark:text-dgdark-600
													`)
												}
												onClick={
													() => {
														removeItemFunc(item)
													}
												}
											>
												<XMarkIcon
													className="size-4"
												/>
											</Button>

											<Link
												className="relative flex flex-auto items-center"
												href={`${pathsData.searchList}/${textUpperCase}?keyword=${textLowerCase}&kwclick=H`}
												dangerouslySetInnerHTML={
													{
														__html: keywordChkFunc(text)
													}
												}
											/>

											<IcSearchLink
												className={searchLinkClassName}
											/>
										</li>
									)
								})
						}

						{
							relatedKeywordArray
								.map((item, index) => {
									const text = item.split(`:`)[0]
									const textUpperCase = text.replace(/\+/gim, `-`).toUpperCase()
									const textLowerCase = text.replace(/\+/gim, `%20`).toLowerCase()

									return (
										<li
											key={Object.keys(relatedKeywordArray)[index]}
											className={liClassName}
										>
											<Link
												className="relative flex flex-auto items-center"
												href={`${pathsData.searchList}/${textUpperCase}?keyword=${textLowerCase}&kwclick=A`}
												dangerouslySetInnerHTML={
													{
														__html: keywordChkFunc(text)
													}
												}
											/>

											<IcSearchLink
												className={searchLinkClassName}
											/>
										</li>
									)
								})
						}
					</ul>
				)
			}
		</>
	)
}

export default HeaderRightSearchLayerSearchView
