'use client'

import {
	classNameFunc
} from "@dg/common/lib/common"

interface InputLabelViewProps {
	className?: string;
	color?: string;

	/** font-weight에 관한 값을 나타냅니다. */
	fontWeight?: `bold` | `light` | `medium` | `normal` | `semibold` | `thin`;

	/** name은 label과 input-id에 일치하는 값을 넣어 줍니다. */
	name?: string;
	title: string;
	titleInfo?: string;

	/** width에 해당하는 수치를 넣어줍니다. */
	w?: number;
}

const InputLabelView = ({
	className,
	color,
	fontWeight = `normal`,
	name,
	title,
	titleInfo,
	w
}: InputLabelViewProps) => {
	const inputLabelCss =
		`
			${w ? `w-${w}` : ``} flex-none ${`font-${fontWeight}`}
			${color ? `text-${color}` : ``}
		`

	return (
		<label
			className={classNameFunc(`${className ?? ``} ${inputLabelCss}`)}
			htmlFor={name}
		>
			{title}

			{
				titleInfo && (
					<span
						className="font-normal text-[#f33]"
					>
						{titleInfo}
					</span>
				)
			}

		</label>
	)
}

export default InputLabelView
