'use client'

import HeaderRightSearchLayerView from '@dg/common/components/Mobile/Header/HeaderView/HeaderRightSearchLayer/HeaderRightSearchLayerView/HeaderRightSearchLayerView'
import useGetApi from '@dg/common/lib/hooks/useGetApi'
import useLayerpopup from '@dg/common/lib/hooks/useLayerpopup'
import {
	searchMobilePopupApi, SearchMobilePopupApiProps
} from '@dg/common/lib/mobile/api'
import {
	UseQueryResult
} from '@tanstack/react-query'
import {
	useEffect, useState
} from 'react'

const HeaderRightSearchLayer = () => {
	const layerpopup = useLayerpopup()

	const [
		pageData,
		setPageData
	] = useState({
		loaded: false
	})

	const searchMobilePopupDataApi = useGetApi({
		api: () => searchMobilePopupApi(10),
		name: `searchMobilePopupDataApi`
	}) as UseQueryResult<SearchMobilePopupApiProps>

	useEffect(() => {
		if (pageData.loaded === false) {
			if (layerpopup.get()[`layerpopup-searchlayer`]?.open === true) {
				setPageData({
					loaded: true
				})
			}
		}
	}, [
		layerpopup,
		pageData.loaded
	])

	const props = {
		data: searchMobilePopupDataApi.data?.result,
		loaded: pageData.loaded
	}

	return (
		<HeaderRightSearchLayerView
			{...props}
		/>
	)
}

export default HeaderRightSearchLayer
