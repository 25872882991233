'use client'

import {
	gtagId, gtmId, appierId
} from '@dg/common/lib/googleAnalyticsBuilder'
import Script from 'next/script'

const LazyScript = () => (
	<>
		{/* Google Tag Manager */}
		<Script
			id="gtm-script"
			strategy="lazyOnload"
			dangerouslySetInnerHTML={
				{
					__html:
						`
							(function(w,d,s,l,i){
							w[l]=w[l]||[];
							w[l].push({'gtm.start': new Date().getTime(), event:'gtm.js'});
							var f=d.getElementsByTagName(s)[0],
								j=d.createElement(s),
								dl=l!='dataLayer'?'&l='+l:'';
							j.async=true;
							j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
							f.parentNode.insertBefore(j,f);
							})(window,document,'script','dataLayer','${gtmId}');
						`
				}
			}
		/>

		{/* Google Analytics */}
		<Script
			src={`https://www.googletagmanager.com/gtag/js?id=${gtagId}`}
			strategy="lazyOnload"
		/>

		<Script
			id="gtag-init"
			strategy="lazyOnload"
			dangerouslySetInnerHTML={
				{
					__html:
						`
							window.dataLayer = window.dataLayer || [];
							function gtag(){dataLayer.push(arguments);}
							gtag('js', new Date());
							gtag('config', '${gtagId}');
						`
				}
			}
		/>

		{/* Appier */}
		<Script
			id="appier"
			strategy="lazyOnload"
			dangerouslySetInnerHTML={
				{
					__html:
						`
							!function (q, g, r, a, p, h, js) {
							if (q.qg) return;
							js = q.qg = function () {
								js.callmethod ? js.callmethod.call(js, arguments) : js.queue.push(arguments);
							};
							js.queue = [];
							p = g.createElement(r); p.async = !0; p.src = a; h = g.getElementsByTagName(r)[0];
							h.parentNode.insertBefore(p, h);
							}(window, document, 'script', 'https://cdn.qgr.ph/qgraph.${appierId}.js');
						`
				}
			}
		/>

		<noscript>
			{/* // eslint-disable-next-line jsx-a11y/iframe-has-title */}
			<iframe
				height="0"
				src={`https://www.googletagmanager.com/ns.html?id=${gtmId}`}
				title="GTM noscript"
				width="0"
				style={
					{
						display: `none`,
						visibility: `hidden`
					}
				}
			/>
		</noscript>
	</>
)

export default LazyScript
