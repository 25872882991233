'use client'

import Spinner from '@dg/common/components/Spinner'
import {
	classNameFunc
} from '@dg/common/lib/common'

interface AsyncBoundaryErrorViewProps {
	className?: string;
}

const AsyncBoundaryErrorView = ({
	className = ``
}: AsyncBoundaryErrorViewProps) => (
	<Spinner
		className={classNameFunc(`rotate-180 ${className}`)}
	>
		<span
			className="sr-only"
		>
			Error
		</span>
	</Spinner>
)

export default AsyncBoundaryErrorView
