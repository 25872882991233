'use client'

import useToast from '@dg/common/lib/hooks/useToast'
import SnsAreaView from 'member/components/Common/SnsArea/SnsAreaView/SnsAreaView'
import {
	usePathname
} from 'next/navigation'
import {
	useCallback, useEffect, useState
} from 'react'

type snsType = `Facebook` | `Google` | `Line` | `Apple` | ``

interface SnsAreaProps {
	className?: string;
	failureUri?: string;
	height?: number;
	redirectUri?: string;
	successUri?: string;
	type?: string;
	width?: number;
}

const SnsArea = ({
	className = ``,
	height = 30,
	width = 30,
	redirectUri = ``,
	successUri = ``,
	failureUri = ``,
	type = `LOGIN`
}: SnsAreaProps) => {
	const toast = useToast()
	const pathname = usePathname()

	const [
		pageData,
		setPageData
	] = useState({
		beforeLogin: `` as snsType,
		tooltipOpen: false
	})

	const snsloginToastChk = pathname?.match(/login/) !== null || pathname?.match(/join/) !== null

	const tooltipBtnFunc = useCallback(() => {
		setPageData({
			...pageData,
			tooltipOpen: false
		})
	}, [
		pageData
	])

	useEffect(() => {
		const dgCookieName = `beforeLoginChannel=`

		if (
			snsloginToastChk === true &&
			document.cookie.match(dgCookieName) !== null &&
			pageData.beforeLogin === ``
		) {
			if (toast.get()[`toast-snslogin`] !== undefined && toast.get()[`toast-snslogin`].open === false) {
				toast.open({
					id: `toast-snslogin`
				})
			}

			setPageData({
				beforeLogin: document.cookie.split(dgCookieName)[1].split(`;`)[0] as snsType,
				tooltipOpen: true
			})
		}
	}, [
		pageData.beforeLogin,
		pathname,
		snsloginToastChk,
		toast
	])

	const props = {
		beforeLogin: pageData.beforeLogin,
		className,
		failureUri,
		height,
		redirectUri,
		snsloginToastChk,
		successUri,
		tooltipBtnFunc,
		tooltipOpen: pageData.tooltipOpen,
		type,
		width
	}

	return (
		<SnsAreaView
			{...props}
		/>
	)
}

export default SnsArea
