'use client'

import {
	classNameFunc
} from '@dg/common/lib/common'
import React from 'react'

interface StackViewProps {
	children: (React.ReactElement | string)[] | React.ReactElement;
	className?: string;

	/** 방향을 나태냅니다. */
	direction?: string;

	/** flex로 공간의 여백을 나태냅니다. default는 0이 들어갑니다. */
	gap?: number;
	noFlex?: boolean;
	width?: number | string;
}

const StackView = ({
	direction,
	className,
	children,
	gap,
	noFlex,
	width
}: StackViewProps) => {
	const stackCss =
		`
			${noFlex ? `flex-none` : `flex`} ${width ? `w-${width}` : `w-full`}
			${direction ? `flex-${direction}` : ``} ${gap ? `gap-${gap}` : ``}
		`

	return (
		<div
			className={classNameFunc(`${className ?? ``} ${stackCss}`)}
		>
			{React.Children.map(children, (child) => child)}
		</div>
	)
}

export default StackView
