'use client'

import Button from '@dg/common/components/Button'
import Link from '@dg/common/components/Link'
import HeaderSideBarLiView from '@dg/common/components/Pc/Header/HeaderView/HeaderSideBar/HeaderSideBarView/HeaderSideBarLiView'
import useImageValidate from '@dg/common/lib/hooks/useImageValidate'
import pathsData from '@dg/common/public/json/pc/paths'
import {
	ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon
} from '@heroicons/react/24/outline'
import Image from 'next/image'
import {
	useTranslation
} from 'react-i18next'

interface HeaderSideBarViewProps {
	goTopFunc: () => void;
	moveDownFunc: () => void;
	title: string;
}

const HeaderSideBarView = ({
	title, goTopFunc, moveDownFunc
}: HeaderSideBarViewProps) => {
	const {
		t
	} = useTranslation()

	const imageValidate = useImageValidate()

	return (
		<article
			className="absolute left-1/2 top-full ml-134.5 w-19 -translate-x-1/2 space-y-2.5"
		>
			<HeaderSideBarLiView
				height={53}
				link={pathsData.qchance}
				src="nav-roulette"
				width={62}
				bgBlue
				rounded
			>
				{t(`Q 라운지`)}
			</HeaderSideBarLiView>

			<ul
				className="flex flex-col rounded border border-solid border-[#d1d0d0] bg-[#fafafa]"
			>
				<li
					className="flex h-8"
				>
					<HeaderSideBarLiView
						height={15}
						link="window_share_430_374"
						srOnly="Share"
						src="nav-share"
						title={title}
						width={16}
						flex
					/>

					<HeaderSideBarLiView
						height={15}
						link={pathsData.help}
						srOnly="Help"
						src="nav-help"
						target="_blank"
						width={13}
						bl
						flex
					/>
				</li>

				<li>
					<HeaderSideBarLiView
						height={15}
						src="nav-cart"
						width={22}
						bt
					>
						{t(`장바구니`)}
					</HeaderSideBarLiView>

					<HeaderSideBarLiView
						height={11}
						link={`window_${pathsData.popupMsgBox}_815_786`}
						src="nav-qpost"
						title={title}
						width={16}
						bt
					>
						Qpost
					</HeaderSideBarLiView>

					<HeaderSideBarLiView
						height={16}
						link={`window_${pathsData.popupMsgBox}_815_786`}
						src="nav-recent"
						title={title}
						width={12}
						bt
					>
						{t(`최근 본 상품`)}
					</HeaderSideBarLiView>
				</li>

				<li
					className="flex flex-col border-t border-dotted border-[#d1d0d0] p-1"
				>
					<ul>
						<li>
							<Link
								className="flex h-17 items-center"
								href={pathsData.home ?? ``}
							>
								<Image
									alt="Item"
									className="object-cover"
									height={67}
									onError={imageValidate.onError}
									src="/images/pc/common/test.png"
									width={67}
								/>
							</Link>
						</li>
					</ul>

					<p
						className="flex items-center text-xs"
					>
						<Button
							className="flex-none"
						>
							<ChevronLeftIcon
								className="w-3"
							/>

							<span
								className="sr-only"
							>
								Prev Button
							</span>
						</Button>

						<span
							className="flex-auto text-center"
						>
							<strong>
								1
							</strong>
							/1
						</span>

						<Button
							className="flex-none"
						>
							<ChevronRightIcon
								className="w-3"
							/>

							<span
								className="sr-only"
							>
								Next Button
							</span>
						</Button>
					</p>
				</li>

				<li
					className="border-t border-dotted border-[#d1d0d0]"
				>
					<Button
						className="w-full px-0 py-1 text-center text-3xs"
					>
						{t(`사이즈 가이드`)}
					</Button>
				</li>
			</ul>

			<div
				className="flex h-6 rounded border border-solid border-[#cdcdcd] bg-[#f2f2f2] text-center text-xs
					font-bold text-[#939393]"
			>
				<Button
					className="h-full flex-auto"
					onClick={goTopFunc}
				>
					TOP
				</Button>

				<Button
					className="h-full flex-none border-l border-solid border-[#cdcdcd] px-1"
					onClick={moveDownFunc}
				>
					<ChevronDownIcon
						className="w-5 stroke-[3px]"
					/>

					<span
						className="sr-only"
					>
						Move Down
					</span>
				</Button>
			</div>
		</article>
	)
}

export default HeaderSideBarView
