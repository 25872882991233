'use client'

import Button from "@dg/common/components/Button"
import {
	classNameFunc
} from "@dg/common/lib/common"
import {
	ChevronLeftIcon,
	ChevronRightIcon
} from '@heroicons/react/24/outline'

interface LoginTypeToggleViewProps {
	onToggleChange: (active: boolean) => void;
	text: string[];
	toggleData: string;
}

const LoginTypeToggleView = ({
	onToggleChange,
	text,
	toggleData
}: LoginTypeToggleViewProps) => {
	const handleToggleChange = () => {
		if (onToggleChange !== undefined) {
			onToggleChange(toggleData !== `id`)
		}
	}

	const iconClassName = classNameFunc(`absolute top-1/2 h-3 -translate-y-1/2`)

	return (
		<Button
			className="absolute mt-1 flex h-4 w-27.5 items-center justify-center rounded-xl bg-[#f3964d] text-dg-white"
			onClick={handleToggleChange}
		>
			{
				toggleData !== `id` && (
					<ChevronLeftIcon
						className={classNameFunc(`left-1 ${iconClassName}`)}
					/>
				)
			}

			<span
				className="h-full text-xs"
			>
				{text[toggleData === `id` ? 1 : 0]}
			</span>

			{
				toggleData === `id` && (
					<ChevronRightIcon
						className={classNameFunc(`right-1 ${iconClassName}`)}
					/>
				)
			}
		</Button>
	)
}

export default LoginTypeToggleView
