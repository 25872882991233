'use client'

import FooterTabView from '@dg/common/components/Mobile/Footer/FooterView/FooterTab/FooterTabView/FooterTabView'
import {
	usePathname
} from 'next/navigation'

const FooterTab = () => {
	const pathname = usePathname()

	const props = {
		tabPathChk: pathname ?? ``

		/* Member PageRouter 지원 종료 시 변경
			tabPathChk: pathname
		*/
	}

	return (
		<FooterTabView
			{...props}
		/>
	)
}

export default FooterTab
