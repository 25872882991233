'use client'

import Toast from '@dg/common/components/Toast'
import {
	classNameFunc
} from '@dg/common/lib/common'
import SnsAreaLiView from 'member/components/Common/SnsArea/SnsAreaView/SnsAreaLiView'
import {
	useTranslation
} from 'react-i18next'

type snsType = `Facebook` | `Google` | `Line` | `Apple` | ``

interface SnsAreaViewProps {
	beforeLogin: snsType;
	className: string;
	failureUri: string;
	height: number;
	redirectUri: string;
	snsloginToastChk: boolean;
	successUri: string;
	tooltipBtnFunc: () => void;
	tooltipOpen: boolean;
	type: string;
	width: number;
}

const SnsAreaView = ({
	className,
	height,
	width,
	successUri,
	failureUri,
	type,
	redirectUri,
	beforeLogin,
	tooltipOpen,
	snsloginToastChk,
	tooltipBtnFunc
}: SnsAreaViewProps) => {
	const {
		t
	} = useTranslation()

	return (
		<>
			<p
				className={
					classNameFunc(`
						relative z-0 my-8 flex h-5 justify-center text-dg-800 after:absolute
						after:left-0 after:top-1/2 after:z-0 after:h-px after:w-full after:-translate-y-1/2
						after:bg-dg-800 dark:text-dgdark-800 dark:after:bg-dgdark-800
					`)
				}
			>
				<span
					className="relative z-1 inline-flex bg-dg-white px-4 text-sm dark:bg-dgdark-white"
				>
					{t(`또는`)}
				</span>
			</p>

			<ul
				className={
					classNameFunc(`
						flex w-full flex-col space-y-4 text-center
						${className}
					`)
				}
			>
				<SnsAreaLiView
					beforeLogin={beforeLogin}
					failureUri={failureUri}
					height={height}
					name="Facebook"
					redirectUri={redirectUri}
					snsloginToastChk={snsloginToastChk}
					successUri={successUri}
					tooltipBtnFunc={tooltipBtnFunc}
					tooltipOpen={tooltipOpen}
					type={type}
					width={width}
				/>

				<SnsAreaLiView
					beforeLogin={beforeLogin}
					failureUri={failureUri}
					height={height}
					name="Google"
					redirectUri={redirectUri}
					snsloginToastChk={snsloginToastChk}
					successUri={successUri}
					tooltipBtnFunc={tooltipBtnFunc}
					tooltipOpen={tooltipOpen}
					type={type}
					width={width}
				/>

				<SnsAreaLiView
					beforeLogin={beforeLogin}
					failureUri={failureUri}
					height={height}
					name="Line"
					redirectUri={redirectUri}
					snsloginToastChk={snsloginToastChk}
					successUri={successUri}
					tooltipBtnFunc={tooltipBtnFunc}
					tooltipOpen={tooltipOpen}
					type={type}
					width={width}
				/>

				<SnsAreaLiView
					beforeLogin={beforeLogin}
					failureUri={failureUri}
					height={height}
					name="Apple"
					redirectUri={redirectUri}
					snsloginToastChk={snsloginToastChk}
					successUri={successUri}
					tooltipBtnFunc={tooltipBtnFunc}
					tooltipOpen={tooltipOpen}
					type={type}
					width={width}
				/>
			</ul>

			<Toast
				id="toast-snslogin"
			>
				{t(`지난 번에는 {text} 로 로그인했습니다.`).replace(/{text}/gim, beforeLogin)}
			</Toast>
		</>
	)
}

export default SnsAreaView
