'use client'

import Button from '@dg/common/components/Button'
import Input from '@dg/common/components/Input'
import HeaderSearchLayerView from '@dg/common/components/Pc/Header/HeaderView/HeaderSearch/HeaderSearchView/HeaderSearchLayerView'
import {
	classNameFunc
} from '@dg/common/lib/common'
import {
	SearchPopularApiProps, SearchRelatedGoodsApiProps
} from '@dg/common/lib/pc/api'
import React, {
	FocusEvent, FormEvent
} from 'react'
import {
	useTranslation
} from 'react-i18next'

interface HeaderSearchViewProps {
	searchCookieKeywordList: SearchPopularApiProps[`result`];
	searchFunc: (event: FormEvent) => void;
	searchLayerChk: boolean;
	searchLayerResetCookieFunc: () => void;
	searchLayerToggleFunc: (event: MouseEvent, value: boolean) => void;
	searchRelatedItemApiFunc: (event: FocusEvent | React.MouseEvent, keyword?: string) => void;
	searchRelatedItemList: SearchRelatedGoodsApiProps[`result`];
	searchRelatedKeyword: string;
	searchRelatedKeywordList: SearchPopularApiProps[`result`];
	searchRelatedLayerChk: boolean;
}

const HeaderSearchView = ({
	searchLayerChk,
	searchRelatedLayerChk,
	searchCookieKeywordList,
	searchRelatedKeywordList,
	searchRelatedKeyword,
	searchRelatedItemList,
	searchRelatedItemApiFunc,
	searchLayerToggleFunc,
	searchLayerResetCookieFunc,
	searchFunc
}: HeaderSearchViewProps) => {
	const {
		t
	} = useTranslation()

	return (
		<form
			className="absolute left-1/2 top-1/2 mt-1 -translate-x-1/2 -translate-y-1/2"
			onSubmit={searchFunc}
		>
			<label
				className="flex h-10 border-4 border-solid border-[#527fef]"
				htmlFor="header-search"
			>
				<Input
					className="text-sm text-[#3782ea]"
					height="h-full"
					id="header-search"
					onChange={searchFunc}
					type="search"
					width="w-80"
					onClick={
						(event) => {
							if (searchLayerToggleFunc !== undefined) {
								searchLayerToggleFunc(event.nativeEvent, true)
							}
						}
					}
					px
				/>

				<Button
					type="submit"
					className={
						classNameFunc(`
							h-full w-17.5 bg-[#527fef] text-center text-sm text-dg-white dark:text-dgdark-white
						`)
					}
				>
					{t(`검색`)}
				</Button>
			</label>

			<HeaderSearchLayerView
				searchCookieKeywordList={searchCookieKeywordList}
				searchLayerChk={searchLayerChk}
				searchLayerResetCookieFunc={searchLayerResetCookieFunc}
				searchLayerToggleFunc={searchLayerToggleFunc}
				searchRelatedItemApiFunc={searchRelatedItemApiFunc}
				searchRelatedItemList={searchRelatedItemList}
				searchRelatedKeyword={searchRelatedKeyword}
				searchRelatedKeywordList={searchRelatedKeywordList}
				searchRelatedLayerChk={searchRelatedLayerChk}
			/>
		</form>
	)
}

export default HeaderSearchView
