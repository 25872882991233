'use client'

import Button from '@dg/common/components/Button'
import Link from '@dg/common/components/Link'
import HeaderRightAlarm from '@dg/common/components/Mobile/Header/HeaderView/HeaderRightAlarm'
import useLayerpopup from '@dg/common/lib/hooks/useLayerpopup'
import IcAlarm from '@dg/common/public/icons/mobile/common/ic-alarm.svg'
import IcCart from '@dg/common/public/icons/mobile/common/ic-cart.svg'
import IcSearch from '@dg/common/public/icons/mobile/common/ic-search.svg'
import pathsData from '@dg/common/public/json/mobile/paths'

const HeaderRightView = () => {
	const layerpopup = useLayerpopup()

	return (
		<ul
			className="flex flex-none space-x-2.5"
		>
			<li>
				<Link
					className="relative block w-7 rounded-full p-0.25"
					href={pathsData.qpost}
				>
					<IcAlarm />

					<span
						className="sr-only"
					>
						Alarm Button
					</span>

					<HeaderRightAlarm />
				</Link>
			</li>

			<li>
				<Button
					className="w-7 rounded-full p-0.25"
					onClick={
						() => {
							layerpopup.open({
								id: `layerpopup-searchlayer`
							})
						}
					}
				>
					<IcSearch />

					<span
						className="sr-only"
					>
						Search Button
					</span>
				</Button>
			</li>

			<li>
				<Link
					className="relative block w-7 rounded-full p-0.25"
					href={pathsData.cart}
				>
					<IcCart />

					<span
						className="sr-only"
					>
						Cart Button
					</span>

					<HeaderRightAlarm
						type="cart"
					/>
				</Link>
			</li>
		</ul>
	)
}

export default HeaderRightView
