'use client'

import {
	FormEvent, useCallback, useMemo
} from 'react'
import {
	useTranslation
} from 'react-i18next'

const useIdValidate = () => {
	const {
		t
	} = useTranslation()

	const data = useMemo(() => ({
		text: ``,
		validValue: ``,
		value: ``
	}), [])

	const chkFunc = useCallback((value: string) => {
		let text = ``

		if ((/^[A-z0-9\\.\\*\\_\\-]*$/).test(value) === false) {
			text = t(`특수 기호는 (. * _ -)만 사용할 수 있습니다.`)
		} else if (value.length > 0 && (value.length < 5 || value.length > 20)) {
			text = `false`
		}

		return text
	}, [
		t
	])

	const onChange = useCallback((event: FormEvent) => {
		const obj = event.target as HTMLInputElement
		const value = obj.value
		const validValue = Array.from(obj.value).slice(0, 20).join(``)

		data.text = chkFunc(validValue || ``)
		data.validValue = validValue
		data.value = value
	}, [
		chkFunc,
		data
	])

	const validate = useCallback((value: string) => {
		data.text = chkFunc(value || ``)

		return data.text
	}, [
		chkFunc,
		data
	])

	return {
		onChange,
		result: data,
		validate
	}
}

export default useIdValidate
