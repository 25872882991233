'use client'

import Button from "@dg/common/components/Button"
import Layerpopup from "@dg/common/components/Layerpopup"
import {
	classNameFunc
} from "@dg/common/lib/common"
import {
	InformationCircleIcon
} from '@heroicons/react/24/outline'
import BirthDayView from "member/components/Common/Find/BirthDayView"
import React from 'react'

interface LoginAdultPopupViewProps {
	adultPopupTitle?: string;
	onChangeAdultData: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onClickSubmitAdultPopup: () => void;
}

const LoginAdultPopupView = ({
	adultPopupTitle,
	onChangeAdultData,
	onClickSubmitAdultPopup
}: LoginAdultPopupViewProps) => {
	const divClassName = classNameFunc(`flex items-center text-sm text-[#333]`)
	const h3ClassName = classNameFunc(`mr-1 flex-none font-bold`)
	const liClassName = classNameFunc(`mb-1 flex leading-4`)

	return (
		<Layerpopup
			id="layerpopup_adult"
			customFooter={
				(
					<Button
						btnType="pcBlue"
						className="rounded-none text-dg-100"
						onClick={onClickSubmitAdultPopup}
					>
						確認
					</Button>
				)
			}
			closeBtn
		>
			<h2
				className="mb-5 text-base font-bold text-[#000] underline"
			>
				生年月日認証
			</h2>

			<p
				className="text-2xs leading-[12.31px] text-[#333]"
			>
				※ Qoo10は満18歳未満のアダルト商品やコンテンツへのアクセスを制限しています。
				<br />
				生年月日の入力を通じて年齢確認後にアクセスが可能です。
			</p>

			<div
				className={classNameFunc(`mt-4 ${divClassName}`)}
			>
				<h3
					className={h3ClassName}
				>
					商品名 :
				</h3>

				<p>
					{adultPopupTitle}
				</p>
			</div>

			<div
				className={classNameFunc(`mt-1 ${divClassName}`)}
			>
				<h3
					className={h3ClassName}
				>
					生年月日 :
				</h3>

				<BirthDayView
					onChangeBirthDay={onChangeAdultData}
				/>
			</div>

			<ul
				className="mt-2.5 text-xs text-[#d60000]"
			>
				<li
					className={liClassName}
				>
					<InformationCircleIcon
						className="mr-2.5"
						height={15}
						width={15}
					/>
					正しい生年月日を入力してください。
				</li>

				<li
					className={liClassName}
				>
					<InformationCircleIcon
						className="mr-2.5"
						height={15}
						width={15}
					/>
					この認証は年齢確認にのみ利用され、保存されません。
				</li>
			</ul>

		</Layerpopup>
	)
}

export default LoginAdultPopupView
