'use client'

import Button from '@dg/common/components/Button'
import Link from '@dg/common/components/Link'
import {
	classNameFunc
} from '@dg/common/lib/common'
import pathsData from '@dg/common/public/json/mobile/paths'
import {
	ChevronDownIcon, XMarkIcon
} from '@heroicons/react/24/outline'
import {
	useTranslation
} from 'react-i18next'
import type {
	RefObject
} from 'react'

interface HeaderRightSearchLayerRecentKeywordViewProps {
	edit: boolean;
	editFunc: () => void;
	headerSearchLayerRecentKeywordRef: RefObject<HTMLElement | null>;
	open: boolean;
	openBtnVisible: boolean;
	openFunc: () => void;
	recentKeywordArray: string[];
	removeAllFunc: () => void;
	removeItemFunc: (item: string) => void;
}

const HeaderRightSearchLayerRecentKeywordView = ({
	headerSearchLayerRecentKeywordRef,
	recentKeywordArray,
	edit,
	open,
	openBtnVisible,
	editFunc,
	openFunc,
	removeAllFunc,
	removeItemFunc
}: HeaderRightSearchLayerRecentKeywordViewProps) => {
	const {
		t
	} = useTranslation()

	return (
		<div
			className="flex flex-col px-4 py-5"
		>
			<h2
				className="flex items-center justify-between text-base"
			>
				<strong>
					{t(`검색 이력`)}
				</strong>

				{
					recentKeywordArray.length > 0 && (
						<span
							className="space-x-4"
						>
							{
								edit === true && (
									<Button
										className="text-xs font-normal text-dg-800"
										onClick={removeAllFunc}
									>
										{t(`전체삭제`)}
									</Button>
								)
							}

							<Button
								onClick={editFunc}
								className={
									classNameFunc(`
										text-xs font-normal
										${
											edit === false ?
												`text-dg-800 dark:text-dgdark-800` :
												`text-dg-blue dark:text-dgdark-blue`
										}
									`)
								}
							>
								{edit === false ? t(`삭제`) : t(`삭제완료`)}
							</Button>
						</span>
					)
				}
			</h2>

			<ul
				ref={headerSearchLayerRecentKeywordRef as RefObject<HTMLUListElement>}
				className={
					classNameFunc(`
						mt-5 overflow-hidden text-xs
						${openBtnVisible === true && open === false ? `max-h-50` : ``}
					`)
				}
			>
				{
					recentKeywordArray.length > 0 ?
						recentKeywordArray
							.filter((filterItem, filterIndex) => filterItem !== `` && filterIndex < 20)
							.map((item, index) => {
								const text = item.split(`:`)[0]
								const textUpperCase = text.replace(/\+/gim, `-`).toUpperCase()
								const textLowerCase = text.replace(/\+/gim, `%20`).toLowerCase()

								return (
									<li
										key={Object.keys(recentKeywordArray)[index]}
										className={
											classNameFunc(`
												mr-2.5 mt-2.5 inline-flex h-10 whitespace-nowrap rounded-3xl border
												border-solid border-dg-400 px-3 dark:border-dgdark-400
											`)
										}
									>
										<Link
											className="relative flex items-center justify-center"
											href={`${pathsData.searchList}/${textUpperCase}?keyword=${textLowerCase}&kwclick=H`}
										>
											{text.replace(/\+/gim, ` `)}
										</Link>

										{
											edit === true && (
												<Button
													className={
														classNameFunc(`
															-mr-3 flex w-9 items-center justify-center text-dg-600
															dark:text-dgdark-600
														`)
													}
													onClick={
														() => {
															removeItemFunc(item)
														}
													}
												>
													<XMarkIcon
														className="size-4"
													/>
												</Button>
											)
										}
									</li>
								)
							}) :
						(
							<li
								className="text-center text-sm text-dg-800 dark:text-dgdark-800"
							>
								{t(`검색 이력이 없습니다.`)}
							</li>
						)
				}
			</ul>

			{
				openBtnVisible === true && open === false && (
					<Button
						className="mt-5 self-end text-xs text-dg-800"
						onClick={openFunc}
					>
						<ChevronDownIcon
							className="size-5 stroke-[2.5]"
						/>
					</Button>
				)
			}
		</div>
	)
}

export default HeaderRightSearchLayerRecentKeywordView
