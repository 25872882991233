'use client'

import {
	addComma
} from '@dg/common/lib/common'
import {
	useTranslation
} from 'react-i18next'

interface ListPriceViewProps {
	item: Record<string, string | number | boolean>;
	price: {
		finalPriceName: string;
		sellPriceName?: string;
	};
}

const ListPriceView = ({
	price, item
}: ListPriceViewProps) => {
	const {
		t
	} = useTranslation()

	const {
		sellPriceName, finalPriceName
	} = price

	const parseSellPriceName = Number(item[sellPriceName ?? ``]) || 0
	const parseFinalPriceName = Number(item[finalPriceName])

	return (
		<p
			className="mt-1 space-x-1"
		>
			{
				parseSellPriceName > parseFinalPriceName && (
					<del
						className="text-2xs text-dg-600 dark:text-dgdark-600"
					>
						{addComma(parseSellPriceName)}

						{t(`엔`)}
					</del>
				)
			}

			<strong
				className="mt-4 text-dg-red dark:text-dgdark-red"
			>
				{addComma(parseFinalPriceName)}

				{t(`엔`)}
			</strong>
		</p>
	)
}

export default ListPriceView
