'use client'

import {
	useCallback, useMemo
} from 'react'
import {
	useTranslation
} from 'react-i18next'

const useCompareValidate = () => {
	const {
		t
	} = useTranslation()

	const data = useMemo(() => ({
		text: ``
	}), [])

	const chkFunc = useCallback((value1: string, value2: string) => {
		let text = ``

		if (value1 !== `` && value2 !== `` && value1 !== value2) {
			text = t(`비밀번호가 일치하지 않습니다. 비밀번호를 다시 입력합니다.`)
		}

		return text
	}, [
		t
	])

	const compare = useCallback((value1: string, value2: string) => {
		data.text = chkFunc(value1 || ``, value2 || ``)
	}, [
		chkFunc,
		data
	])

	const validate = useCallback((value1: string, value2: string) => {
		data.text = chkFunc(value1 || ``, value2 || ``)

		return data.text
	}, [
		chkFunc,
		data
	])

	return {
		compare,
		result: data,
		validate
	}
}

export default useCompareValidate
