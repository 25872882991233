'use client'

import HeaderRightSearchLayerSearchView from '@dg/common/components/Mobile/Header/HeaderView/HeaderRightSearchLayer/HeaderRightSearchLayerView/HeaderRightSearchLayerSearch/HeaderRightSearchLayerSearchView/HeaderRightSearchLayerSearchView'
import {
	searchRelatedApi, SearchRelatedApiProps
} from '@dg/common/lib/api'
import {
	addCookie
} from '@dg/common/lib/common'
import useLayerpopup from '@dg/common/lib/hooks/useLayerpopup'
import useSetApi from '@dg/common/lib/hooks/useSetApi'
import pathsData from '@dg/common/public/json/mobile/paths'
import {
	FormEvent, useCallback, useEffect, useMemo, useState
} from 'react'

interface HeaderRightSearchLayerSearchFormDataProps extends HTMLFormControlsCollection {
	[`searchlayer-keyword`]: HTMLInputElement;
}

const HeaderRightSearchLayerSearch = () => {
	const layerpopup = useLayerpopup()

	const data = useMemo(() => ({
		delayFunc: null as unknown as ReturnType<typeof setTimeout>
	}), [])

	const [
		pageData,
		setPageData
	] = useState({
		inputValue: ``,
		open: false,
		recentKeywordArray: [] as string[],
		relatedKeywordArray: [] as string[]
	})

	const headerSearchRelatedApi = useSetApi({
		api: searchRelatedApi,
		progress: false,
		successCallback: (successData: SearchRelatedApiProps, variables: string) => {
			if (successData.code === 0) {
				setPageData({
					...pageData,
					inputValue: variables,
					open: variables !== ``,
					relatedKeywordArray: successData.result.result_list
				})
			}
		}
	})

	const inputChgFunc = useCallback((event: FormEvent) => {
		const obj = event.target as HTMLInputElement
		const value = obj.value

		setPageData({
			...pageData,
			open: value !== ``,
			relatedKeywordArray: value !== `` ? pageData.relatedKeywordArray : []
		})

		clearTimeout(data.delayFunc)

		if (value !== ``) {
			data.delayFunc = setTimeout(() => {
				headerSearchRelatedApi.set(value)
			}, 300)
		}
	}, [
		data,
		headerSearchRelatedApi,
		pageData
	])

	const keywordChkFunc = useCallback((value: string) => {
		const convValue = value.replace(/\+/gim, ` `)
		const regExpValue = pageData.inputValue.replace(/\\/gim, `\\\\`)
		const regValue = new RegExp(`(${regExpValue})`, `gim`)

		const replacer = (match: string, p1: string) => `<span+class="text-dg-red+dark:text-dgdark-red">${p1}</span>`

		return convValue.replace(regValue, replacer).replace(/ /gim, `&nbsp;`).replace(/\+/gim, ` `)
	}, [
		pageData.inputValue
	])

	const removeItemFunc = useCallback((item: string) => {
		const setData = {
			...pageData
		}

		let cookieData = ``

		setData.recentKeywordArray =
			pageData.recentKeywordArray
				.filter((filterItem) => filterItem !== item)
				.map((mapItem, mapIndex) => {
					cookieData += `${mapIndex > 0 ? `_` : ``}${encodeURIComponent(mapItem)}`

					return mapItem
				})

		addCookie(`recentSearchKeyword=${cookieData}`, 30)

		setPageData({
			...setData
		})
	}, [
		pageData
	])

	const submitFunc = useCallback((event: FormEvent) => {
		if (event.cancelable === true) {
			event.preventDefault()
			event.stopPropagation()
		}

		const obj = event.target as HTMLFormElement
		const objElements = obj.elements as HeaderRightSearchLayerSearchFormDataProps
		const keywordValue = encodeURIComponent(objElements[`searchlayer-keyword`].value)

		window.location.href = `${pathsData.searchListFull}/?keyword=${keywordValue}`
	}, [])

	useEffect(() => {
		const dgCookieName = `recentSearchKeyword=`

		let recentKeywordArray = [] as string[]

		if (document.cookie.match(dgCookieName) !== null) {
			recentKeywordArray = decodeURIComponent(document.cookie.split(dgCookieName)[1].split(`;`)[0]).split(`_`)
		}

		if (pageData.recentKeywordArray.length === 0 && recentKeywordArray.length > 0) {
			setPageData({
				...pageData,
				recentKeywordArray
			})
		}

		if (layerpopup.get()[`layerpopup-searchlayer`] !== undefined && layerpopup.get()[`layerpopup-searchlayer`].open === true) {
			const inputObj =
				document.querySelector(`#layerpopup-searchlayer .layerpopup-content form input`) as HTMLInputElement

			if (inputObj !== null) {
				setTimeout(() => {
					inputObj.focus()
				}, 300)
			}
		}
	}, [
		layerpopup,
		pageData
	])

	const props = {
		inputChgFunc,
		keywordChkFunc,
		open: pageData.open,
		recentKeywordArray: pageData.recentKeywordArray,
		relatedKeywordArray: pageData.relatedKeywordArray,
		removeItemFunc,
		submitFunc
	}

	return (
		<HeaderRightSearchLayerSearchView
			{...props}
		/>
	)
}

export default HeaderRightSearchLayerSearch
