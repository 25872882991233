'use client'

import HeaderLoginMenuLiView from '@dg/common/components/Pc/Header/HeaderView/HeaderLoginMenuLiView'
import pathsData from '@dg/common/public/json/pc/paths'
import {
	useTranslation
} from 'react-i18next'

interface HeaderLoginMenuViewProps {
	loginMenuSelected: string;
	loginSeller: boolean;
}

const HeaderLoginMenuView = ({
	loginMenuSelected, loginSeller
}: HeaderLoginMenuViewProps) => {
	const {
		t
	} = useTranslation()

	return (
		<nav
			className="h-10 w-full flex-none bg-[#517fef]"
		>
			<ul
				className="mx-auto flex w-pc"
			>
				<HeaderLoginMenuLiView
					link={`${pathsData.login}${loginSeller === true ? `?seller=true` : ``}`}
					selected={(loginMenuSelected === `login`)}
				>
					{t(`로그인`)}
				</HeaderLoginMenuLiView>

				<HeaderLoginMenuLiView
					link={`${pathsData.join}${loginSeller === true ? `?seller=true` : ``}`}
					selected={(loginMenuSelected === `join`)}
				>
					{t(`회원 등록(무료)`)}
				</HeaderLoginMenuLiView>

				{
					loginSeller === false && (
						<HeaderLoginMenuLiView
							link={`${pathsData.registerSeller}?isSellerRegProcess=Y`}
							last
						>
							{t(`출점자 등록`)}
						</HeaderLoginMenuLiView>
					)
				}
			</ul>
		</nav>
	)
}

export default HeaderLoginMenuView
