'use client'

import {
	getLegacyServerUrl
} from '@dg/common/lib/qoo10Common'
import Login from 'member/components/Pc/Login'
import {
	getString, isReturnUrlIsRelatedLoginPage
} from 'member/lib/common'
import {
	use
} from 'react'

export interface LoginPageSearchParamsProps {
	ReturnUrl: string;
	confirm_email: string;
	isAdult: string;
	login_id: string;
	new_email_confirm: string;
	nextUrl: string;
	seller: string;
}

interface LoginPageProps {
	searchParams: Promise<LoginPageSearchParamsProps>;
}

const LoginPage = ({
	searchParams
}: LoginPageProps) => {
	const {
		confirm_email: qConfirmEmail,
		isAdult: qIsAdult,
		login_id: qLoginId,
		new_email_confirm: qNewEmailConfirm,
		nextUrl: qNextUrl,
		ReturnUrl: qReturnUrl,
		seller: qSeller
	} = use(searchParams)

	const getUrlWithScriptCheck = (url: string): string => (url && url.toLowerCase().indexOf(`script`) < 0 ? url : ``)

	const confirmEmail = getString(qConfirmEmail)
	const isAdult = qIsAdult === `Y`
	const loginId = getString(qLoginId)
	const isNewEmailConfirm = qNewEmailConfirm === `Y`
	const nextUrl = getString(qNextUrl)
	const seller = getString(qSeller)
	let returnUrl = getString(qReturnUrl)
	let isGuestAfterGoodsLogin = false
	let isNoLoginQuestion = false
	let orderUrl = ``

	returnUrl = getUrlWithScriptCheck(nextUrl || returnUrl)

	if (
		typeof document !== `undefined` &&
		document.referrer &&
		document.referrer.toLowerCase().indexOf(`/pc/join`) < 0
	) {
		returnUrl = returnUrl || document.referrer
	}

	if (isReturnUrlIsRelatedLoginPage(returnUrl)) {
		returnUrl = getLegacyServerUrl()
	}

	returnUrl = returnUrl || getLegacyServerUrl()

	if (returnUrl.toLowerCase().indexOf(`buyorder.aspx`) >= 0) {
		isGuestAfterGoodsLogin = true

		if (returnUrl.indexOf(`?`) >= 0) orderUrl = `${returnUrl}&nonmember=Y`
		else orderUrl = `${returnUrl}?nonmember=Y`
	} else if (
		returnUrl.toLowerCase().indexOf(`orderpayment.aspx`) >= 0 &&
		returnUrl.toLowerCase().indexOf(`nonmember_available=y`) >= 0
	) {
		// non_member_order_link_holder.Visible = true
		isGuestAfterGoodsLogin = true

		if (returnUrl.indexOf(`?`) >= 0) orderUrl = `${returnUrl}&nonmember=Y`
		else orderUrl = `${returnUrl}?nonmember=Y`
	}

	if (returnUrl.indexOf(`NHelpContactUsQuestion.aspx`) >= 0 || returnUrl.indexOf(`NHelpReport.aspx`) >= 0) {
		/* need_more_help_holder.Visible = true; */
		returnUrl = `${returnUrl}&notlogin=Y`

		isNoLoginQuestion = true
	}

	const props = {
		confirmEmail,
		isAdult,
		isGuestAfterGoodsLogin,
		isNewEmailConfirm,
		isNoLoginQuestion,
		loginId,
		orderUrl,
		returnUrl,
		seller
	}

	return (
		<Login
			{...props}
		/>
	)
}

export default LoginPage
