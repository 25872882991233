'use client'

import Button from '@dg/common/components/Button'
import Link from '@dg/common/components/Link'
import {
	addCookie
} from '@dg/common/lib/common'
import pathsData from '@dg/common/public/json/pc/paths'
import {
	PropsWithChildren
} from 'react'

interface FooterInfoLiViewProps {
	link: string;
	target?: string;
}

const FooterInfoLiView = ({
	children, link, target
}: PropsWithChildren<FooterInfoLiViewProps>) => (
	<li
		className="mr-2.5 py-1"
	>
		{
			link?.match(/https/) && pathsData.home !== link ?
				(
					<Link
						className="align-top"
						href={link}
						target={target}
					>
						{children}
					</Link>
				) :
				(
					<Button
						className="align-top"
						onClick={
							() => {
								if (pathsData.home === link) {
									addCookie(`FixPcVersion=Y`, 1)
								}

								setTimeout(() => {
									window.location.href = link
								}, 100)
							}
						}
					>
						{children}
					</Button>
				)
		}
	</li>
)

export default FooterInfoLiView
