'use client'

import {
	classNameFunc
} from '@dg/common/lib/common'
import {
	SelectHTMLAttributes, PropsWithChildren
} from 'react'

export interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
	borderType?: `none` | `outline` | `underline`;
	className?: string;
	defaultBgTransparent?: boolean;
	defaultDisabledBgColor?: boolean;
	defaultTextSize?: boolean;
	error?: boolean;
	height?: string;
	hover?: boolean;
	pd?: boolean;
	px?: boolean;
	py?: boolean;
	rounded?: boolean;
	transition?: boolean;
}

const Select = (props: PropsWithChildren<SelectProps>) => {
	const {
		children, className = ``, height = `h-12`,
		borderType = `outline`, px = true, py = false, pd = false, rounded = false, hover = false, transition = true,
		defaultTextSize = true, defaultDisabledBgColor = true, defaultBgTransparent = true, id, defaultValue,
		error = false,
		...rest
	} = props

	const customClassName =
		classNameFunc(`
			cursor-pointer appearance-none overflow-hidden
			outline-none disabled:cursor-not-allowed
			${defaultBgTransparent === true && error === false ? `bg-transparent` : ``}
			${transition === true ? `transition-colors` : ``}
			${pd === true ? `py-4 pl-4 pr-10` : ``}
			${py === true ? `py-4` : ``}
			${px === true ? `pl-4 pr-10` : ``}
			${
				borderType !== `none` ?
					`
						border-solid
						${borderType === `outline` ? `border` : `border-b`}
						${
							error === false ?
								`
									border-dg-500 focus:border-dg-black
									dark:border-dgdark-500 dark:focus:border-dgdark-black
								` :
								`
									border-solid border-dg-red bg-dg-red100
									dark:border-dgdark-red dark:bg-dgdark-red100
								`
						}
					` :
					``
			}
			${rounded === true ? `rounded-lg` : ``}
			${
				hover === true && error === false ?
					`
						hover:border-dg-black hover:bg-dg-500/30
						dark:hover:border-dgdark-black dark:hover:bg-dgdark-500/30
					` :
					``
			}
			${defaultTextSize === true ? `text-sm` : ``}
			${defaultDisabledBgColor === true ? `disabled:bg-dg-300 dark:disabled:bg-dgdark-300` : ``}
			${height}
			${className}
		`)

	return (
		<select
			{...rest}
			key={`${id ?? ``}-${(defaultValue ?? ``).toString()}`}
			className={customClassName}
			defaultValue={defaultValue}
			id={id}
		>
			{children}
		</select>
	)
}

export default Select
