'use client'

import {
	commonLoadingValue
} from '@dg/common/lib/store'
import {
	useAtom
} from 'jotai'
import {
	useCallback
} from 'react'

const useLoading = () => {
	const [
		loading,
		setLoading
	] = useAtom(commonLoadingValue)

	const get = useCallback(() => loading, [
		loading
	])

	const open = useCallback(() => {
		setLoading(true)
	}, [
		setLoading
	])

	const close = useCallback(() => {
		setLoading(false)
	}, [
		setLoading
	])

	return {
		close,
		get,
		open
	}
}

export default useLoading
