'use client'

import Button from "@dg/common/components/Button"
import Layerpopup from "@dg/common/components/Layerpopup"
import {
	classNameFunc
} from "@dg/common/lib/common"
import useLayerpopup from '@dg/common/lib/hooks/useLayerpopup'
import {
	CheckIcon
} from '@heroicons/react/24/outline'
import InputLabelView from "member/components/Common/Find/InputLabelView"
import StackView from "member/components/Common/Find/StackView"
import ValidateInput from "member/components/Common/Validate/ValidateInput"
import IcInfo from 'member/public/icons/mobile/login/ic-info.svg'
import React from 'react'

interface SnsPopupNoEmailViewProps {
	email: string;
	name: string;
	onChangeLoginData: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onClickCheckEmail: () => void;
	onClickClose: () => void;
	onClickSubmit: () => void;
	submitDisabledBtn: boolean;
}

const SnsPopupNoEmailView = ({
	onChangeLoginData,
	onClickCheckEmail,
	onClickClose,
	onClickSubmit,
	email,
	name,
	submitDisabledBtn
}: SnsPopupNoEmailViewProps) => {
	const layerpopup = useLayerpopup()

	const onClickSnsEmailAlertPopupClose = () => {
		layerpopup.close(`layerpopup-snsemail-invalid`)
	}

	const inputWrapClassName = classNameFunc(`flex h-9 flex-auto space-x-2.5`)

	return (
		<Layerpopup
			className="w-[500px] pb-6"
			id="layerpopup_noemail"
			setTitle="氏名とメー ルアドレスを入力してください。"
			setWidth={false}
			customFooter={
				(
					<div
						className="mx-auto flex w-full space-x-2 px-14"
					>
						<Button
							className="flex-1 border border-dg-500 bg-dg-white py-4  text-base text-[#9a9ca2]"
							onClick={onClickClose}
							rounded
						>
							閉じる
						</Button>

						<Button
							onClick={onClickSubmit}
							className={
								classNameFunc(`
									flex-1 border py-4 text-base
									${submitDisabledBtn ? `bg-dg-500 text-dg-800` : `bg-dg-red text-dg-100`}
								`)
							}
							rounded
						>
							保存する
						</Button>
					</div>
				)
			}
		>
			<div>
				<p
					className="mb-11 text-center text-sm text-dg-600"
				>
					Qoo10のサービスを利用するには
					<br />
					氏名とメールアドレスの入力が必要です。
				</p>

				<StackView
					className="px-14.5"
					direction="col"
				>
					<StackView
						direction="col"
					>
						<InputLabelView
							className="w-30 text-left"
							name="name"
							title="氏名"
						/>

						{/* <InputView
							name="name"
							onChange={onChangeLoginData}
							placeholder="氏名を入力してください。"
							type="text"
							validateType="text"
							value={name}
						/> */}

						<ValidateInput
							borderType="underline"
							className="h-9 w-full"
							defaultValue={name}
							name="name"
							onChange={onChangeLoginData}
							placeholder="氏名を入力してください。"
							type="text"
							validateType="name"
							wrapClassName={inputWrapClassName}
						/>
					</StackView>

					<StackView
						className="mt-7.5 text-left"
						direction="col"
					>
						<InputLabelView
							className="w-30 text-left"
							name="email"
							title="メール"
						/>

						{/* <InputView
							name="email"
							onChange={onChangeLoginData}
							placeholder="メールアドレスを入力してください。"
							type="text"
							validateType="email"
							value={email}
						/> */}
						<ValidateInput
							borderType="underline"
							className="h-9 w-full"
							defaultValue={email}
							name="email"
							onChange={onChangeLoginData}
							placeholder="メールアドレスを入力してください。"
							type="text"
							validateType="email"
							wrapClassName={inputWrapClassName}
						/>
					</StackView>

					<Button
						onClick={onClickCheckEmail}
						className={
							classNameFunc(`
								mt-9 flex items-center justify-center
								rounded-full border border-[#ffd5d0] bg-[#fff8f8]
								px-5 py-4 text-center text-[#ff422e]
							`)
						}
					>
						<CheckIcon
							className="mr-2 size-5 font-bold"
						/>

						<span>
							使用できるメールであるかチェック
						</span>
					</Button>

					<Layerpopup
						className="w-[400px]"
						id="layerpopup-snsemail-invalid"
						setTitle="入力されたメールアドレスは<br />登録不可です。"
						setWidth={false}
						closeBtn
					>
						<div
							className={
								classNameFunc(`
									border-b border-solid border-dg-500 pb-5 text-sm text-dg-800
									dark:border-dgdark-500 dark:text-dgdark-800
								`)
							}
						>
							入力したメールアドレスをもう一度確認してください。
						</div>

						<div
							className="mt-6 text-left text-sm"
						>
							<p
								className="mb-1 flex"
							>
								<IcInfo
									className="mr-1 w-4"
								/>
								メール登録ができない場合の例
							</p>

							<ul
								className="text-dg-800 dark:text-dgdark-800"
							>
								<li>
									&middot; 登録済みのメールアドレス
								</li>

								<li>
									&middot; 不正確なメールアドレス
								</li>

								<li>
									&middot; 使用不可能なメールアドレス
								</li>
							</ul>
						</div>

						<Button
							className="mx-auto inline-block w-[125px] bg-dg-red py-4 text-dg-white"
							onClick={onClickSnsEmailAlertPopupClose}
							rounded
						>
							閉じる
						</Button>
					</Layerpopup>

					<Layerpopup
						id="layerpopup-snsemail-valid"
					>
						<div
							className={
								classNameFunc(`
									mt-7.5 border-b border-solid border-dg-500 pb-5 text-sm
									text-dg-800 dark:border-dgdark-500 dark:text-dgdark-800
								`)
							}
						>
							使用可能なメールアドレスです。
						</div>
					</Layerpopup>
				</StackView>
			</div>
		</Layerpopup>
	)
}

export default SnsPopupNoEmailView
