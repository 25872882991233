'use client'

import Input from "@dg/common/components/Input"
import {
	classNameFunc
} from "@dg/common/lib/common"
import {
	useRadioGroupContext
} from "./RadioGroup"

interface RadioProps {
	className?: string;
	id: string;
	labelClassName?: string;
	name: string;
	spanClassName?: string;
	text: string;
}

const Radio = ({
	className,
	id,
	labelClassName,
	name,
	spanClassName,
	text
}: RadioProps) => {
	const {
		activeId, dispatchIdEvent
	} = useRadioGroupContext()

	return (
		<Input
			checked={id === activeId}
			className={className !== undefined ? classNameFunc(className) : undefined}
			id={id}
			labelClassName={labelClassName !== undefined ? classNameFunc(labelClassName) : undefined}
			name={name}
			onChange={() => dispatchIdEvent(`ACTIVE`, id)}
			spanClassName={spanClassName !== undefined ? classNameFunc(spanClassName) : undefined}
			text={text}
			type="radio"
		/>
	)
}

export default Radio
