'use client'

import FooterTabRecentView from '@dg/common/components/Mobile/Footer/FooterView/FooterTabRecent/FooterTabRecentView/FooterTabRecentView'
import {
	addCookie
} from '@dg/common/lib/common'
import useGetApi from '@dg/common/lib/hooks/useGetApi'
import useLayerpopup from '@dg/common/lib/hooks/useLayerpopup'
import useSetApi from '@dg/common/lib/hooks/useSetApi'
import useToast from '@dg/common/lib/hooks/useToast'
import {
	wishApi, wishRemoveApi, bottomInfoApi,
	type BottomInfoApiProps
} from '@dg/common/lib/mobile/api'
import {
	useQueryClient, UseQueryResult
} from '@tanstack/react-query'
import {
	useCallback, useState
} from 'react'

const AppFooterTabRecent = () => {
	const queryClient = useQueryClient()
	const layerpopup = useLayerpopup()
	const toast = useToast()

	const [
		pageData,
		setPageData
	] = useState({
		wishActive: false
	})

	const bottomInfoDataApi = useGetApi({
		api: bottomInfoApi,
		name: `bottomInfoDataApi`
	}) as UseQueryResult<BottomInfoApiProps>

	const wishChangeFunc = useCallback((value: boolean) => {
		setPageData({
			...pageData,
			wishActive: value
		})
	}, [
		pageData
	])

	const resetRecentItems = useCallback(async (boolean = false) => {
		if (boolean === true) {
			addCookie(`ezview=`, 0)

			await queryClient.refetchQueries({
				queryKey: [
					`bottomInfoDataApi`
				]
			})

			layerpopup.close(`layerpopup-recentitemsremovealert`)

			toast.open({
				id: `toast-remove`
			})
		} else {
			layerpopup.open({
				id: `layerpopup-recentitemsremovealert`
			})
		}
	}, [
		layerpopup,
		queryClient,
		toast
	])

	const wishAddSetApi = useSetApi({
		api: wishApi,
		successCallback: async () => {
			wishChangeFunc(true)

			await queryClient.refetchQueries({
				queryKey: [
					`bottomInfoDataApi`
				]
			})

			toast.open({
				id: `toast-wishalert`
			})
		}
	})

	const wishRemoveSetApi = useSetApi({
		api: wishRemoveApi,
		successCallback: async () => {
			wishChangeFunc(false)

			await queryClient.refetchQueries({
				queryKey: [
					`bottomInfoDataApi`
				]
			})

			await queryClient.refetchQueries({
				queryKey: [
					`addressGroupDataApi`
				]
			})

			toast.open({
				id: `toast-wishalert`
			})
		}
	})

	const wishAddFunc = useCallback((formData: URLSearchParams) => {
		wishAddSetApi.set(formData)
	}, [
		wishAddSetApi
	])

	const wishRemoveFunc = useCallback((formData: URLSearchParams) => {
		wishRemoveSetApi.set(formData)
	}, [
		wishRemoveSetApi
	])

	const props = {
		list: bottomInfoDataApi.data?.result,
		resetRecentItems,
		wishActive: pageData.wishActive,
		wishAddFunc,
		wishRemoveFunc
	}

	return (
		<FooterTabRecentView
			{...props}
		/>
	)
}

export default AppFooterTabRecent
