'use client'

import Input from "@dg/common/components/Input"
import StackView from "member/components/Common/Find/StackView"
import React from 'react'

interface BirthDayViewProps {
	birthdayText?: string;
	height?: string;

	/** @description birthdayText: 1991-02-27로 들어와야함 */
	max?: string;
	onChangeBirthDay: (event: React.ChangeEvent<HTMLInputElement>) => void;
	width?: string;
}

const BirthDayView = ({
	max, birthdayText, width, height, onChangeBirthDay
}: BirthDayViewProps) => (
	<StackView
		className="relative gap-2"
	>
		<Input
			className={width ?? `w-full`}
			defaultValue={birthdayText}
			height={height}
			max={max}
			min="1900-01-01"
			onChange={onChangeBirthDay}
			type="date"
			px
			rounded
		/>
	</StackView>
)

export default BirthDayView
