'use client'

import {
	ProvidersErrorProps
} from '@dg/common/components/Providers'
import {
	device
} from '@dg/common/lib/common'
import useLoading from '@dg/common/lib/hooks/useLoading'
import pathsData from '@dg/common/public/json/mobile/paths'
import pathsPcData from '@dg/common/public/json/pc/paths'
import {
	MutationFunction, useMutation
} from '@tanstack/react-query'
import {
	useRouter
} from 'next/navigation'
import {
	useCallback, useMemo
} from 'react'

interface UseSetApiProps {
	api: MutationFunction<unknown, never>;
	errorCallback?: (
		error: ProvidersErrorProps | string,
		variables: never
	) => void;
	mutateCallback?: (
		variables: never
	) => void;
	name?: string;
	progress?: boolean;
	successCallback?: (
		successData: never,
		variables: never
	) => void;
}

const useSetApi = ({
	api, mutateCallback, successCallback, errorCallback, progress = false, name
}: UseSetApiProps) => {
	const data = useMemo(() => ({
		delayFunc: null as unknown as ReturnType<typeof setTimeout>,
		flag: false
	}), [])

	const router = useRouter()
	const loading = useLoading()

	const useSetApiMutation = useMutation({
		mutationFn: api as MutationFunction<never, never>,
		mutationKey: [
			`${name}`
		],
		onError: (error: ProvidersErrorProps, variables: never) => {
			data.flag = false

			const customError = error

			if (typeof Error !== `undefined` && process.env.NODE_ENV === `development`) {
				console.log(
					customError.name,
					customError.errorMessage,
					customError.response.data,
					customError.response.status,
					customError.response.statusText
				)
			}

			if (progress === true) {
				loading.close()
			}

			if (customError.response.status === 401 && errorCallback === undefined) {
				const pathDataChk = device().browser.desktop === undefined ? pathsData : pathsPcData

				if (device().browser.app === undefined) {
					return router.replace(`${pathDataChk.login}?ReturnUrl=${encodeURIComponent(window.location.href)}`)
				}

				return window.open(`${pathDataChk.login}?ReturnUrl=close`)
			}

			if (errorCallback !== undefined) {
				errorCallback(customError, variables)
			}

			return false
		},
		onMutate: (variables: never) => {
			if (progress === true) {
				loading.open()
			}

			if (mutateCallback !== undefined) {
				mutateCallback(variables)
			}
		},
		onSettled: () => {
			/* empty */
		},
		onSuccess: (successData: never, variables: never) => {
			data.flag = false

			if (progress === true) {
				loading.close()
			}

			if (successCallback !== undefined) {
				successCallback(successData, variables)
			}
		}
	})

	const {
		mutate
	} = useSetApiMutation

	const set = useCallback((value?: unknown) => {
		clearTimeout(data.delayFunc)
		data.delayFunc = setTimeout(() => {
			if (data.flag === false) {
				data.flag = true

				mutate(value as never)
			}
		}, 500)
	}, [
		data,
		mutate
	])

	return {
		...useSetApiMutation,
		set
	}
}

export default useSetApi
