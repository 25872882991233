'use client'

import Input, {
	InputProps
} from "@dg/common/components/Input/Input"
import {
	classNameFunc
} from "@dg/common/lib/common"
import useEmailValidate from "@dg/common/lib/hooks/useEmailValidate"
import useIdValidate from "@dg/common/lib/hooks/useIdValidate"
import usePasswordValidate from "@dg/common/lib/hooks/usePasswordValidate"
import usePhoneValidate from "@dg/common/lib/hooks/usePhoneValidate"
import InputBoxStyleView, {
	TVariant
} from "member/components/Common/Find/InputBoxStyleView"
import React from 'react'

interface InputViewProps extends InputProps {
	className?: string;
	rounded?: boolean;
	validateType?: `email` | `id` | `password` | `phone` | `text`;
	validationMargin?: number;
	validationNowrap?: boolean;
	variant?: TVariant;
}

interface validateHookType {
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	result: {
		text: string;
		validValue?: string;
		value: string;
	};
}

const InputView = ({
	validateType,
	onChange,
	value,
	validationMargin,
	validationNowrap,
	variant,
	width,
	...rest
}: InputViewProps) => {
	const emailValidate = useEmailValidate()
	const idValidate = useIdValidate()
	const passwordValidate = usePasswordValidate()
	const phoneValidate = usePhoneValidate()

	let validate: validateHookType | null = null

	if (validateType === `email`) {
		validate = emailValidate
	} else if (validateType === `id`) {
		validate = idValidate
	} else if (validateType === `password`) {
		validate = passwordValidate
	} else if (validateType === `phone`) {
		validate = phoneValidate
	}

	const validationTextCss =
		`
			mt-1 w-full ${validationMargin ? `ml-${validationMargin}` : ``}
			${validationNowrap ? `whitespace-nowrap` : ``} text-xs text-dg-red
		`

	return (
		<div
			className={classNameFunc(`${width ? `w-[${width}px]` : `w-full`} flex-1`)}
		>
			<InputBoxStyleView
				className={classNameFunc(`${width ? `w-[${width}px]` : `w-full`} ${`!border-0`}`)}
				variant={variant}
			>
				<Input
					{...rest}
					className={width ? `w-[${width}px]` : `w-full`}
					height="h-full"
					onChange={
						(event: React.ChangeEvent<HTMLInputElement>) => {
							if (onChange !== undefined) {
								onChange(event)
							}

							if (validate !== null) {
								validate.onChange(event)
							}
						}
					}
					value={
						validate &&
						!(validate.result.value === `` || value === ``) ?
							validate.result.value :
							value
					}
					px
				/>
			</InputBoxStyleView>

			{
				value !== `` && validate && validate.result.text !== `false` && (
					<p
						className={classNameFunc(validationTextCss)}
					>
						{validate.result.text}
					</p>
				)
			}
		</div>
	)
}

export default InputView
