'use client'

import {
	SyntheticEvent, useCallback, useMemo
} from 'react'

const useImageValidate = () => {
	const data = useMemo(() => ({
		event: null as HTMLImageElement | null
	}), [])

	const onError = useCallback((event: SyntheticEvent) => {
		const obj = event.target as HTMLImageElement
		const src = `/images/common/noImage.png`

		obj.src = src
		obj.srcset = src

		data.event = obj

		return true
	}, [
		data
	])

	return {
		onError
	}
}

export default useImageValidate
