'use client'

import {
	addCookie,
	device
} from '@dg/common/lib/common'
import useGetApi from '@dg/common/lib/hooks/useGetApi'
import useLayerpopup from '@dg/common/lib/hooks/useLayerpopup'
import {
	updateUserAdultInfo, layerAlert
} from '@dg/common/lib/qoo10Common'
import {
	UseQueryResult
} from '@tanstack/react-query'
import {
	goodsApi, specialApi, GoodsApiResponseProps, SpecialApiResponseProps
} from "member/lib/api"
import {
	useSearchParams
} from 'next/navigation'
import React, {
	useCallback, useState
} from 'react'
import {
	useTranslation
} from 'react-i18next'
import LoginAdultView from "./LoginAdultView/LoginAdultView"

interface AdultPopupDataType {
	birthday: string;
}

interface LoginAdultProps {
	onConfirmAdult: () => void;
}

const adultAge = 18

const LoginAdult = ({
	onConfirmAdult
}: LoginAdultProps) => {
	const searchParams = useSearchParams()
	const layerpopup = useLayerpopup()

	const {
		t
	} = useTranslation()

	const goodscode = searchParams.get(`goodcode`)
	const sid = searchParams.get(`sid`)

	const [
		adultPopupData,
		setAdultPopupData
	] = useState<AdultPopupDataType>({
		birthday: `2022-01-01`
	})

	const loginGoodsApi = useGetApi({
		api: (): Promise<GoodsApiResponseProps> | `` => (goodscode ? goodsApi(goodscode) : ``),
		name: `loginGoodsApi`
	}) as UseQueryResult<GoodsApiResponseProps>

	const loginSpecialApi = useGetApi({
		api: (): Promise<SpecialApiResponseProps> | `` => (sid ? specialApi(sid) : ``),
		name: `loginSpecialApi`
	}) as UseQueryResult<SpecialApiResponseProps>

	const adultPopupTitle = loginGoodsApi.data?.result?.gd_nm || loginSpecialApi.data?.result?.title || ``

	const onChangeAdultData = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		setAdultPopupData({
			birthday: event.target.value
		})
	}, [])

	const onClickSubmitAdultPopup = useCallback(() => {
		const today = new Date()
		const tempBirth = new Date(adultPopupData.birthday)
		const diffDate = today.getTime() - tempBirth.getTime()

		const diffyear = Math.floor(Math.abs(diffDate / (1000 * 60 * 60 * 24 * 365)))

		if (diffyear >= adultAge) {
			if (device().browser.iphone && device().browser.app) {
				updateUserAdultInfo(`Y`)
			}

			addCookie(`isAdultCheckConfirm=${adultPopupData.birthday}`, -1)

			onConfirmAdult()
		} else {
			if (device().browser.iphone && device().browser.app) {
				updateUserAdultInfo(`N`)
			}

			layerAlert(layerpopup.open, t(`AdultGoods`))

			// window.close()
			// FIX: 이건 없어도 될거같은 코드 BUT 확인필요
		}
	}, [
		adultPopupData.birthday,
		layerpopup.open,
		onConfirmAdult,
		t
	])

	return (
		<LoginAdultView
			adultPopupTitle={adultPopupTitle}
			onChangeAdultData={onChangeAdultData}
			onClickSubmitAdultPopup={onClickSubmitAdultPopup}
		/>
	)
}

export default LoginAdult
