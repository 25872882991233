'use client'

import useImageValidate from '@dg/common/lib/hooks/useImageValidate'
import Image from 'next/image'

interface ListImageViewProps {
	image: {
		altName: string;
		imageName: string;
		imageType?: string; // gd-image
	};
	item: Record<string, string | number | boolean>;
}

const ListImageView = ({
	image, item
}: ListImageViewProps) => {
	const imageValidate = useImageValidate()

	const {
		altName, imageName, imageType
	} = image

	let imageSrc = (item[imageName] || ``).toString()

	if (imageType === `gd-image`) {
		imageSrc = `https://gd.image-qoo10.jp/li/${item[imageName].toString().slice(-3)}/${item[imageName].toString().slice(-6, item[imageName].toString().length - 3)}/${item[imageName].toString()}.g_80-w-st_g.jpg`
	}

	return (
		<div
			className="relative w-full pt-full"
		>
			<span
				className="absolute left-0 top-0 block size-full"
			>
				<Image
					alt={`${item[altName]} Image`}
					className="object-cover"
					onError={imageValidate.onError}
					sizes="20vw"
					src={imageSrc}
					fill
				/>
			</span>
		</div>
	)
}

export default ListImageView
