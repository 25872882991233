'use client'

import ValidateTextView from 'member/components/Common/Validate/ValidateText/ValidateTextView/ValidateTextView'
import {
	ReactNode
} from 'react'

interface ValidateTextProps {
	children?: ReactNode;
	className?: string;
	errorMarkVisible?: boolean;
	inline?: boolean;
	mt?: boolean;
}

const ValidateText = ({
	children, className = ``, inline = true, mt = true, errorMarkVisible = true
}: ValidateTextProps) => {
	const props = {
		children,
		className,
		errorMarkVisible,
		inline,
		mt
	}

	return (
		<ValidateTextView
			{...props}
		/>
	)
}

export default ValidateText
