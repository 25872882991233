'use client'

import {
	FormEvent, useCallback, useMemo
} from 'react'
import {
	useTranslation
} from 'react-i18next'

const useEmailValidate = () => {
	const {
		t
	} = useTranslation()

	const data = useMemo(() => ({
		text: ``,
		value: ``
	}), [])

	const chkFunc = useCallback((value: string) => {
		let text = ``

		if (value.length > 0 && value.match(/@/) !== null && (/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([A-z]{2,6}(?:\.[A-z]{2})?)$/).test(value) === false) {
			text = t(`정확한 이메일 주소를 입력하세요.`)
		} else if (value.match(/@/) === null) {
			text = `false`
		}

		return text
	}, [
		t
	])

	const onChange = useCallback((event: FormEvent) => {
		const obj = event.target as HTMLInputElement
		const value = obj.value

		data.text = chkFunc(value || ``)
		data.value = value
	}, [
		chkFunc,
		data
	])

	const validate = useCallback((value: string) => {
		data.text = chkFunc(value || ``)

		return data.text
	}, [
		chkFunc,
		data
	])

	return {
		onChange,
		result: data,
		validate
	}
}

export default useEmailValidate
