'use client'

import Button from '@dg/common/components/Button'
import {
	classNameFunc
} from '@dg/common/lib/common'
import {
	ChevronLeftIcon, XMarkIcon
} from '@heroicons/react/24/outline'
import {
	MouseEvent, ReactNode, type RefObject
} from 'react'
import {
	useTranslation
} from 'react-i18next'

// import IcClose from '@dg/common/public/icons/common/ic-close.svg'

interface LayerpopupViewProps {
	backBtn: boolean;
	bottom: boolean;
	btnCloseFunc: (event?: Event | MouseEvent) => void;
	className: string;
	closeBtn: boolean;
	customFooter: ReactNode;
	defaultFooter: boolean;
	full: boolean;
	height: string;
	id: string;
	layerpopupRef: RefObject<HTMLDivElement | null>;
	maxHeight: string;
	overflow: boolean;
	setWidth: boolean;
	text: ReactNode;
	title: ReactNode;
	transition?: boolean;
	width: string;
	wrapHidden: boolean;
	wrapOpacity: boolean;
	wrapZIndex: string;
}

const LayerpopupView = ({
	layerpopupRef,
	title,
	text,
	id,
	className,
	backBtn,
	bottom,
	closeBtn,
	customFooter,
	defaultFooter,
	full,
	setWidth,
	overflow,
	wrapHidden,
	wrapOpacity,
	wrapZIndex,
	width,
	height,
	maxHeight,
	transition = true,
	btnCloseFunc
}: LayerpopupViewProps) => {
	const {
		t
	} = useTranslation()

	const customClassName =
		classNameFunc(`
			fixed z-10 flex flex-col bg-dg-white shadow-lg shadow-dg-black/10
			dark:bg-dgdark-white dark:shadow-dgdark-black/10
			${
				transition === true ?
					`${bottom === true ? `transition-transform` : `transition-opacity`}` :
					``
			}
			${bottom === true ? `bottom-0 rounded-t-md` : ``}
			${full === true ? `top-0 h-full max-h-full` : height}
			${
				full === false && bottom === false ?
					`
						left-1/2 top-1/2 min-w-50 -translate-x-1/2 -translate-y-1/2
						rounded-2xl pt-6
						${
							setWidth === true && width === `w-max` ?
								`w-[calc(100%-2rem)] max-w-112.5` :
								`${width} max-w-9/10`
						}
					` :
					`left-0`
			}
			${full === true || bottom === true ? `w-full` : ``}
			${full !== true && maxHeight === `max-h-9/10` ? `max-h-9/10` : maxHeight}
			${
				wrapOpacity === true ?
					`${bottom === true ? `translate-y-full` : `opacity-0`}` :
					`${bottom === true ? `translate-y-0` : `opacity-100`}`
			}
			${overflow === false ? `overflow-hidden` : ``}
			${customFooter !== undefined || defaultFooter === true ? `pb-0` : `pb-6`}
			${className}
		`)

	const customHeaderClassName =
		classNameFunc(`
			layerpopup-header relative flex min-h-12 w-full flex-none
			${bottom === true ? `bottom-0` : ``}
			${full === true ? `border-b border-solid border-dg-200 dark:border-dgdark-200` : ``}
			${bottom === false && full === false ? `px-6 text-lg` : ``}
			${backBtn === true ? `justify-start` : `justify-end`}
		`)

	return (
		<div
			className={
				classNameFunc(`
					layerpopup-wrap fixed left-0 top-0 size-full items-center justify-center
					${wrapHidden === true ? `invisible overflow-hidden` : ``}
					${wrapZIndex}
				`)
			}
		>
			<div
				className={customClassName}
				id={id}
				ref={layerpopupRef}
			>
				{
					full === false && bottom === false && closeBtn === true && (
						<Button
							className="btn-close absolute right-2.5 top-2.5 z-10 block size-10 flex-none rounded p-1"
							data-original="close"
							onClick={btnCloseFunc}
							hover
						>
							<XMarkIcon />

							<span
								className="sr-only"
							>
								{backBtn === true ? `Back` : `Close`}
							</span>
						</Button>
					)
				}

				{
					(full === true || bottom === true || title !== ``) && (
						<div
							className={customHeaderClassName}
						>
							<strong
								className={
									classNameFunc(`
										relative order-2 flex w-full self-center py-2 uppercase
										${
											bottom === true ?
												`
													mx-4 !pt-10 after:absolute after:bottom-0 after:left-0 after:block
													after:h-px after:w-full after:bg-dg-black
													dark:after:bg-dgdark-black
												` :
												``
										}
										${full === true ? `px-4` : ``}
										${bottom === false && full === false ? `justify-center !pb-4.5 !pt-3` : ``}
									`)
								}
							>
								{
									typeof title !== `string` ?
										title :
										(
											<span
												// eslint-disable-next-line react/no-danger
												dangerouslySetInnerHTML={
													{
														__html: title
													}
												}
											/>
										)
								}
							</strong>

							{
								(full === true || bottom === true) && closeBtn === true && (
									<Button
										data-original="close"
										onClick={btnCloseFunc}
										className={
											classNameFunc(`
												btn-close block flex-none
												${backBtn === true ? `order-1` : `order-3`}
												${bottom === false && backBtn === true ? `-mr-2` : ``}
												${bottom === false && backBtn === false ? `-ml-2` : ``}
												${
													bottom === true ?
														`
															absolute left-1/2 top-0 h-8.5 w-16 -translate-x-1/2
															rounded-t-lg px-2 after:block after:h-0.5 after:w-full
															after:rounded-md after:bg-dg-black
															after:dark:bg-dgdark-black
														` :
														`min-h-full w-10 p-2`
												}
											`)
										}
										hover
									>
										{
											bottom === false && (
												backBtn === true ?
													<ChevronLeftIcon /> :
													<XMarkIcon />
											)
										}

										<span
											className="sr-only"
										>
											{backBtn === true ? `Back` : `Close`}
										</span>
									</Button>
								)
							}
						</div>
					)
				}

				<div
					className={
						classNameFunc(`
							layerpopup-content relative flex flex-auto flex-col content-start overflow-y-auto
							overflow-x-hidden overscroll-contain break-words text-sm
							${
								full === true || bottom === true ?
									`p-4 text-left` :
									`space-y-4.5 px-6 text-center`
							}
							${
								full === false && bottom === false && title === `` && closeBtn === false &&
								(customFooter !== undefined || defaultFooter === true) ?
									`pt-3` :
									``
							}
							${
								full === false && bottom === false && title === `` && closeBtn === false &&
								customFooter === undefined && defaultFooter === false ?
									`pt-1.5` :
									``
							}
							${full === false && bottom === false && title === `` && closeBtn === true ? `pt-9` : ``}
							${customFooter !== undefined || defaultFooter === true ? `pb-9` : ``}
							${customFooter === undefined && defaultFooter === false && title === `` ? `pb-1.5` : ``}
							${customFooter === undefined && defaultFooter === false && title !== `` ? `pb-3` : ``}
						`)
					}
				>
					{
						typeof text !== `string` ?
							text :
							(
								<div
									// eslint-disable-next-line react/no-danger
									dangerouslySetInnerHTML={
										{
											__html: text
										}
									}
								/>
							)
					}
				</div>

				{
					(customFooter !== undefined || defaultFooter === true) && (
						<div
							className={
								classNameFunc(`
									layerpopup-footer relative flex min-h-14 flex-none
									${
										customFooter === undefined ?
											`border-t border-solid border-dg-400 dark:border-dg-400` :
											``
									}
								`)
							}
						>
							{
								customFooter === undefined ?
									(
										<Button
											className="btn-close"
											data-original="close"
											onClick={btnCloseFunc}
										>
											{t(`확인`)}
										</Button>
									) :
									customFooter
							}
						</div>
					)
				}
			</div>

			<div
				className={
					classNameFunc(`
						fixed left-0 top-0 size-full cursor-pointer bg-dg-black/60 transition-opacity
						dark:bg-dgdark-black/60
						${wrapOpacity === true ? `opacity-0` : `opacity-100`}
					`)
				}
			/>
		</div>
	)
}

export default LayerpopupView
