'use client'

import Toast from '@dg/common/components/Toast'
import {
	classNameFunc
} from '@dg/common/lib/common'
import IcHeart from '@dg/common/public/icons/mobile/common/ic-heart.svg'
import {
	useTranslation
} from 'react-i18next'

interface WishToastProps {
	active?: boolean;
}

const WishToast = ({
	active = false
}: WishToastProps) => {
	const {
		t
	} = useTranslation()

	return (
		<Toast
			height="h-44"
			id="toast-wishalert"
			width="w-44"
			className={
				classNameFunc(`
					top-1/2 flex -translate-y-1/2 items-center justify-center !rounded-full !bg-dg-white
					text-center shadow-lg dark:!bg-dgdark-white
					[&>div>button]:hidden [&>div>div]:!flex [&>div>div]:!flex-col
				`)
			}
		>
			<IcHeart
				className={
					classNameFunc(`
						-mt-2 h-20
						${
							active === true ?
								`[&>path]:fill-dg-red dark:[&>path]:fill-dgdark-red` :
								`[&>path]:fill-dg-600 dark:[&>path]:fill-dgdark-600`
						}
					`)
				}
			/>

			<span
				className={
					classNameFunc(`
						text-sm
						${active === true ? `text-dg-red dark:text-dgdark-red` : `text-dg-800 dark:text-dgdark-800`}
					`)
				}
			>
				{active === true ? t(`즐겨찾기에 추가`) : t(`즐겨찾기 삭제`)}
			</span>
		</Toast>
	)
}

export default WishToast
