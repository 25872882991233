'use client'

import {
	FormEvent, useCallback, useMemo
} from 'react'
import {
	useTranslation
} from 'react-i18next'

type nameType = `mei` | `sei`

const useNameValidate = () => {
	const {
		t
	} = useTranslation()

	const data = useMemo(() => ({
		text: ``,
		value: ``
	}), [])

	const chkFunc = useCallback((type: nameType, value: string) => {
		let text = ``

		if (value.length > 0 && (/^[ァ-ヶー]+$/).test(value) === false) {
			text = t(`({text})를 전각 가나로 입력해 주십시오.`).replace(/{text}/gim, type === `sei` ? t(`세이`) : t(`메이`))
		}

		return text
	}, [
		t
	])

	const onChange = useCallback((event: FormEvent, nameType: nameType) => {
		const obj = event.target as HTMLInputElement
		const value = obj.value

		data.text = chkFunc(nameType || `mei`, value || ``)
		data.value = value
	}, [
		chkFunc,
		data
	])

	const validate = useCallback((type: nameType, value: string) => {
		data.text = chkFunc(type || `mei`, value || ``)

		return data.text
	}, [
		chkFunc,
		data
	])

	return {
		onChange,
		result: data,
		validate
	}
}

export default useNameValidate
