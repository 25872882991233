'use client'

import Layout from '@dg/common/components/Layout'
import Link from '@dg/common/components/Link'
import {
	device
} from '@dg/common/lib/common'
import useImageValidate from '@dg/common/lib/hooks/useImageValidate'
import pathsData from '@dg/common/public/json/mobile/paths'
import pathsPcData from '@dg/common/public/json/pc/paths'
import Image from 'next/image'
import {
	useTranslation
} from 'react-i18next'

const GlobalError = ({
	error
}: {
	error: Error & {
		digest?: string;
	};
}) => {
	const {
		t
	} = useTranslation()

	const imageValidate = useImageValidate()

	return (
		<html
			lang="ja"
		>
			<body>
				<Layout>
					<style>
						{
							`
								#wrap-content {
									display: flex;
									align-items: center;
									justify-content: center;
									background-color: #fcfcfd;
								}
							`
						}
					</style>

					<section
						id="error"
					>
						<div
							className="flex size-full flex-col items-center justify-center"
						>
							<Image
								alt="Page Not Found"
								className="object-cover"
								height={51}
								onError={imageValidate.onError}
								src="/images/common/404.png"
								width={144}
							/>

							<strong
								className="mt-8 block text-lg transition-colors"
							>
								{t(`이 페이지에 액세스할 수 없습니다.`)}
							</strong>

							<span
								className="mb-6 mt-4 block text-sm text-dg-600 dark:text-dgdark-600"
							>
								{t(`불편을 끼쳐 드려 죄송합니다. 네트워크 상태를 확인한 후 다시 시도해 주세요.`)}
							</span>

							<Link
								className="border border-solid border-dg-500 dark:border-dgdark-500"
								href={(device().browser.mobile === true ? pathsData : pathsPcData).home}
								pd
							>
								{t(`이전 페이지로 돌아가기`)}
							</Link>

							<span
								className="mt-25 block text-sm text-dg-600 dark:text-dgdark-600"
							>
								{error.message}
							</span>
						</div>
					</section>
				</Layout>
			</body>
		</html>
	)
}

export default GlobalError
