'use client'

import {
	addCookie
} from "@dg/common/lib/common"
import useLayerpopup from "@dg/common/lib/hooks/useLayerpopup"
import {
	getLegacyServerUrl
} from "@dg/common/lib/qoo10Common"
import pathsData from '@dg/common/public/json/pc/paths'
import {
	getHostCheckedUrl
} from "member/lib/common"
import {
	useRouter
} from "next/navigation"
import {
	useEffect, useState
} from "react"
import {
	useTranslation
} from "react-i18next"
import LoginSnsAreaView, {
	LoginSnsAreaPopupDataProps, LoginSnsAreaViewUrlsProps
} from "./LoginSnsAreaView/LoginSnsAreaView"

interface snsLoginProcessProps {
	snsLoginProcess:
	(
		snsRetValue: string,
		snsUidValue: string,
		snsCdValue: string,
		snsEmailValue: string,
		snsNameValue: string,
		snsReturnUrlValue: string,
		snsLoginIdValue: string,
		regDtValue: string,
		snsIsRegister?: string,
		memberKind?: `M` | `N`,
	) => void;
}

interface LoginSnsAreaProps {
	hidden?: boolean;
	isRegister?: string;
	loginId?: string;
	memberKind?: `M` | `N`;
	regDt?: string;
	ret?: string;
	returnUrl?: string;
	snsCd?: string;
	snsEmail?: string;
	snsName?: string;
	uid?: string;
}

const LoginSnsArea = ({
	hidden = false,
	returnUrl = getLegacyServerUrl(),
	ret,
	uid,
	snsCd,
	snsEmail,
	snsName,
	loginId,
	regDt,
	isRegister,
	memberKind
}: LoginSnsAreaProps) => {
	const {
		t
	} = useTranslation()

	const router = useRouter()
	const layerpopup = useLayerpopup()

	const [
		popupData,
		setPopupData
	] = useState<LoginSnsAreaPopupDataProps>({
		email: ``,
		loginId: ``,
		name: ``,
		regDt: ``,
		ret: ``,
		returnUrl: ``,
		snsCd: ``,
		snsEmail: ``,
		uid: ``
	})

	const [
		urls,
		setUrls
	] = useState<LoginSnsAreaViewUrlsProps>({
		failureUri: getHostCheckedUrl(pathsData.loginSns),
		redirectUri: returnUrl,
		successUri: getHostCheckedUrl(pathsData.loginSns)
	})

	if (typeof window !== `undefined`) {
		(window.snsLoginProcess as snsLoginProcessProps[`snsLoginProcess`]) = (
			snsRetValue,
			snsUidValue,
			snsCdValue,
			snsEmailValue,
			snsNameValue,
			snsReturnUrlValue,
			snsLoginIdValue,
			regDtValue,
			snsIsRegister,
			snsMemberKind
		) => {
			if (snsRetValue === `0`) {
				addCookie(`myinfoauth=`, 0)

				window.localStorage.setItem(`ga-membercheck`, snsMemberKind === `M` ? `Y` : `N`)

				if (snsIsRegister === `Y`) {
					addCookie(`next_page_popup=snsregistereventpopup_:::_0_:::_0`, 1)
				}

				router.push(snsReturnUrlValue)
			} else if (snsRetValue === `3` || snsRetValue === `-21`) {
				setPopupData({
					...popupData,
					email: snsEmailValue,
					name: snsNameValue,
					ret: snsRetValue,
					returnUrl: snsReturnUrlValue,
					snsCd: snsCdValue,
					snsEmail: snsEmailValue,
					uid: snsUidValue
				})

				layerpopup.open({
					id: `layerpopup_noemail`
				})
			} else if (snsRetValue === `2`) {
				setPopupData({
					...popupData,
					email: snsEmailValue,
					loginId: snsLoginIdValue,
					name: snsNameValue,
					regDt: regDtValue,
					ret: snsRetValue,
					returnUrl: snsReturnUrlValue,
					snsCd: snsCdValue,
					snsEmail: snsEmailValue,
					uid: snsUidValue
				})

				layerpopup.open({
					id: `layerpopup_haspopup`
				})
			} else {
				layerpopup.open({
					id: `layerpopup-alert`,
					text: t(`입력한 정보로 로그인할 수 없습니다. ID와 비밀번호를 확인하세요.`)
				})
			}
		}
	}

	useEffect(() => {
		if (urls.redirectUri !== returnUrl) {
			setUrls({
				...urls,
				redirectUri: returnUrl
			})
		}

		if (hidden === true && ret !== undefined) {
			const tempSnsBeforeLoginCookieName = `tempBeforeLoginChannel=`

			if (document.cookie.match(tempSnsBeforeLoginCookieName) !== null) {
				addCookie(`beforeLoginChannel=${document.cookie.split(tempSnsBeforeLoginCookieName)[1]?.split(`;`)[0]}`, 365)

				addCookie(`tempBeforeLoginChannel=`, 0)
			}

			if (
				typeof window !== `undefined` &&
				typeof window.opener !== `undefined` &&
				typeof (window.opener as {
					snsLoginProcess: snsLoginProcessProps[`snsLoginProcess`];
				}).snsLoginProcess !== `undefined`
			) {
				(window.opener as {
					snsLoginProcess: snsLoginProcessProps[`snsLoginProcess`];
				})
					.snsLoginProcess(ret || ``, uid ?? ``, snsCd ?? ``, snsEmail ?? ``, snsName ?? ``, decodeURIComponent(returnUrl || ``), loginId ?? ``, regDt ?? ``, isRegister ?? ``, memberKind ?? `M`)

				window.close()
			}
		}
	}, [
		hidden,
		loginId,
		regDt,
		ret,
		returnUrl,
		snsCd,
		snsEmail,
		snsName,
		uid,
		isRegister,
		memberKind,
		urls
	])

	const props = {
		hidden,
		popupData,
		urls
	}

	return (
		<LoginSnsAreaView
			{...props}
		/>
	)
}

export default LoginSnsArea
