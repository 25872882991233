'use client'

import Layerpopup from '@dg/common/components/Layerpopup'
import Link from '@dg/common/components/Link'
import HeaderRightSearchLayerCategory from '@dg/common/components/Mobile/Header/HeaderView/HeaderRightSearchLayer/HeaderRightSearchLayerView/HeaderRightSearchLayerCategory'
import HeaderRightSearchLayerKeyword from '@dg/common/components/Mobile/Header/HeaderView/HeaderRightSearchLayer/HeaderRightSearchLayerView/HeaderRightSearchLayerKeyword'
import HeaderRightSearchLayerRecentKeyword from '@dg/common/components/Mobile/Header/HeaderView/HeaderRightSearchLayer/HeaderRightSearchLayerView/HeaderRightSearchLayerRecentKeyword'
import HeaderRightSearchLayerSearch from '@dg/common/components/Mobile/Header/HeaderView/HeaderRightSearchLayer/HeaderRightSearchLayerView/HeaderRightSearchLayerSearch'
import useImageValidate from '@dg/common/lib/hooks/useImageValidate'
import imageData from '@dg/common/public/json/imageData'
import Image from 'next/image'
import type {
	SearchMobilePopupApiProps
} from '@dg/common/lib/mobile/api'

interface HeaderRightSearchLayerViewProps {
	data?: SearchMobilePopupApiProps[`result`];
	loaded: boolean;
}

const HeaderRightSearchLayerView = ({
	data, loaded
}: HeaderRightSearchLayerViewProps) => {
	const imageValidate = useImageValidate()

	return (
		<Layerpopup
			id="layerpopup-searchlayer"
			wrapZIndex="z-150"
			full
		>
			<article
				className="space-y-2.5"
			>
				<HeaderRightSearchLayerSearch />

				{
					loaded === true && data?.banner && (
						<Link
							className="!-mt-2.5 block"
							href={data.banner.link}
						>
							<Image
								alt={data.banner.title}
								className="object-cover"
								height={160}
								onError={imageValidate.onError}
								placeholder={imageData.emptyImage}
								sizes="100vw"
								src={data.banner.src}
								width={750}
							/>
						</Link>
					)
				}

				{
					data?.hot_keywords && (
						<HeaderRightSearchLayerKeyword
							list={data.hot_keywords}
						/>
					)
				}

				<HeaderRightSearchLayerRecentKeyword />

				{
					loaded === true && data?.categories && (
						<HeaderRightSearchLayerCategory
							list={data.categories}
						/>
					)
				}
			</article>
		</Layerpopup>
	)
}

export default HeaderRightSearchLayerView
