'use client'

import {
	addHyphen
} from '@dg/common/lib/common'
import {
	FormEvent, useCallback, useMemo
} from 'react'
import {
	useTranslation
} from 'react-i18next'

const usePhoneValidate = () => {
	const {
		t
	} = useTranslation()

	const data = useMemo(() => ({
		beforeValue: ``,
		text: ``,
		validValue: ``,
		value: ``
	}), [])

	const chkFunc = useCallback((value: string, nation?: string) => {
		const defaultValue = value.split(`-`).join(``).replace(/[A-z]/gim, ``)
		let setValue = Number.isNaN(Number(defaultValue)) === false ? defaultValue : parseInt(defaultValue, 10).toString()

		if (setValue === `NaN`) {
			setValue = ``
		}

		const nationSgHk = nation === `SG` || nation === `65` || nation === `HK` || nation === `852`
		const nationId = nation === `ID` || nation === `62`
		const nationKr = nation === `KR` || nation === `82`
		const nationCa = nation === `1-CA`
		const nationJp = nation === `JP` || nation === `81`
		const nationBe = nation === `BE` || nation === `32`
		const beStartWith04 = nationBe === true && setValue.startsWith(`04`) === true
		const beStartNotWith04 = nationBe === true && setValue.startsWith(`04`) === false

		let validLength = nationSgHk === true ? 8 : 13

		if (nationCa === true || beStartWith04 === true) {
			validLength = 10
		} else if (beStartNotWith04 === true) {
			validLength = 9
		}

		let validValue = Array.from(setValue).slice(0, validLength).join(``)

		const jpstartWith2 = nationJp === true && (validValue.startsWith(`03`) || validValue.startsWith(`06`))

		const nationMyCn =
			nation === `MY` || nation === `60` ||
			nation === `CN` || nation === `86`

		const originalValueChk1 =
			nationJp === false &&
			nationMyCn === false &&
			nationKr === false &&
			nationId === false &&
			setValue.length > 7

		const originalValueChk2 = nationId === true && setValue.length > 10 && setValue.length < 13
		const originalValueChk3 = nationKr === true && setValue.length === 11
		const originalValueChk4 = nationMyCn === true && setValue.length > 9 && setValue.length < 12

		const originalValueChk5 =
			nationJp === true &&
			(validValue.startsWith(`03`) === true || validValue.startsWith(`06`) === true) &&
			setValue.length === 10

		const originalValueChk6 =
			nationJp === true && (validValue.startsWith(`03`) === false && validValue.startsWith(`06`) === false)

		const originalValueChk7 = originalValueChk6 === true && setValue.length === 10
		const originalValueChk8 = originalValueChk6 === true && (setValue.length > 9 && setValue.length < 12)

		const originalValueChk9 = beStartWith04 === true && setValue.length === 10
		const originalValueChk10 = beStartNotWith04 === true && setValue.length === 9

		data.value = value.replace(/[A-z]/gim, ``)

		let firstLength = 5

		if (jpstartWith2 === true) {
			firstLength = 2
		} else if (nationBe === false) {
			if (validValue.length === 12) {
				firstLength = 4
			} else if (validValue.length < 12) {
				firstLength = 3
			}
		}

		let secondLength = 4

		if (nationCa === true || originalValueChk7 === true) {
			secondLength = 3
		} else if (beStartNotWith04 === true) {
			secondLength = 2
		}

		let thirdLength = 4

		if (beStartNotWith04 === true) {
			thirdLength = 2
		}

		let addHyphenArray = [
			firstLength,
			secondLength,
			thirdLength
		]

		if (nationSgHk === true) {
			addHyphenArray = [
				4,
				4
			]
		} else if (beStartWith04 === true) {
			addHyphenArray = [
				4,
				2,
				2,
				2
			]
		}

		validValue = addHyphen(validValue, addHyphenArray)

		let text = ``

		if (
			setValue.length > 0 &&
			originalValueChk1 === false &&
			originalValueChk2 === false &&
			originalValueChk3 === false &&
			originalValueChk4 === false &&
			originalValueChk5 === false &&
			originalValueChk8 === false &&
			originalValueChk9 === false &&
			originalValueChk10 === false
		) {
			text = t(`휴대폰 번호의 자릿수를 확인합니다.`)
		}

		data.validValue = validValue

		return text
	}, [
		data,
		t
	])

	const onChange = useCallback((event: FormEvent, nation?: string) => {
		const obj = event.target as HTMLInputElement
		const value = obj.value

		data.text = chkFunc(value || ``, nation)
	}, [
		chkFunc,
		data
	])

	const validate = useCallback((value: string, nation?: string) => {
		data.text = chkFunc(value || ``, nation)

		return data.text
	}, [
		chkFunc,
		data
	])

	return {
		onChange,
		result: data,
		validate
	}
}

export default usePhoneValidate
