'use client'

import {
	classNameFunc
} from '@dg/common/lib/common'
import useImageValidate from '@dg/common/lib/hooks/useImageValidate'
import pathsData from '@dg/common/public/json/pc/paths'
import LoginRewardLiView from 'member/components/Pc/Login/LoginView/LoginReward/LoginRewardView/LoginRewardLiView'
import Image from 'next/image'
import {
	useTranslation
} from 'react-i18next'

const LoginRewardView = () => {
	const {
		t
	} = useTranslation()

	const imageValidate = useImageValidate()

	const ulClassName = classNameFunc(`mt-2 flex space-x-2.5 text-xs text-[#517fef]`)

	return (
		<div
			className="mx-auto mt-17.5 w-pc"
		>
			<h3
				className={
					classNameFunc(`
						relative flex items-center whitespace-nowrap text-sm font-bold text-[#517fef] transition-colors
						after:ml-1 after:h-px after:w-full after:border-t after:border-dotted after:border-[#a7a7a7]
					`)
				}
			>
				<div
					className="relative mr-1 flex"
				>
					<Image
						alt="Dot Image"
						height={17}
						onError={imageValidate.onError}
						src="/images/pc/login/bottom-title-dot.png"
						width={17}
					/>
				</div>

				{t(`Qoo10 회원 혜택`)}
			</h3>

			<ul
				className="mt-4 flex justify-center space-x-5 text-sm"
			>
				<li
					className={
						classNameFunc(`
							relative pr-5 after:absolute after:bottom-0 after:right-0 after:h-13 after:w-px
							after:border-r after:border-dotted after:border-[#a7a7a7]
						`)
					}
				>
					<h4
						className="font-bold transition-colors"
					>
						{t(`Qoo10으로 운다메시!`)}
					</h4>

					<ul
						className={ulClassName}
					>
						<LoginRewardLiView
							image="/images/pc/login/clover.png"
							link={pathsData.timeSale}
						>
							{t(`타임 세일`)}
						</LoginRewardLiView>

						<LoginRewardLiView
							image="/images/pc/login/clock.png"
							link={pathsData.dailyDeal}
						>
							{t(`오늘의 세일`)}
						</LoginRewardLiView>

						<LoginRewardLiView
							image="/images/pc/login/star.png"
							link={pathsData.bestseller}
						>
							{t(`랭킹`)}
						</LoginRewardLiView>
					</ul>
				</li>

				<li>
					<h4
						className="font-bold transition-colors"
					>
						{t(`쇼핑 & 혜택`)}
					</h4>

					<ul
						className={ulClassName}
					>
						<LoginRewardLiView
							html={t(`지금 사용할 수있는 유익한 쿠폰`)}
							image="/images/pc/login/bg-cash.png"
							link={`${process.env.legacyPcUrl}/gmkt.inc/Special/Special.aspx?sid=161579`}
							type="bg"
						/>

						<LoginRewardLiView
							html={t(`매일 즐길 수 있는 Q 라운지`)}
							image="/images/pc/login/bg-roulette.png"
							link={pathsData.qchance}
							type="bg"
						/>

						<LoginRewardLiView
							html={t(`리뷰를 쓰고 Q 스탬프 1장`)}
							image="/images/pc/login/bg-pen.png"
							link={pathsData.qchance}
							type="bg"
						/>
					</ul>
				</li>
			</ul>
		</div>
	)
}

export default LoginRewardView
