interface FormDataProps {
	email: {
		link: string;
		name: string;
	}[];
	phone: {
		fullname: string;
		jpfullname: string;
		name: string;
		value: number;
	}[];
}

const formData: FormDataProps = {
	email: [
		{
			link: `https://mail.google.com/mail/u/0/?GO_FRONT_SAFARI#inbox`,
			name: `gmail.com`
		},
		{
			link: `https://login.yahoo.co.jp/config/login?GO_FRONT_SAFARI`,
			name: `yahoo.co.jp`
		},
		{
			link: `https://login.live.com`,
			name: `hotmail.com`
		},
		{
			link: `https://login.live.com`,
			name: `hotmail.co.jp`
		},
		{
			link: `https://login.live.com`,
			name: `live.jp`
		},
		{
			link: `https://login.live.com`,
			name: `outlook.com`
		},
		{
			link: `https://www.icloud.com/?GO_FRONT_SAFARI`,
			name: `icloud.com`
		},
		{
			link: `https://login.aol.com/?GO_FRONT_SAFARI`,
			name: `aol.jp`
		},
		{
			link: `https://mail.smt.docomo.ne.jp/?GO_FRONT_SAFARI`,
			name: `docomo.ne.jp`
		},
		{
			link: `https://www.softbank.jp/mysoftbank/?GO_FRONT_SAFARI`,
			name: `i.softbank.jp`
		},
		{
			link: `https://www.au.com/mobile/service/webmail/login/?GO_FRONT_SAFARI`,
			name: `ezweb.ne.jp`
		}
	],
	phone: [
		{
			fullname: `Australia`,
			jpfullname: `オーストラリア`,
			name: `AU`,
			value: 61
		},
		{
			fullname: `Canada`,
			jpfullname: `カナダ`,
			name: `CA`,
			value: 1
		},
		{
			fullname: `China`,
			jpfullname: `中国`,
			name: `CN`,
			value: 86
		},
		{
			fullname: `France`,
			jpfullname: `フランス`,
			name: `FR`,
			value: 33
		},
		{
			fullname: `Hong Kong`,
			jpfullname: `香港`,
			name: `HK`,
			value: 852
		},
		{
			fullname: `Indonesia`,
			jpfullname: `インドネシア`,
			name: `ID`,
			value: 62
		},
		{
			fullname: `Italy`,
			jpfullname: `イタリア`,
			name: `IT`,
			value: 39
		},
		{
			fullname: `Japan`,
			jpfullname: `日本`,
			name: `JP`,
			value: 81
		},
		{
			fullname: `Malaysia`,
			jpfullname: `マレーシア`,
			name: `MY`,
			value: 60
		},
		{
			fullname: `New Zealand`,
			jpfullname: `ニュージーランド`,
			name: `NZ`,
			value: 64
		},
		{
			fullname: `Philippines`,
			jpfullname: `フィリピン`,
			name: `PH`,
			value: 63
		},
		{
			fullname: `Poland`,
			jpfullname: `ポーランド`,
			name: `PL`,
			value: 48
		},
		{
			fullname: `Russia`,
			jpfullname: `ロシア`,
			name: `RU`,
			value: 7
		},
		{
			fullname: `Singapore`,
			jpfullname: `シンガポール`,
			name: `SG`,
			value: 65
		},
		{
			fullname: `South Korea`,
			jpfullname: `韓国`,
			name: `KR`,
			value: 82
		},
		{
			fullname: `Switzerland`,
			jpfullname: `スイス`,
			name: `CH`,
			value: 41
		},
		{
			fullname: `Taiwan`,
			jpfullname: `台湾`,
			name: `TW`,
			value: 886
		},
		{
			fullname: `Thailand`,
			jpfullname: `タイ`,
			name: `TH`,
			value: 66
		},
		{
			fullname: `United Kingdom`,
			jpfullname: `イギリス`,
			name: `GB`,
			value: 44
		},
		{
			fullname: `United States`,
			jpfullname: `アメリカ`,
			name: `US`,
			value: 1
		},
		{
			fullname: `Vietnam`,
			jpfullname: `ベトナム`,
			name: `VN`,
			value: 84
		},
		{
			fullname: `Belgium`,
			jpfullname: `ベルギー`,
			name: `BE`,
			value: 32
		},
		{
			fullname: `Brazil`,
			jpfullname: `ブラジル`,
			name: `BR`,
			value: 55
		},
		{
			fullname: `Brunei Darussalam`,
			jpfullname: `ブルネイ`,
			name: `BN`,
			value: 673
		},
		{
			fullname: `Bulgaria`,
			jpfullname: `ブルガリア`,
			name: `BG`,
			value: 359
		},
		{
			fullname: `Denmark`,
			jpfullname: `デンマーク`,
			name: `DK`,
			value: 45
		},
		{
			fullname: `Egypt`,
			jpfullname: `エジプト`,
			name: `EG`,
			value: 20
		},
		{
			fullname: `Finland`,
			jpfullname: `フィンランド`,
			name: `FI`,
			value: 358
		},
		{
			fullname: `Germany`,
			jpfullname: `ドイツ`,
			name: `DE`,
			value: 49
		},
		{
			fullname: `Greece`,
			jpfullname: `ギリシャ`,
			name: `GR`,
			value: 30
		},
		{
			fullname: `Hungary`,
			jpfullname: `ハンガリー`,
			name: `HU`,
			value: 36
		},
		{
			fullname: `India`,
			jpfullname: `インド`,
			name: `IN`,
			value: 91
		},
		{
			fullname: `Israel`,
			jpfullname: `イスラエル`,
			name: `IL`,
			value: 972
		},
		{
			fullname: `Kuwait`,
			jpfullname: `クウェート`,
			name: `KW`,
			value: 965
		},
		{
			fullname: `Macau`,
			jpfullname: `マカオ`,
			name: `MO`,
			value: 853
		},
		{
			fullname: `Mexico`,
			jpfullname: `メキシコ`,
			name: `MX`,
			value: 52
		},
		{
			fullname: `Myanma`,
			jpfullname: `ミャンマー`,
			name: `MM`,
			value: 95
		},
		{
			fullname: `Netherlands`,
			jpfullname: `オランダ`,
			name: `NL`,
			value: 31
		},
		{
			fullname: `Norway`,
			jpfullname: `ノルウェー`,
			name: `NO`,
			value: 47
		},
		{
			fullname: `Portugal`,
			jpfullname: `ポルトガル`,
			name: `PT`,
			value: 351
		},
		{
			fullname: `Spain`,
			jpfullname: `スペイン`,
			name: `ES`,
			value: 34
		},
		{
			fullname: `Sri Lanka`,
			jpfullname: `スリランカ`,
			name: `LK`,
			value: 94
		},
		{
			fullname: `Sweden`,
			jpfullname: `スウェーデン`,
			name: `SE`,
			value: 46
		},
		{
			fullname: `Turkey`,
			jpfullname: `トルコ`,
			name: `TR`,
			value: 90
		}
	]
}

export default formData
