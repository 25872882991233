'use client'

import Link from '@dg/common/components/Link'
import {
	classNameFunc
} from '@dg/common/lib/common'
import {
	SearchPopularApiProps
} from '@dg/common/lib/pc/api'
import {
	FocusEvent, MouseEvent, useCallback
} from 'react'

interface HeaderSearchLayerUlViewProps {
	list: SearchPopularApiProps[`result`];
	searchRelatedItemApiFunc: (event: FocusEvent | MouseEvent, keyword?: string) => void;
	title?: string;
}

const HeaderSearchLayerUlView = ({
	list, searchRelatedItemApiFunc, title
}: HeaderSearchLayerUlViewProps) => {
	const unicodeToChar = useCallback((text: string) => text.replace(/%/gim, `\\`).replace(/\\u[\dA-F]{4}/gim, (match) => String.fromCharCode(parseInt(match.replace(/\\u/g, ``), 16))), [])

	return (
		<>
			{
				title !== undefined && (
					<strong
						className="flex h-6 w-full items-center bg-[#eceff2] px-3"
					>
						{title}
					</strong>
				)
			}

			<ul
				className="w-3/5"
			>
				{
					list.map(({
						keyword, originalKeyword
					}, index) => {
						const originalValue =
							unicodeToChar(originalKeyword ?? keyword)

						return (
							<li
								key={Object.keys(list)[index]}
								className="relative flex h-6 items-center"
							>
								<Link
									href={`${process.env.legacyPcUrl}/s/${encodeURIComponent(originalValue).toUpperCase()}?keyword=${originalValue}`}
									className={
										classNameFunc(`
											size-full truncate px-3 leading-6 transition-none hover:bg-[#527fef]
											hover:text-dg-white hover:after:absolute hover:after:left-full
											hover:after:top-0 hover:after:block hover:after:size-0
											hover:after:border-[0.75rem] hover:after:border-solid
											hover:after:border-transparent hover:after:border-l-[#527fef]
											dark:hover:text-dgdark-white
										`)
									}
									dangerouslySetInnerHTML={
										{
											__html: unicodeToChar(keyword)
										}
									}
									onMouseOver={
										(event) => {
											if (searchRelatedItemApiFunc !== undefined) {
												searchRelatedItemApiFunc(event, originalValue)
											}
										}
									}
								/>
							</li>
						)
					})
				}
			</ul>
		</>
	)
}

export default HeaderSearchLayerUlView
