'use client'

import {
	tooltipData,
	type TooltipValueProps
} from '@dg/common/lib/store'
import {
	useAtom
} from 'jotai'
import {
	useCallback
} from 'react'

const useTooltip = () => {
	const [
		tooltip,
		setTooltip
	] = useAtom(tooltipData)

	const get = useCallback(() => tooltip, [
		tooltip
	])

	const set = useCallback(({
		id = ``, open = false, target, title = ``, text = ``, transition = true
	}: TooltipValueProps) => {
		const setData = tooltip

		setData[id] = {
			id,
			open,
			target,
			text,
			title,
			transition
		}

		setTooltip(setData)
	}, [
		setTooltip,
		tooltip
	])

	const open = useCallback(({
		id = ``, target, title = ``, text = ``, transition = true
	}: TooltipValueProps) => {
		const setData = tooltip

		if (setData[id] !== undefined) {
			setData[id] = {
				...setData[id],
				open: true,
				target,
				text: text !== `` ? text : setData[id].text,
				title: title !== `` ? title : setData[id].title,
				transition
			}

			setTooltip(setData)
		}
	}, [
		setTooltip,
		tooltip
	])

	const close = useCallback((id = ``, transition = true) => {
		const setData = tooltip

		if (setData[id] !== undefined) {
			setData[id] = {
				...setData[id],
				open: false,
				transition
			}

			setTooltip(setData)
		}
	}, [
		setTooltip,
		tooltip
	])

	return {
		close,
		get,
		open,
		set
	}
}

export default useTooltip
