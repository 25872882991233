'use client'

import {
	CaptchaApiProps, logoutApi
} from '@dg/common/lib/api'
import useLogin from '@dg/common/lib/hooks/useLogin'
import useSetApi from '@dg/common/lib/hooks/useSetApi'
import useTooltip from '@dg/common/lib/hooks/useTooltip'
import {
	gaDimensionFunc, getLegacyServerUrl
} from '@dg/common/lib/qoo10Common'
import pathsData from '@dg/common/public/json/pc/paths'
import {
	useQueryClient
} from '@tanstack/react-query'
import {
	loginInfoType, LoginPageProps
} from "member/components/Pc/Login/LoginType"
import LoginView from "member/components/Pc/Login/LoginView/LoginView"
import {
	loginApi, LoginApiProps
} from "member/lib/api"
import {
	useRouter
} from 'next/navigation'
import React, {
	useEffect, useMemo, useRef, useState
} from 'react'
import {
	useTranslation
} from 'react-i18next'

export interface OAuth2LoginInfo {
	mobile_device_token: string;
	sns_cd: string;
	sns_email: string;
	sns_gender: string;
	sns_name: string;
	uid: string;
}

interface loginErrorProps {
	code: number;
	message: string;
	result: {
		custNm?: string;
		custNo: string;
		email?: string;
		loginId?: string;
		oauth2LoginReqDTO?: OAuth2LoginInfo;
		regDt?: string;
		rememberMe: string;
	};
}

const Login = ({
	isAdult, isGuestAfterGoodsLogin, isNoLoginQuestion = false, loginId, orderUrl = ``, seller,
	returnUrl = getLegacyServerUrl()
}: LoginPageProps) => {
	const {
		t
	} = useTranslation()

	const router = useRouter()
	const login = useLogin()
	const queryClient = useQueryClient()
	const tooltip = useTooltip()

	const data = useMemo(() => ({
		loaded: false
	}), [])

	const RefLoginInfo = useRef<loginInfoType>({
		checkbox: true,
		country: `81`,
		email: loginId ?? ``,
		loginId: ``,
		password: ``,
		phoneNumber: ``,
		refreshCount: 0
	})

	const [
		memberType,
		setMemberType
	] = useState(`member`)

	const [
		loginType,
		setLoginType
	] = useState<`id` | `phone`>(`id`)

	// 얼럿 메시지
	const [
		alertMsg,
		setAlertMsg
	] = useState <string>(``)

	const snsLoginRetCodeMap = {
		ALREADY_REGISTERED: 2,
		NAME_OR_EMAIL_IS_BLANK: 3,
		NOT_FOUND_USER_AND_NAME_OR_EMAIL_IS_BLANK: -21
	}

	const reGenerateUrl =
		returnUrl.match(/https/) !== null && returnUrl.match(/qoo10.jp/) === null ?
			pathsData.home :
			returnUrl

	const loginProcess = useSetApi({
		api: loginApi,
		errorCallback: (error) => {
			if (typeof error !== `string` && typeof error.response !== `undefined`) {
				const errorData = error.response?.data as loginErrorProps

				RefLoginInfo.current.refreshCount += 1
				RefLoginInfo.current.password = ``

				if (errorData.message === `INVALID_CAPTCHA`) {
					setAlertMsg(t(`Captcha가 틀렸습니다.`) || ``)
				} else if (errorData.message === `PENDING_LOGIN_BY_HP_NO`) {
					router.push(`/pc/login/phoneauth?enc_cust_no=${encodeURIComponent(errorData.result.custNo)}&ReturnUrl=${reGenerateUrl}`)
				} else if (errorData.message === `BLACKLIST`) {
					router.push(`/pc/login/memberconfirm?enc_cust_no=${encodeURIComponent(errorData.result.custNo)}&ReturnUrl=${reGenerateUrl}`)
				} else if (errorData.message === `NOT_CONFIRMED`) {
					router.push(`/pc/join/email?emailvalue=${encodeURIComponent(errorData.result.custNo)}&returnurl=${reGenerateUrl}`)
				} else if (errorData.message === `SELLER_PRIMARY_ID`) {
					router.push(`/pc/login/checkprimaryid?enc_cust_no=${encodeURIComponent(errorData.result.custNo)}&ReturnUrl=${reGenerateUrl}`)
				} else if (
					errorData.message === `NAME_OR_EMAIL_IS_BLANK` ||
					errorData.message === `ALREADY_REGISTERED` ||
					errorData.message === `NOT_FOUND_USER_AND_NAME_OR_EMAIL_IS_BLANK`
				) {
					const ret = snsLoginRetCodeMap[errorData.message]
					const uid = errorData.result.oauth2LoginReqDTO?.uid ?? ``
					const snsCd = errorData.result.oauth2LoginReqDTO?.sns_cd ?? ``
					const snsEmail = errorData.result.oauth2LoginReqDTO?.sns_email ?? errorData.result.email ?? ``
					const snsName = errorData.result.oauth2LoginReqDTO?.sns_name ?? errorData.result.custNm ?? ``
					const snsGender = errorData.result.oauth2LoginReqDTO?.sns_gender ?? ``
					const innerLoginId = errorData.result.loginId ?? ``
					const regDt = errorData.result.regDt ?? ``
					// eslint-disable-next-line @stylistic/max-len
					const url = `/mobile/login/sns?ret=${ret}&uid=${uid}&sns_cd=${snsCd}&sns_email=${snsEmail}&sns_name=${snsName}&sns_gender=${snsGender}&ReturnUrl=${returnUrl}&login_id=${innerLoginId}&reg_d=${regDt}`

					router.push(url)
				} else if (memberType === `member`) {
					setAlertMsg(`${t(`입력한 정보로 로그인할 수 없습니다. ID와 비밀번호를 확인하세요.`)}<br />${t(`sns정보에서 등록한 경우는 우측 아이콘으로 로그인해 주세요.`)}`)
				} else {
					setAlertMsg(t(`회원 등록되지 않고 쇼핑을 한 분은 【게스트】로 로그인하십시오. 구매 시 입력한 이메일 주소와 휴대폰 번호로 로그인할 수 있습니다.`))
				}
			}
		},
		successCallback: (successData: LoginApiProps) => {
			if (successData.code === 200) {
				gaDimensionFunc({
					appierCategory: `login`,
					event: true,
					url: window.location.href
				})

				login.set({
					flag: true
				})

				// gaDimensionFunc 이벤트 호출로 인해 setTimeout 설정
				setTimeout(() => {
					router.replace(reGenerateUrl || getLegacyServerUrl())
				}, 500)
			} else if (memberType === `member`) {
				setAlertMsg(t(`입력한 정보로 로그인할 수 없습니다. ID와 비밀번호를 확인하세요.`))
			} else {
				setAlertMsg(t(`회원 등록되지 않고 쇼핑을 한 분은 【게스트】로 로그인하십시오. 구매 시 입력한 이메일 주소와 휴대폰 번호로 로그인할 수 있습니다.`))
			}
		}
	})

	const logoutSetApi = useSetApi({
		api: logoutApi,
		successCallback: () => {
			login.set({
				flag: false,
				userData: ``
			})
		}
	})

	const onChangeLoginRadio = (id: string) => {
		if (tooltip.get()[`tooltip-loginstatus`] !== undefined && tooltip.get()[`tooltip-loginstatus`].open === true) {
			tooltip.close(`tooltip-loginstatus`)
		}

		setMemberType(id)
	}

	const onChangeToggle = (active: boolean) => {
		setLoginType(active ? `id` : `phone`)
	}

	/* FIX: 성인인증 완료 */
	const onConfirmAdult = () => {
		if (returnUrl) {
			router.replace(returnUrl)
		}
	}

	// 로그인 버튼 눌렀을 때 동작
	const onLoginButtonClick = (event: React.FormEvent) => {
		event.preventDefault()

		const formData = new URLSearchParams()

		const captcha = (event.target as HTMLFormElement).elements?.namedItem(`input-captcha`)
		let captchaValue = ``

		if (captcha) {
			captchaValue = (captcha as HTMLInputElement).value
		}

		const captchaData = queryClient.getQueryData<CaptchaApiProps>([
			`captchaDataApi`
		])?.result.q_captcha_req_no

		if (memberType === `member`) {
			if (loginType === `id` &&
				(!RefLoginInfo.current.email || RefLoginInfo.current.email.length === 0)) {
				setAlertMsg(t(`이메일 또는 ID를 입력하세요.`) || ``)

				return false
			}

			if (loginType === `phone` &&
				(!RefLoginInfo.current.phoneNumber || RefLoginInfo.current.phoneNumber.length === 0)) {
				setAlertMsg(t(`로그인용 휴대전화 번호를 입력하세요.`) || ``)

				return false
			}

			if (!RefLoginInfo.current.password || RefLoginInfo.current.password.length === 0) {
				setAlertMsg(t(`패스워드를 입력하세요.`) || ``)

				return false
			}

			if (captchaData && (!captcha || !captchaValue)) {
				setAlertMsg(t(`문자 인증이 필요합니다.`) || ``)

				return false
			}

			if (loginType === `id`) {
				formData.append(`login_id`, RefLoginInfo.current.email)
				formData.append(`member_kind`, `M`)
			} else if (loginType === `phone`) {
				formData.append(
					`login_id`,
					`+${RefLoginInfo.current.country}-${RefLoginInfo.current.phoneNumber.replace(/-/gi, ``)}`
				)
				formData.append(`member_kind`, `M`)
			}

			if (captcha && captchaData) {
				formData.append(`captcha_req_no`, captchaData)
				formData.append(`recaptcha_response_field`, captchaValue)
			}

			formData.append(`passwd`, RefLoginInfo.current.password)
		} else {
			if (!RefLoginInfo.current.email || RefLoginInfo.current.email.length === 0) {
				setAlertMsg(t(`{text}를 입력합니다.`).replace(/{text}/gim, t(`이메일 주소`)))

				return false
			}

			if (!RefLoginInfo.current.phoneNumber || RefLoginInfo.current.phoneNumber.length === 0) {
				setAlertMsg(t(`{text}를 입력합니다.`).replace(/{text}/gim, t(`휴대폰 번호`)))

				return false
			}

			formData.append(`login_id`, RefLoginInfo.current.email)
			formData.append(`passwd`, RefLoginInfo.current.phoneNumber.replaceAll(`-`, ``))
			formData.append(`member_kind`, `N`)
		}

		if (!formData.get(`login_id`) || formData.get(`login_id`)?.trim().length === 0) return false

		formData.append(`next_url`, returnUrl)
		formData.append(`remember_me`, RefLoginInfo.current.checkbox ? `Y` : `N`)

		if (memberType === `member` && (captchaData && (captcha as HTMLInputElement).value !== ``)) {
			(captcha as HTMLInputElement).value = ``
		}

		RefLoginInfo.current.loginId = formData.get(`login_id`) ?? ``

		gaDimensionFunc({
			data: {
				ep_event_area: `login`,
				ep_event_label: `login`,
				ep_event_page: `pc_login`,
				event_name: `click_event`
			},
			event: true,
			url: window.location.href
		})

		return loginProcess.set(formData)
	}

	useEffect(() => {
		if (data.loaded === false) {
			data.loaded = true

			if (seller === `true` && login.info === true) {
				logoutSetApi.set()
			}
		}
	}, [
		data,
		login.info,
		logoutSetApi,
		seller
	])

	return (
		<LoginView
			RefLoginInfo={RefLoginInfo}
			alertMsg={alertMsg}
			isAdult={isAdult}
			isGuestAfterGoodsLogin={isGuestAfterGoodsLogin}
			isNoLoginQuestion={isNoLoginQuestion}
			loginType={loginType}
			memberType={memberType}
			onChangeLoginRadio={onChangeLoginRadio}
			onChangeToggle={onChangeToggle}
			onConfirmAdult={onConfirmAdult}
			onLoginButtonClick={onLoginButtonClick}
			orderUrl={orderUrl}
			returnUrl={returnUrl}
			seller={seller}
		/>
	)
}

export default Login
