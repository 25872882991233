'use client'

import Link from '@dg/common/components/Link'
import ListImageView from '@dg/common/components/Pc/Common/List/ListView/ListImageView'
import ListPriceView from '@dg/common/components/Pc/Common/List/ListView/ListPriceView'
import ListStarView from '@dg/common/components/Pc/Common/List/ListView/ListStarView'
import ListTitleView from '@dg/common/components/Pc/Common/List/ListView/ListTitleView'
import {
	classNameFunc
} from '@dg/common/lib/common'
import {
	Fragment
} from 'react'

interface ListViewProps {
	bottom?: {
		price?: {
			finalPriceName: string;
			sellPriceName?: string;
		};
		star?: {
			starName: string;
		};
		title?: {
			className?: string;
			prependIconName?: string;
			titleName: string;
		};
	}[];
	className: string;
	liClassName: string;
	linkName: string;
	list: unknown[];
	top?: {
		height?: string;
		image?: {
			altName: string;
			imageName: string;
			imageType?: string; // gd-image
		};
		width?: string;
	};
	type: `horizon` | `vertical`;
}

const ListView = ({
	className, liClassName, linkName, list, type, top, bottom
}: ListViewProps) => (
	<ul
		className={`list relative ${className}`}
	>
		{
			(list as Record<string, string | number | boolean>[])
				.map((item, index) => (
					<li
						key={Object.keys(list)[index]}
						className={liClassName}
					>
						<div
							className={
								classNameFunc(`
									flex
									${type === `horizon` ? `space-x-2.5` : `flex-col space-y-2.5`}
								`)
							}
						>
							{
								top !== undefined && (
									<div
										className={
											classNameFunc(`
												flex-none border border-solid border-dg-black/10
												dark:border-dgdark-black/10
												${top.height ?? `h-55`}
												${top.width ?? `w-55`}
											`)
										}
									>
										<Link
											href={`${process.env.legacyPcUrl}/gmkt.inc/goods/goods.aspx?goodscode=${item[linkName]}`}
										>
											{
												top.image !== undefined && (
													<ListImageView
														image={top.image}
														item={item}
													/>
												)
											}
										</Link>
									</div>
								)
							}

							{
								bottom !== undefined && (
									<div
										className="flex-auto"
									>
										{
											bottom.map((bottomItem, bottomIndex) => (
												<Fragment
													key={Object.keys(bottom)[bottomIndex]}
												>
													{
														bottomItem.title !== undefined &&
														Object.keys(bottomItem)[0] === `title` && (
															<Link
																href={`${process.env.legacyPcUrl}/gmkt.inc/goods/goods.aspx?goodscode=${item[linkName]}`}
															>
																<ListTitleView
																	item={item}
																	title={bottomItem.title}
																/>
															</Link>
														)
													}

													{
														bottomItem.price !== undefined &&
														Object.keys(bottomItem)[0] === `price` && (
															<ListPriceView
																item={item}
																price={bottomItem.price}
															/>
														)
													}

													{
														bottomItem.star !== undefined &&
														Object.keys(bottomItem)[0] === `star` && (
															<ListStarView
																item={item}
																star={bottomItem.star}
															/>
														)
													}
												</Fragment>
											))
										}
									</div>
								)
							}
						</div>
					</li>
				))
		}
	</ul>
)

export default ListView
