'use client'

import CaptchaView from '@dg/common/components/Pc/Common/Captcha/CaptchaView/CaptchaView'
import {
	captchaApi, CaptchaApiProps
} from '@dg/common/lib/api'
import useGetApi from '@dg/common/lib/hooks/useGetApi'
import {
	UseQueryResult
} from '@tanstack/react-query'

interface CaptchaProps {
	alwaysUsed?: boolean;
	className?: string;
	contentWidth?: string;
	inputHeight?: string;
	loginId?: string;
	refreshCount?: number;
	titleWidth?: string;
}

const Captcha = ({
	className = ``,
	titleWidth = `w-32`,
	contentWidth = `w-60`,
	loginId,
	refreshCount = 0,
	alwaysUsed = false,
	inputHeight = `h-8`
}: CaptchaProps) => {
	const captchaDataApi = useGetApi({
		api: () => captchaApi(loginId?.startsWith(`+`) ? `` : loginId, alwaysUsed),
		gcTime: 300,
		name: `captchaDataApi`
	}) as UseQueryResult<CaptchaApiProps>

	const props = {
		alwaysUsed,
		captchaImageUrl: captchaDataApi.data?.result.q_captcha_image_url,
		captchaReqNo: captchaDataApi.data?.result.q_captcha_req_no,
		className,
		contentWidth,
		inputHeight,
		loginId,
		refreshCount,
		titleWidth,
		visible: captchaDataApi.data?.result.visible
	}

	return (
		<CaptchaView
			{...props}
		/>
	)
}

export default Captcha
