'use client'

import Button from '@dg/common/components/Button'
import Input from '@dg/common/components/Input'
import Skeleton from '@dg/common/components/Skeleton'
import {
	classNameFunc
} from '@dg/common/lib/common'
import useImageValidate from '@dg/common/lib/hooks/useImageValidate'
import {
	ArrowPathIcon
} from '@heroicons/react/24/outline'
import {
	useQueryClient
} from '@tanstack/react-query'
import Image from 'next/image'
import {
	useEffect, useMemo
} from 'react'
import {
	useTranslation
} from 'react-i18next'

interface CaptchaViewProps {
	alwaysUsed: boolean;
	captchaImageUrl?: string;
	captchaReqNo?: string;
	className: string;
	contentWidth: string;
	inputHeight: string;
	refreshCount: number;
	titleWidth: string;
	visible?: boolean;
}

const CaptchaView = ({
	className, titleWidth, contentWidth, refreshCount, alwaysUsed, inputHeight, captchaImageUrl, captchaReqNo, visible
}: CaptchaViewProps) => {
	const {
		t
	} = useTranslation()

	const queryClient = useQueryClient()
	const imageValidate = useImageValidate()

	const data = useMemo(() => ({
		refreshCount: 0
	}), [])

	useEffect(() => {
		if (refreshCount !== data.refreshCount) {
			data.refreshCount = refreshCount
		}
	}, [
		data,
		refreshCount
	])

	const captchaWrapClassName = classNameFunc(`flex space-x-2.5 ${className}`)

	const captchaTitleClassName =
		classNameFunc(`
			flex h-7.5 flex-none items-center text-sm font-normal transition-colors
			${titleWidth}
		`)

	const captchaContentClassName = classNameFunc(`flex flex-auto flex-col ${contentWidth}`)

	return (
		visible !== undefined ?
			(alwaysUsed === true || visible === true) && (
				<div
					className={captchaWrapClassName}
				>
					<div
						className={captchaTitleClassName}
					>
						{t(`문자 인증`)}
					</div>

					<div
						className={captchaContentClassName}
					>
						<div
							className="flex"
						>
							<div
								className="relative h-12.5 w-50"
							>
								{
									captchaImageUrl && (
										<Image
											alt="Captcha"
											className="object-contain"
											onError={imageValidate.onError}
											sizes="200px"
											src={captchaImageUrl}
											fill
										/>
									)
								}
							</div>

							<Button
								className={
									classNameFunc(`
										relative ml-2.5 flex size-5 items-center justify-center self-end rounded-md
										border border-solid border-dg-500 dark:border-dgdark-500
									`)
								}
								onClick={
									() => {
										queryClient.refetchQueries({
											queryKey: [
												`captchaDataApi`
											]
										})
									}
								}
							>
								<ArrowPathIcon
									className="w-3"
								/>

								<span
									className="sr-only"
								>
									Refresh Captcha
								</span>
							</Button>
						</div>

						<Input
							className="mt-4 flex-auto text-xs"
							data-value={captchaReqNo}
							height={inputHeight}
							id="input-captcha"
							name="captcha"
							placeholder={t(`이미지에 있는 문자를 입력합니다.`)}
							px
							rounded
						/>
					</div>
				</div>
			) :
			(
				<div
					className={captchaWrapClassName}
				>
					<div
						className={captchaTitleClassName}
					>
						<Skeleton
							className={captchaTitleClassName}
						/>
					</div>

					<div
						className={captchaContentClassName}
					>
						<Skeleton
							className="h-12.5 w-50"
						/>

						<Skeleton
							className={
								`
									mt-4 flex-auto
									${inputHeight}
								`
							}
						/>
					</div>
				</div>
			)
	)
}

export default CaptchaView
