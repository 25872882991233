'use client'

import ToastView from '@dg/common/components/Toast/ToastView/ToastView'
import useToast from '@dg/common/lib/hooks/useToast'
import {
	toastData
} from '@dg/common/lib/store'
import {
	useAtom
} from 'jotai'
import {
	PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState
} from 'react'

interface ToastProps {
	className?: string;
	closeBtn?: boolean;
	closeEventFunc?: () => void;
	defaultOpen?: boolean;
	duration?: number;
	height?: string;
	id: string;
	toastZIndex?: string;
	width?: string;
}

const Toast = ({
	children, id, className = ``, duration = 3000, toastZIndex = `z-200`, width = `w-max`, height = `h-auto`,
	defaultOpen = false, closeBtn = false,
	closeEventFunc
}: PropsWithChildren<ToastProps>) => {
	const toastHooks = useToast()

	const [
		storeToast,
		setStoreToast
	] = useAtom(toastData)

	if (storeToast[id] === undefined) {
		storeToast[id] = {
			id,
			open: defaultOpen,
			text: ``,
			transition: true
		}
	}

	const {
		open, transition
	} = storeToast[id]

	let {
		text
	} = storeToast[id]

	if (text === `` && children !== undefined) {
		text = children
	}

	const toastRef = useRef<HTMLDivElement>(null)

	const data = useMemo(() => ({
		openCloseDelayFunc: null as unknown as ReturnType<typeof setTimeout>,
		openDelayFunc: null as unknown as ReturnType<typeof setTimeout>
	}), [])

	const [
		pageData,
		setPageData
	] = useState({
		wrapHidden: true,
		wrapOpacity: true
	})

	const toastCloseFunc = useCallback(() => {
		const toast = {
			obj: toastRef.current
		}

		const {
			obj
		} = toast

		if (obj !== null) {
			setPageData({
				wrapHidden: false,
				wrapOpacity: true
			})

			const durationSec = parseFloat(window.getComputedStyle(obj).transitionDuration) * 1000
			const closeDuration = transition === true ? durationSec + 50 : 0

			clearTimeout(data.openCloseDelayFunc)
			clearTimeout(data.openDelayFunc)

			data.openCloseDelayFunc = setTimeout(() => {
				setPageData({
					wrapHidden: true,
					wrapOpacity: true
				})

				const setData = {
					...storeToast
				}

				if (setData[id].open === true) {
					setData[id] = {
						...setData[id],
						open: false
					}

					setStoreToast(setData)
				}

				if (closeEventFunc !== undefined) {
					closeEventFunc()
				}
			}, closeDuration)
		}
	}, [
		closeEventFunc,
		data,
		id,
		setStoreToast,
		storeToast,
		transition
	])

	const toastOpenFunc = useCallback(() => {
		const toast = {
			obj: toastRef.current
		}

		const {
			obj
		} = toast

		clearTimeout(data.openCloseDelayFunc)
		clearTimeout(data.openDelayFunc)

		if (obj !== null) {
			setPageData({
				wrapHidden: false,
				wrapOpacity: true
			})

			data.openDelayFunc = setTimeout(() => {
				setPageData({
					wrapHidden: false,
					wrapOpacity: false
				})

				data.openCloseDelayFunc = setTimeout(() => {
					toastCloseFunc()
				}, transition === true ? duration : 0)
			}, transition === true ? 10 : 0)
		}
	}, [
		data,
		duration,
		toastCloseFunc,
		transition
	])

	useEffect(() => {
		if (toastRef.current !== null) {
			if (toastHooks.get()[id] === undefined) {
				toastHooks.set({
					id
				})
			}

			if (open === true && pageData.wrapHidden === true && pageData.wrapOpacity === true) {
				toastOpenFunc()
			} else if (
				defaultOpen === false &&
				open === false &&
				pageData.wrapHidden === false &&
				pageData.wrapOpacity === false
			) {
				toastCloseFunc()
			}
		}
	}, [
		defaultOpen,
		id,
		open,
		pageData.wrapHidden,
		pageData.wrapOpacity,
		toastCloseFunc,
		toastHooks,
		toastOpenFunc
	])

	const props = {
		className,
		closeBtn,
		height,
		id,
		text,
		toastCloseFunc,
		toastRef,
		toastZIndex,
		transition,
		width,
		wrapHidden: pageData.wrapHidden,
		wrapOpacity: pageData.wrapOpacity
	}

	return (
		<ToastView
			{...props}
		/>
	)
}

export default Toast
