'use client'

import Button from '@dg/common/components/Button'
import Link from '@dg/common/components/Link'
import {
	classNameFunc
} from '@dg/common/lib/common'
import pathsData from '@dg/common/public/json/mobile/paths'
import {
	ChevronDownIcon
} from '@heroicons/react/24/outline'
import {
	useTranslation
} from 'react-i18next'
import type {
	SearchMobilePopupApiProps
} from '@dg/common/lib/mobile/api'

interface HeaderRightSearchLayerKeywordViewProps {
	list?: SearchMobilePopupApiProps[`result`][`hot_keywords`];
	open: boolean;
	selIndex: number;
	toggleBtnFunc: () => void;
}

const HeaderRightSearchLayerKeywordView = ({
	list, open, selIndex, toggleBtnFunc
}: HeaderRightSearchLayerKeywordViewProps) => {
	const {
		t
	} = useTranslation()

	return (
		list && (
			<div
				className={
					classNameFunc(`
						relative mt-4 flex overflow-hidden bg-[#fff4f8] px-4
						${open === false ? `h-15` : `flex-col pb-5`}
					`)
				}
			>
				<strong
					className="mr-7.5 flex h-15 flex-none items-center text-base font-bold"
				>
					{t(`인기 검색어`)}
				</strong>

				<ul
					className={open === false ? `flex-auto` : `mt-1`}
					style={
						{
							transform: `translate(0px, -${open === false ? selIndex * 60 : 0}px)`
						}
					}
				>
					{
						list
							.map((item, index) => (
								<li
									key={Object.keys(list)[index]}
									className={
										classNameFunc(`
											flex w-full items-center space-x-2.5 text-base
											${open === false ? `h-15` : `h-8.5`}
										`)
									}
								>
									<strong
										className="w-5 italic"
									>
										{index + 1}
									</strong>

									<Link
										className="relative flex size-full items-center"
										href={`${pathsData.searchListFull}/?keyword=${item.keyword}&kwclick=P`}
									>
										{item.keyword}
									</Link>
								</li>
							))
					}
				</ul>

				<Button
					className="absolute right-0 top-0 flex h-15 items-center justify-center px-4"
					onClick={toggleBtnFunc}
					transition={false}
				>
					<ChevronDownIcon
						className={
							classNameFunc(`
								size-5 rotate-0 transition-all
								${open === true ? `rotate-180` : ``}
							`)
						}
					/>

					<span
						className="sr-only"
					>
						Toggle Button
					</span>
				</Button>
			</div>
		)
	)
}

export default HeaderRightSearchLayerKeywordView
