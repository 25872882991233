'use client'

import {
	FormEvent, useCallback, useMemo
} from 'react'
import {
	useTranslation
} from 'react-i18next'

const usePasswordValidate = () => {
	const {
		t
	} = useTranslation()

	const data = useMemo(() => ({
		text: ``,
		validValue: ``,
		value: ``
	}), [])

	const chkFunc = useCallback((value: string) => {
		let text = ``

		if (value.length > 0) {
			if (value.length < 6 || value.length > 20) {
				text = t(`6자 이상 20자 이내의 반각 영숫자(혼용)로 입력해 주십시오.`)
			} else if ((/[A-z]/).test(value) === false || (/[0-9]/).test(value) === false) {
				text = t(`문자와 숫자를 함께 입력하십시오.`)
			}
		}

		return text
	}, [
		t
	])

	const onChange = useCallback((event: FormEvent) => {
		const obj = event.target as HTMLInputElement
		const value = obj.value

		if (value.length <= 20) {
			const validValue = Array.from(obj.value).slice(0, 20).join(``)

			data.text = chkFunc(validValue || ``)
			data.validValue = validValue
			data.value = value
		}
	}, [
		chkFunc,
		data
	])

	const validate = useCallback((value: string) => {
		data.text = chkFunc(value || ``)

		return data.text
	}, [
		chkFunc,
		data
	])

	return {
		onChange,
		result: data,
		validate
	}
}

export default usePasswordValidate
