'use client'

import TooltipView from '@dg/common/components/Tooltip/TooltipView/TooltipView'
import useTooltip from '@dg/common/lib/hooks/useTooltip'
import {
	tooltipData
} from '@dg/common/lib/store'
import {
	useAtom
} from 'jotai'
import {
	PropsWithChildren, ReactNode, useCallback, useEffect, useMemo, useRef, useState
} from 'react'

interface TooltipProps {
	border?: boolean;
	className?: string;
	closeEventFunc?: () => void;
	defaultOpen?: boolean;
	header?: boolean;
	height?: string;
	id: string;
	maxHeight?: string;
	rounded?: boolean;
	setTitle?: ReactNode;
	tooltipOffset?: boolean;
	tooltipZIndex?: string;
	width?: string;
}

const Tooltip = ({
	children, id, className = ``, setTitle = ``, header = true, rounded = false, border = false, tooltipOffset = false,
	tooltipZIndex = `z-110`, width = `w-max`, height = `h-auto`, maxHeight = `max-h-9/10`, defaultOpen = false,
	closeEventFunc
}: PropsWithChildren<TooltipProps>) => {
	const tooltipHooks = useTooltip()

	const [
		storeTooltip,
		setStoreTooltip
	] = useAtom(tooltipData)

	const tooltipRef = useRef<HTMLDivElement>(null)

	if (storeTooltip[id] === undefined) {
		storeTooltip[id] = {
			id,
			open: defaultOpen,
			target: null as unknown as HTMLElement,
			text: ``,
			title: ``,
			transition: true
		}
	}

	const {
		open, target, transition
	} = storeTooltip[id]

	let {
		title, text
	} = storeTooltip[id]

	if (title === `` && setTitle !== undefined) {
		title = setTitle
	}

	if (text === `` && children !== undefined) {
		text = children
	}

	const data = useMemo(() => ({
		openCloseDelayFunc: null as unknown as ReturnType<typeof setTimeout>,
		resizeDelayFunc: null as unknown as ReturnType<typeof setTimeout>
	}), [])

	const [
		pageData,
		setPageData
	] = useState({
		wrapHidden: true,
		wrapLeft: 0,
		wrapOpacity: true,
		wrapTop: 0
	})

	const tooltipPosition = useCallback(() => {
		const tooltip = {
			body: document.querySelector(`body`) as HTMLElement,
			layerpopupContent: tooltipRef.current?.closest(`.layerpopup-content`) as HTMLDivElement,
			left: 0,
			obj: tooltipRef.current as HTMLDivElement,
			padding: 5,
			top: 0,
			wrap: document.querySelector(`#wrap`) as HTMLDivElement
		}

		const {
			layerpopupContent, obj, padding, wrap, body
		} = tooltip

		let {
			top, left
		} = tooltip

		const scrollX = wrap.scrollLeft > 0 ? wrap.scrollLeft : window.scrollX
		const scrollY = wrap.scrollTop > 0 ? wrap.scrollTop : window.scrollY

		if (target !== undefined && target !== null) {
			top =
				layerpopupContent !== undefined && layerpopupContent !== null ?
					target.getBoundingClientRect().top - layerpopupContent.getBoundingClientRect().top +
					layerpopupContent.scrollTop + target.offsetHeight + padding :
					target.getBoundingClientRect().top + scrollY + target.offsetHeight + padding

			left =
				layerpopupContent !== undefined && layerpopupContent !== null ?
					target.getBoundingClientRect().left - layerpopupContent.getBoundingClientRect().left :
					target.getBoundingClientRect().left + scrollX

			if (tooltipOffset === true && layerpopupContent === null) {
				top = target.offsetTop + target.offsetHeight + padding
				left = target.offsetLeft
			}
		}

		const offsetW =
			layerpopupContent !== undefined && layerpopupContent !== null ?
				layerpopupContent.scrollWidth :
				body.offsetWidth

		const offsetH =
			layerpopupContent !== undefined && layerpopupContent !== null ?
				layerpopupContent.scrollHeight :
				body.offsetHeight

		if (obj !== undefined && obj !== null) {
			if (top + obj.offsetHeight + padding > offsetH) {
				top += offsetH - top - obj.offsetHeight - padding
			}

			if (left + obj.offsetWidth + padding - scrollX > offsetW) {
				left -= left + obj.offsetWidth + padding - offsetW - scrollX
			}
		}

		return {
			left,
			top
		}
	}, [
		tooltipOffset,
		target
	])

	const tooltipOpenFunc = useCallback(() => {
		const {
			left, top
		} = tooltipPosition()

		setPageData({
			wrapHidden: false,
			wrapLeft: left,
			wrapOpacity: true,
			wrapTop: top
		})

		clearTimeout(data.openCloseDelayFunc)
		data.openCloseDelayFunc = setTimeout(() => {
			setPageData({
				wrapHidden: false,
				wrapLeft: left,
				wrapOpacity: false,
				wrapTop: top
			})
		}, transition === true ? 10 : 0)
	}, [
		data,
		tooltipPosition,
		transition
	])

	const tooltipCloseFunc = useCallback(() => {
		const tooltip = {
			obj: tooltipRef.current
		}

		const {
			obj
		} = tooltip

		if (obj !== null) {
			setPageData({
				...pageData,
				wrapHidden: false,
				wrapOpacity: true
			})

			const durationSec = parseFloat(window.getComputedStyle(obj).transitionDuration) * 1000
			const duration = transition === true ? durationSec + 50 : 0

			clearTimeout(data.openCloseDelayFunc)

			data.openCloseDelayFunc = setTimeout(() => {
				setPageData({
					wrapHidden: true,
					wrapLeft: 0,
					wrapOpacity: true,
					wrapTop: 0
				})

				obj.style.opacity = ``
				obj.style.top = ``
				obj.style.left = ``

				const setData = {
					...storeTooltip
				}

				if (setData[id].open === true) {
					setData[id] = {
						...setData[id],
						open: false
					}

					setStoreTooltip(setData)
				}

				if (closeEventFunc !== undefined) {
					closeEventFunc()
				}
			}, duration)
		}
	}, [
		closeEventFunc,
		data,
		id,
		pageData,
		setStoreTooltip,
		storeTooltip,
		transition
	])

	useEffect(() => {
		if (tooltipRef.current !== null) {
			if (tooltipHooks.get()[id] === undefined) {
				tooltipHooks.set({
					id
				})
			}

			const {
				left, top
			} = tooltipPosition()

			const openChk1 = pageData.wrapHidden === true && pageData.wrapOpacity === true

			if (
				(defaultOpen === true || target !== null) && open === true &&
				(openChk1 === true || (left !== pageData.wrapLeft || top !== pageData.wrapTop))
			) {
				tooltipOpenFunc()
			} else if (
				defaultOpen === false &&
				open === false &&
				pageData.wrapHidden === false &&
				pageData.wrapOpacity === false
			) {
				tooltipCloseFunc()
			}

			for (const item of tooltipRef.current.querySelectorAll(`.btn-close`)) {
				item.addEventListener(`click`, tooltipCloseFunc)
			}
		}

		const resizeObj = (tooltipRef.current ?? document.querySelector(`body`)) as HTMLElement
		const resizeWrapObj = document.querySelector(`#wrap`) as HTMLElement

		const resizeFunc = () => {
			tooltipOpenFunc()
		}

		let beforeWidth = resizeObj.offsetWidth
		let beforeWrapWidth = resizeWrapObj.offsetWidth

		const resizeObserver = new ResizeObserver((entries) => {
			for (const entry of entries) {
				if (entry.contentRect) {
					const resizeWidth = entry.contentRect.width

					if (Math.abs(beforeWidth - resizeWidth) > 10) {
						beforeWidth = resizeWidth

						clearTimeout(data.resizeDelayFunc)
						data.resizeDelayFunc = setTimeout(() => {
							resizeFunc()

							// console.log(`Tooltip size changed`)
						}, 500)
					}
				}
			}
		})

		resizeObserver.observe(resizeObj)

		const resizeWrapObserver = new ResizeObserver((entries) => {
			for (const entry of entries) {
				if (entry.contentRect) {
					const resizeWidth = entry.contentRect.width

					if (Math.abs(beforeWrapWidth - resizeWidth) > 10) {
						beforeWrapWidth = resizeWidth

						clearTimeout(data.resizeDelayFunc)
						data.resizeDelayFunc = setTimeout(() => {
							resizeFunc()

							// console.log(`Tooltip Wrap size changed`)
						}, 500)
					}
				}
			}
		})

		resizeWrapObserver.observe(resizeWrapObj)

		const cleanup = () => {
			for (const item of tooltipRef.current?.querySelectorAll(`.btn-close`) ?? []) {
				item.removeEventListener(`click`, tooltipCloseFunc)
			}

			clearTimeout(data.resizeDelayFunc)

			resizeObserver.unobserve(resizeObj)

			resizeWrapObserver.unobserve(resizeWrapObj)
		}

		return cleanup
	}, [
		data,
		defaultOpen,
		id,
		open,
		pageData.wrapHidden,
		pageData.wrapLeft,
		pageData.wrapOpacity,
		pageData.wrapTop,
		target,
		tooltipCloseFunc,
		tooltipHooks,
		tooltipOpenFunc,
		tooltipPosition
	])

	const props = {
		border,
		className,
		header,
		height,
		id,
		maxHeight,
		rounded,
		text,
		title,
		tooltipCloseFunc,
		tooltipRef,
		tooltipZIndex,
		transition,
		width,
		wrapHidden: pageData.wrapHidden,
		wrapLeft: pageData.wrapLeft,
		wrapOpacity: pageData.wrapOpacity,
		wrapTop: pageData.wrapTop
	}

	return (
		<TooltipView
			{...props}
		/>
	)
}

export default Tooltip
