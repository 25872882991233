'use client'

import Button from '@dg/common/components/Button'
import {
	addCookie, appVersionChkFunc, classNameFunc, device
} from '@dg/common/lib/common'
import useImageValidate from '@dg/common/lib/hooks/useImageValidate'
import {
	getLegacyServerUrl, mobileAppleLogin, mobileFacebookLogin, mobileGoogleLogin, mobileLineLogin,
	openPopup, procOpenerFunc
} from '@dg/common/lib/qoo10Common'
import {
	XMarkIcon
} from '@heroicons/react/24/solid'
import Image from 'next/image'
import {
	useRouter,
	useSearchParams
} from 'next/navigation'
import {
	useMemo
} from 'react'
import {
	useTranslation
} from 'react-i18next'

type snsType = `Facebook` | `Google` | `Line` | `Apple` | ``

interface SnsAreaLiViewProps {
	beforeLogin: snsType;
	failureUri: string;
	height: number;
	name: string;
	redirectUri: string;
	snsloginToastChk: boolean;
	successUri: string;
	tooltipBtnFunc: () => void;
	tooltipOpen: boolean;
	type: string;
	width: number;
}

const SnsAreaLiView = ({
	height,
	name,
	width,
	successUri = ``,
	failureUri = ``,
	type,
	redirectUri = ``,
	beforeLogin,
	tooltipOpen,
	snsloginToastChk,
	tooltipBtnFunc
}: SnsAreaLiViewProps) => {
	const {
		t
	} = useTranslation()

	const router = useRouter()
	const searchParams = useSearchParams()
	const imageValidate = useImageValidate()

	const data = useMemo(() => ({
		delayCount: 0,
		delayFunc: null as unknown as ReturnType<typeof setInterval>
	}), [])

	const facebookChk = name === `Facebook` && beforeLogin === name
	const googleChk = name === `Google` && beforeLogin === name
	const lineChk = name === `Line` && beforeLogin === name
	const appleChk = name === `Apple` && beforeLogin === name

	return (
		<li
			className="relative flex-1"
		>
			{
				snsloginToastChk === true &&
				tooltipOpen === true &&
				(facebookChk === true || googleChk === true || lineChk === true || appleChk === true) && (
					<div
						className={
							classNameFunc(`
								absolute -top-10 left-4 flex items-center rounded-md bg-dg-blue px-4 py-2
								text-sm text-dg-white after:absolute after:-bottom-1 after:left-4 after:size-3.5
								after:rotate-45 after:bg-dg-blue dark:bg-dgdark-blue dark:text-dgdark-white
								after:dark:bg-dgdark-blue
							`)
						}
					>
						{t(`이전 로그인`)}

						<Button
							className="ml-2"
							onClick={tooltipBtnFunc}
						>
							<XMarkIcon
								className="size-5"
							/>

							<span
								className="sr-only"
							>
								Close
							</span>
						</Button>
					</div>
				)
			}

			<Button
				className={
					classNameFunc(`
						mx-auto flex h-12 w-full flex-none items-center space-x-2.5 overflow-hidden rounded-md
						border border-solid border-dg-500 px-6 text-sm dark:border-dgdark-500
					`)
				}
				onClick={
					() => {
						addCookie(`${device().browser.app === undefined ? `tempB` : `b`}eforeLoginChannel=${name}`, 365)

						if (device().browser.app !== undefined) {
							const appVersionChk = appVersionChkFunc({
								android: {
									move: `1.0.2`,
									qoo10: `5.0.7`
								},
								ios: {
									move: `1.0.2`,
									qoo10: `4.8.6`
								}
							})

							const lineAppLoginChk = name === `Line` && appVersionChk === true

							if (device().browser.app === `move` && searchParams?.get(`memberdev`) === null) {
								const lineAlertChk = name === `Line` && appVersionChk === false

								if (name === `Facebook` || lineAlertChk === true) {
									// eslint-disable-next-line @stylistic/max-len
									let text = `申し訳ございません。Facebookでの会員登録/ログインは準備中です。既にQoo10で登録されたFacebookのメールアドレスと同じアドレスでは登録することができません。`

									if (name === `Line`) {
										// eslint-disable-next-line @stylistic/max-len
										text = `申し訳ございません。Lineでの会員登録/ログインは準備中です。既にQoo10で登録されたLineのメールアドレスと同じアドレスでは登録することができません。`
									}

									// eslint-disable-next-line no-alert
									window.alert(text)

									return false
								}
							}

							if (name === `Google`) {
								mobileGoogleLogin()

								return false
							}

							if (name === `Facebook`) {
								mobileFacebookLogin()

								return false
							}

							if (name === `Apple` && device().browser.android === undefined) {
								mobileAppleLogin()

								return false
							}

							if (lineAppLoginChk === true) {
								mobileLineLogin()

								return false
							}
						}

						let snsUrl = `/api/oauth2/authorization/${name.toLowerCase()}?success_uri=${encodeURIComponent(successUri)}&failure_uri=${encodeURIComponent(failureUri)}&redirect_uri=${encodeURIComponent(redirectUri)}&type=${type}`

						if (name.toLowerCase() === `line` && device().browser.app !== undefined) {
							let loginSrc = `MB`

							if (device().browser.app === `move`) {
								loginSrc = `MM`
							}

							snsUrl = `${getLegacyServerUrl(`/Login/Partner/LineLogin.aspx`)}?login_src=${loginSrc}&ReturnUrl=${encodeURIComponent(redirectUri)}`
						}

						let popupHeight = 600
						let popupWidth = 600

						if (name.toLowerCase() === `google` || name.toLowerCase() === `facebook`) {
							popupHeight = 1000
							popupWidth = 850
						} else if (name.toLowerCase() === `apple`) {
							popupHeight = 800
							popupWidth = 700
						}

						// 인증 실패 시 redirect url 설정
						window.localStorage.setItem(`snsloginurl`, snsUrl)

						clearInterval(data.delayFunc)
						data.delayFunc = setInterval(() => {
							const loginCookieName = `GMKT.FRONT.JP=`

							if (document.cookie.match(loginCookieName) !== null || data.delayCount === 60) {
								clearInterval(data.delayFunc)

								if (
									document.cookie.match(loginCookieName) !== null &&
									snsUrl.match(/exopn/gim) !== null
								) {
									const func = `${decodeURIComponent(snsUrl).split(`exopn://`)[1].split(`&`)[0]}CallFromPopup`

									procOpenerFunc(func)
								}
							}

							data.delayCount += 1
						}, 1000)

						if (
							name === `Facebook` ||
							name === `Line` ||
							device().browser.desktop === true ||
							device().browser.app !== undefined
						) {
							if (device().browser.app !== undefined) {
								snsUrl = `${process.env.memberUrl}${snsUrl}`
							}

							openPopup(snsUrl, popupHeight, popupWidth)
						} else {
							router.replace(snsUrl)
						}

						return false
					}
				}
			>
				<Image
					alt={`${name} Icon`}
					height={height}
					onError={imageValidate.onError}
					src={`/images/common-member/${name.toLowerCase()}-2024.png`}
					width={width}
				/>

				<span
					className="flex-auto text-center"
				>
					{t(`{text}로 로그인${name === `Apple` ? `_apple` : ``}`).replace(/{text}/gim, name)}
				</span>
			</Button>
		</li>
	)
}

export default SnsAreaLiView
