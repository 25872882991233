'use client'

import useImageValidate from '@dg/common/lib/hooks/useImageValidate'
import Image from 'next/image'

interface ListTitleViewProps {
	item: Record<string, string | number | boolean>;
	title: {
		className?: string;
		prependIconName?: string;
		titleName: string;
	};
}

const ListTitleView = ({
	title, item
}: ListTitleViewProps) => {
	const imageValidate = useImageValidate()

	const {
		className, prependIconName, titleName
	} = title

	const prependIcon = prependIconName !== undefined ? item[prependIconName || ``].toString().trim() : ``

	return (
		<p
			className={className}
		>
			{
				prependIcon !== undefined && prependIcon !== `` && (
					(prependIcon === `TD` || prependIcon === `GB` || prependIcon === `PD`) && (
						<span
							className="mr-1 inline-flex align-middle"
						>
							{
								prependIcon === `TD` && (
									<Image
										alt="Timesale Image"
										height={17}
										onError={imageValidate.onError}
										src="/images/pc/common/list-timesale.png"
										width={14}
									/>
								)
							}

							{
								prependIcon === `GB` && (
									<Image
										alt="Groupbuy Image"
										height={19}
										onError={imageValidate.onError}
										src="/images/pc/common/list-groupbuy.png"
										width={19}
									/>
								)
							}

							{
								prependIcon === `PD` && (
									<Image
										alt="Dailydeal Image"
										height={17}
										onError={imageValidate.onError}
										src="/images/pc/common/list-dailydeal.png"
										width={14}
									/>
								)
							}
						</span>
					)
				)
			}

			{item[titleName]}
		</p>
	)
}

export default ListTitleView
