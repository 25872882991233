'use client'

import Button from '@dg/common/components/Button'
import Link from '@dg/common/components/Link'
import {
	classNameFunc
} from '@dg/common/lib/common'
import useImageValidate from '@dg/common/lib/hooks/useImageValidate'
import Image from 'next/image'
import {
	PropsWithChildren
} from 'react'

interface HeaderSideBarLiViewProps {
	bgBlue?: boolean;
	bl?: boolean;
	bt?: boolean;
	flex?: boolean;
	height?: number;
	link?: string;
	rounded?: boolean;
	srOnly?: string;
	src?: `nav-roulette` | `nav-share` | `nav-help` | `nav-cart` | `nav-qpost` | `nav-recent`;
	target?: string;
	title?: string;
	width?: number;
}

const HeaderSideBarLiView = ({
	children,
	title,
	link,
	rounded = false,
	bgBlue = false,
	target,
	width,
	height,
	src = `nav-roulette`,
	srOnly,
	flex,
	bl,
	bt
}: PropsWithChildren<HeaderSideBarLiViewProps>) => {
	const imageValidate = useImageValidate()

	const linkClassName =
		classNameFunc(`
			text-center font-bold
			${rounded === true ? `rounded` : ``}
			${bgBlue === true ? `bg-[#527fef] text-dg-white dark:text-dgdark-white` : ``}
			${flex === true ? `flex flex-1 items-center justify-center p-0` : `block w-full`}
			${bl === true ? `border-l border-solid border-[#e6e8e9]` : ``}
			${bt === true ? `border-t border-solid border-[#e6e8e9] py-2 text-3xs` : `py-1 text-xs`}
		`)

	return (
		link !== undefined && link.match(/window/) === null ?
			(
				<Link
					className={linkClassName}
					href={link}
					target={target}
				>
					<Image
						alt={srOnly ?? (children as string).toString()}
						className="mx-auto"
						height={height}
						onError={imageValidate.onError}
						src={`/images/pc/common/${src}.png`}
						width={width}
					/>

					{children}
				</Link>
			) :
			(
				<Button
					className={linkClassName}
					onClick={
						() => {
							if (link?.split(`_`)[0] === `window`) {
								const shareUrl = `${process.env.legacyPcUrl}/gmkt.inc/SNS/PopupShareSNS.aspx?title=${encodeURIComponent(title ?? ``)}&share_url=${encodeURIComponent(window.location.href ?? ``)}&gd_image=${encodeURIComponent(`https://stjp.image-qoo10.jp/qoo10/front/cm/common/image/logo_qoo10_200.png`)}&sns_cd=A&m_reset_yn=&msg=`

								window.open(
									link.split(`_`)[1] !== `share` ?
										link.split(`_`)[1] :
										shareUrl,
									link.split(`_`)[2],
									link.split(`_`)[3]
								)
							}
						}
					}
				>
					<Image
						alt={srOnly ?? (children as string).toString()}
						className="mx-auto"
						height={height}
						onError={imageValidate.onError}
						src={`/images/pc/common/${src}.png`}
						width={width}
					/>

					{children}
				</Button>
			)
	)
}

export default HeaderSideBarLiView
