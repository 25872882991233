'use client'

import {
	classNameFunc
} from '@dg/common/lib/common'
import IcStar from '@dg/common/public/icons/pc/common/ic-star.svg'

interface ListStarViewProps {
	item: Record<string, string | number | boolean>;
	star: {
		starName: string;
	};
}

const ListStarView = ({
	star, item
}: ListStarViewProps) => {
	const {
		starName
	} = star

	const parseStarName = Number(item[starName])

	const starClassName = classNameFunc(`h-full w-3 flex-none`)

	return (
		parseStarName > 0 && (
			<p
				className="relative mt-1 h-3 w-15 overflow-hidden"
			>
				<span
					className="flex h-full text-[#d3d3d3]"
				>
					<IcStar
						className={starClassName}
					/>

					<IcStar
						className={starClassName}
					/>

					<IcStar
						className={starClassName}
					/>

					<IcStar
						className={starClassName}
					/>

					<IcStar
						className={starClassName}
					/>
				</span>

				<span
					className="absolute left-0 top-0 z-10 flex h-full overflow-hidden text-dg-red dark:text-dgdark-red"
					style={
						{
							width: `${parseStarName}%`
						}
					}
				>
					<IcStar
						className={starClassName}
					/>

					<IcStar
						className={starClassName}
					/>

					<IcStar
						className={starClassName}
					/>

					<IcStar
						className={starClassName}
					/>

					<IcStar
						className={starClassName}
					/>
				</span>
			</p>
		)
	)
}

export default ListStarView
