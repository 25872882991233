'use client'

import {
	InputProps
} from '@dg/common/components/Input/Input'
import useCompareValidate from '@dg/common/lib/hooks/useCompareValidate'
import useEmailValidate from '@dg/common/lib/hooks/useEmailValidate'
import useIdValidate from '@dg/common/lib/hooks/useIdValidate'
import useNameValidate from '@dg/common/lib/hooks/useNameValidate'
import usePasswordValidate from '@dg/common/lib/hooks/usePasswordValidate'
import usePhoneValidate from '@dg/common/lib/hooks/usePhoneValidate'
import ValidateInputView from 'member/components/Common/Validate/ValidateInput/ValidateInputView/ValidateInputView'
import {
	ChangeEvent, FormEvent, ReactElement, useCallback, useEffect, useMemo, useRef, useState
} from 'react'

export interface ValidateInputProps extends InputProps {
	appendElement?: ReactElement | boolean | undefined;
	autoDisabled?: boolean;
	btnLast?: boolean;
	compareTarget?: string; // validateType: compare 일 때 사용
	disableText?: boolean;
	disabled?: boolean;
	fullNation?: boolean;
	nameType?: `mei` | `sei`; // validateType: name 일 때 사용
	nationClassName?: string; // type: tel 일 때 사용, select className 설정
	nationDisabledBgColor?: boolean; // type: tel 일 때 사용, disabled일 때 bg 사용 여부 설정
	nationOnChange?: (event: ChangeEvent<HTMLSelectElement>) => void; // type: tel 일 때 사용, select onChange Event
	prependElement?: ReactElement | boolean | undefined;
	validateTextClassName?: string;
	validateType: `compare` | `email` | `id` | `name` | `password` | `phone` | `disabled`;
	wrapClassName?: string;
}

const ValidateInput = (props: ValidateInputProps) => {
	const {
		validateType, wrapClassName = ``, btnLast = false, defaultValue = ``, prependElement, appendElement,
		validateTextClassName, compareTarget = `body`, nameType = `mei`, nationDisabledBgColor = false,
		nationClassName = ``, fullNation = true, autoDisabled = true, disableText = false, disabled,
		onChange, nationOnChange,
		...rest
	} = props

	const emailValidate = useEmailValidate()
	const idValidate = useIdValidate()
	const passwordValidate = usePasswordValidate()
	const compareValidate = useCompareValidate()
	const phoneValidate = usePhoneValidate()
	const nameValidate = useNameValidate()

	const validateInputRef = useRef<HTMLInputElement>(null)

	const data = useMemo(() => ({
		defaultValue: ``
	}), [])

	const [
		pageData,
		setPageData
	] = useState({
		textValue: ``,
		validateText: ``
	})

	const validateSelectChangeFunc = useCallback((event: FormEvent) => {
		setPageData({
			textValue: ``,
			validateText: ``
		})

		if (nationOnChange !== undefined) {
			nationOnChange(event as ChangeEvent<HTMLSelectElement>)
		}
	}, [
		nationOnChange
	])

	const validateOnChangeFunc = useCallback((event: FormEvent) => {
		const obj = event.target as HTMLInputElement

		let textValue = obj.value
		let validateText = ``

		const compareTargetObj = document.querySelector(compareTarget) as HTMLInputElement

		if (validateType === `email`) {
			emailValidate.onChange(event)

			textValue = emailValidate.result.value
			validateText = emailValidate.result.text
		} else if (validateType === `id`) {
			idValidate.onChange(event)

			textValue = idValidate.result.validValue
			validateText = idValidate.result.text
		} else if (validateType === `password`) {
			passwordValidate.onChange(event)

			textValue = passwordValidate.result.validValue
			validateText = passwordValidate.result.text
		} else if (validateType === `compare` && compareTargetObj !== null) {
			const compareValue = Array.from(obj.value).slice(0, 20).join(``)

			compareValidate.compare(compareTargetObj.value, compareValue)

			textValue = compareValue
			validateText = compareValidate.result.text
		} else if (validateType === `phone`) {
			const nationValue = (obj.previousElementSibling as HTMLSelectElement)?.value || ``

			phoneValidate.onChange(event, nationValue)

			textValue = phoneValidate.result.validValue
			validateText = phoneValidate.result.text
		} else if (validateType === `name`) {
			nameValidate.onChange(event, nameType)

			textValue = nameValidate.result.value
			validateText = nameValidate.result.text
		}

		setPageData({
			textValue,
			validateText
		})

		if (onChange !== undefined) {
			onChange(event as ChangeEvent<HTMLInputElement>)
		}
	}, [
		compareTarget,
		compareValidate,
		emailValidate,
		idValidate,
		nameType,
		nameValidate,
		onChange,
		passwordValidate,
		phoneValidate,
		validateType
	])

	useEffect(() => {
		// eslint-disable-next-line @stylistic/max-len
		const compareInputValue = Array.from((validateInputRef.current?.querySelector(`input`) as HTMLInputElement).value).slice(0, 20).join(``)
		const compareTargetObj = document.querySelector(compareTarget) as HTMLInputElement
		const compareChkValue = `${compareInputValue}_dg_${compareTargetObj?.value || ``}`

		if (
			validateType !== `compare` &&
			data.defaultValue !== defaultValue &&
			defaultValue !== undefined &&
			defaultValue !== null
		) {
			data.defaultValue = defaultValue as string

			const phoneNationChk = defaultValue.toString().match(/\+/) !== null
			let textValue = (validateType === `phone` ? Array.from((defaultValue as string)?.split(`-`).filter((filterItem, filterIndex) => filterIndex > (phoneNationChk === true ? 0 : -1))).join(`-`) : defaultValue) as string
			let validateText = ``

			if (validateType === `email`) {
				validateText = emailValidate.validate(textValue)
			} else if (validateType === `id`) {
				validateText = idValidate.validate(textValue)
			} else if (validateType === `password`) {
				validateText = passwordValidate.validate(textValue)
			} else if (validateType === `phone`) {
				const nationValue =
					(validateInputRef.current?.querySelector(`select`) as HTMLSelectElement)?.value || ``

				validateText = disabled === true ? `` : phoneValidate.validate(textValue, nationValue)
				textValue = disabled === true ? textValue : phoneValidate.result.validValue
			} else if (validateType === `name`) {
				validateText = nameValidate.validate(nameType, textValue)
			}

			setPageData({
				...pageData,
				textValue,
				validateText
			})
		} else if (validateType === `compare` && data.defaultValue !== compareChkValue) {
			data.defaultValue = compareChkValue

			let validateText = ``

			if (compareInputValue !== compareTargetObj?.value) {
				validateText = compareValidate.validate(compareInputValue, compareTargetObj?.value || ``)
			}

			setPageData({
				...pageData,
				validateText
			})
		}
	}, [
		compareTarget,
		compareValidate,
		data,
		defaultValue,
		disabled,
		emailValidate,
		idValidate,
		nameType,
		nameValidate,
		pageData,
		passwordValidate,
		phoneValidate,
		validateType
	])

	const extProps = {
		...rest,
		appendElement,
		autoDisabled,
		btnLast,
		defaultValue,
		disableText,
		disabled,
		fullNation,
		nationClassName,
		nationDisabledBgColor,
		prependElement,
		textValue: pageData.textValue,
		validateInputRef,
		validateOnChangeFunc,
		validateSelectChangeFunc,
		validateText: pageData.validateText,
		validateTextClassName,
		validateType,
		wrapClassName
	}

	return (
		<ValidateInputView
			{...extProps}
		/>
	)
}

export default ValidateInput
