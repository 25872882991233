'use client'

import {
	classNameFunc
} from '@dg/common/lib/common'
import React from 'react'

export type TVariant =
	`` | `bgoutlined-${number}` | `bgoutlined` | `clear-${number}` | `clear` | `outlined-${number}` |
	`outlined` | `standard-${number}` | `standard`

interface InputBoxStyleViewProps {
	children: React.ReactElement[] | React.ReactElement;
	className?: string;
	inputStackCss?: string;

	/** flex의 상태를 flex-none으로 변경해줍니다. */
	noFlex?: boolean;
	variant?: TVariant;
}

const InputBoxStyleView = ({
	className,
	children,
	noFlex,
	inputStackCss,
	variant = `outlined`
}: InputBoxStyleViewProps) => {
	let inputCss = ``

	if (variant && variant.includes(`standard`)) {
		const [
			, variantSize
		] = variant.split(`-`)

		inputCss = inputStackCss ?? `
			h-${variantSize || 10} rounded ${noFlex ? `flex-none` : `flex-1`}
					border-b-[1px] border-[#f1f1f1] text-xs
		`
	} else if (variant && variant.includes(`bgoutlined`)) {
		const [
			, variantSize
		] = variant.split(`-`)

		inputCss = inputStackCss ?? `
			h-${variantSize || 10} rounded ${noFlex ? `flex-none` : `flex-1`} border-[1px]
					border-[#f1f1f1] bg-dg-100 text-xs
		`
	} else if (variant && variant.includes(`outlined`)) {
		const [
			, variantSize
		] = variant.split(`-`)

		inputCss = inputStackCss ?? `
			h-${variantSize || 10} rounded ${noFlex ? `flex-none` : `flex-1`} border-[1px]
					border-[#f1f1f1] text-xs
		`
	} else if (variant && variant.includes(`clear`)) {
		const [
			, variantSize
		] = variant.split(`-`)

		inputCss = inputStackCss ?? `h-${variantSize || 10} rounded ${noFlex ? `flex-none` : `flex-1`} text-xs`
	}

	return (
		<div
			className={classNameFunc(`${className ?? ``} ${inputCss}`)}
		>
			{
				React.Children.map(children, (child) => {
					if (React.isValidElement(child)) {
						return child
					}

					return false
				})
			}
		</div>
	)
}

export default InputBoxStyleView
