'use client'

import {
	classNameFunc
} from "@dg/common/lib/common"
import React, {
	createContext, useState, useMemo, useEffect, useContext
} from "react"

type ActionType = `ACTIVE`

interface RadioContextType {
	activeId: string;
	dispatchIdEvent: (actionType: ActionType, payload: string) => void;
}

interface RadioGroupProps {
	children: React.ReactNode[] | React.ReactNode;
	className?: string;
	defaultId: string;
	onChange: (id: string) => void;
}

const RadioGroupContext = createContext<RadioContextType>({
	activeId: ``,
	dispatchIdEvent: () => null
})

const useRadioGroupContext = () => useContext(RadioGroupContext)

const RadioGroup = ({
	children,
	className,
	defaultId,
	onChange
}: RadioGroupProps) => {
	const [
		activeId,
		setActiveId
	] = useState<string>(defaultId)

	const dispatchIdEvent = (actionType: ActionType, payload: string) => {
		switch (actionType) {
			case `ACTIVE`: {
				setActiveId(payload)

				break
			}

			default: {
				break
			}
		}
	}

	useEffect(() => {
		if (onChange !== undefined) {
			onChange(activeId)
		}
	}, [
		activeId
	])

	const radioContextValues = useMemo<RadioContextType>(() => ({
		activeId,
		dispatchIdEvent
	}), [
		activeId
	])

	return (
		<RadioGroupContext.Provider
			value={radioContextValues}
		>
			<div
				className={className !== undefined ? classNameFunc(className) : undefined}
			>
				{children}
			</div>
		</RadioGroupContext.Provider>
	)
}

export default RadioGroup

export {
	useRadioGroupContext
}
