'use client'

import Button from "@dg/common/components/Button"
import {
	classNameFunc
} from "@dg/common/lib/common"
import useImageValidate from '@dg/common/lib/hooks/useImageValidate'
import useLayerpopup from '@dg/common/lib/hooks/useLayerpopup'
import Image from 'next/image'
import React from 'react'
import LoginAdultPopupView from "../../LoginAdultPopupView"

interface LoginAdultProps {
	adultPopupTitle: string;
	onChangeAdultData: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onClickSubmitAdultPopup: () => void;
}

const LoginAdult = ({
	adultPopupTitle,
	onChangeAdultData,
	onClickSubmitAdultPopup
}: LoginAdultProps) => {
	const layerpopup = useLayerpopup()

	const imageValidate = useImageValidate()

	return (
		<>
			<div
				className={
					classNameFunc(`
						my-5 flex space-x-1.5 rounded bg-[#f2f2f2] px-4 py-2.5 text-2xs
						leading-[1.34] tracking-[-1px] text-[#e70000]
					`)
				}
			>
				<Image
					alt="Caution"
					className="mr-3 object-contain"
					height={22}
					onError={imageValidate.onError}
					sizes="22px"
					src="/images/pc/common-member/info-caution.png"
					width={22}
				/>

				<div>
					アダルト商品やコンテンツは満18歳以上の方のみ閲覧可能です。
					<br />
					年齢確認のため、

					<strong
						className="font-bold"
					>
						ログイン
					</strong>
					または

					<Button
						className="font-bold underline"
						onClick={
							() => layerpopup.open({
								id: `layerpopup_adult`
							})
						}
					>
						生年月日認証
					</Button>
					を行ってください。
				</div>
			</div>

			<LoginAdultPopupView
				adultPopupTitle={adultPopupTitle}
				onChangeAdultData={onChangeAdultData}
				onClickSubmitAdultPopup={onClickSubmitAdultPopup}
			/>
		</>
	)
}

export default LoginAdult
