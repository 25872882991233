'use client'

import {
	toastData,
	type ToastValueProps
} from '@dg/common/lib/store'
import {
	useAtom
} from 'jotai'
import {
	useCallback
} from 'react'

const useToast = () => {
	const [
		toast,
		setToast
	] = useAtom(toastData)

	const get = useCallback(() => toast, [
		toast
	])

	const set = useCallback(({
		id = ``, open = false, text = ``, transition = true
	}: ToastValueProps) => {
		const setData = toast

		setData[id] = {
			id,
			open,
			text,
			transition
		}

		setToast(setData)
	}, [
		setToast,
		toast
	])

	const open = useCallback(({
		id = ``, text = ``, transition = true
	}: ToastValueProps) => {
		const setData = toast

		if (setData[id] !== undefined) {
			setData[id] = {
				...setData[id],
				open: true,
				text: text !== `` ? text : setData[id].text,
				transition
			}

			setToast(setData)
		}
	}, [
		setToast,
		toast
	])

	const close = useCallback((id = ``, transition = true) => {
		const setData = toast

		if (setData[id] !== undefined) {
			setData[id] = {
				...setData[id],
				open: false,
				transition
			}

			setToast(setData)
		}
	}, [
		setToast,
		toast
	])

	return {
		close,
		get,
		open,
		set
	}
}

export default useToast
