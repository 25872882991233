'use client'

import HeaderMenuView from '@dg/common/components/Pc/Header/HeaderView/HeaderMenu/HeaderMenuView/HeaderMenuView'
import React, {
	useCallback, useState
} from 'react'

interface HeaderMenuProps {
	title: string;
}

const HeaderMenu = ({
	title
}: HeaderMenuProps) => {
	const [
		pageData,
		setPageData
	] = useState({
		active1: ``,
		active2: ``,
		category: false
	})

	const categoryUlFunc = useCallback((event:
		React.FocusEvent<HTMLAnchorElement | HTMLButtonElement | HTMLLIElement, Element> |
		React.MouseEvent) => {
		if (event.type === `mouseover`) {
			const obj = event.target as HTMLElement
			const buttonObj = obj.closest(`button`) !== null ? obj.closest(`button`) : obj.closest(`a`)

			if (buttonObj?.nextElementSibling !== null) {
				const setData = {
					...pageData
				}

				if (obj.closest(`li`)?.classList.contains(`category`) === false) {
					if (buttonObj?.getAttribute(`data-depth`)) {
						const splitDepth = buttonObj?.getAttribute(`data-depth`) ?? ``
						const split0 = parseInt(splitDepth.split(`-`)[0], 10)

						if (split0 === 0) {
							setData.active1 = splitDepth
							setData.active2 = ``
						} else if (split0 === 1) {
							setData.active2 = splitDepth
						}
					}
				} else {
					setData.category = true
				}

				setPageData(setData)
			}
		} else {
			const obj = event.relatedTarget as HTMLElement

			if (obj !== null) {
				const categoryObj = obj.closest(`.category`)

				if (categoryObj === null) {
					setPageData({
						active1: ``,
						active2: ``,
						category: false
					})
				}
			}
		}
	}, [
		pageData
	])

	const props = {
		active1: pageData.active1,
		active2: pageData.active2,
		category: pageData.category,
		categoryUlFunc,
		title
	}

	return (
		<HeaderMenuView
			{...props}
		/>
	)
}

export default HeaderMenu
